import { type ActionContext } from 'vuex'

import {
  type BlockCore,
  type BuilderState,
  type ResourceCore,
} from '@/lib/builder'
import { type CoreModule } from '@/lib/store'

export const blockDelete = async <
  TResource extends ResourceCore,
  TBlock extends BlockCore,
>(
  {
    state,
    commit,
    dispatch,
  }: ActionContext<BuilderState<TResource, TBlock>, CoreModule>,
  blockId: string
): Promise<void> => {
  commit('_builder_BLOCK_DELETE', blockId)
}
