<i18n locale="en">
{
  "actions": {
    "download": "Download",
    "replace": "Replace",
    "remove": "Remove",
    "load": "Upload a file (Max 4 MB)",
    "select": "Select file"
  },
  "context": {
    "editing": "Editing {label}"
  },
  "labels": {
    "preview": "File Preview"
  },
  "errors": {
    "too-many-files": "Upload a single file at a time.",
    "not-enough-files": "Must upload one file."
  }
}
</i18n>
<template>
  <div class="flex-col gap-2">
    <div
      :class="['own-file flex-col gap-2', error && 'own-file--error']"
      @dragover.prevent
      @drop.prevent="onDrop"
    >
      <button
        class="own-file__content-container"
        :disabled="disabled"
        @click="openFilePicker"
      >
        <!-- This is basically hidden -->
        <input
          ref="uploader"
          class="file-picker"
          type="file"
          name="file"
          :accept="accept"
          @change="onFileUpload"
        />

        <div
          v-if="modelValue"
          class="own-file__preview-container flex-col align-center justify-center"
        >
          <slot name="preview" :preview-value="modelValue">
            <PhFile class="misc-brand" size="32" />
          </slot>
        </div>

        <div
          v-else
          class="own-file__no-content flex-col gap-4 align-center justify-center"
        >
          <slot name="empty">
            <PhUploadSimple class="misc-brand" size="24" />
            <OwnType
              :text="t('actions.load')"
              variant="button"
              color="secondary"
            />
          </slot>
        </div>

        <OwnActionMenu
          v-if="modelValue"
          class="own-file__actions"
          :actions="actions"
          :disabled="disabled"
          @action="onActionSelect"
        >
          <template #trigger>
            <PhDotsThreeCircle class="text-color-secondary" size="24" />
          </template>
        </OwnActionMenu>
      </button>
    </div>

    <OwnButton
      v-if="!hideEditButton"
      :disabled="disabled"
      :text="
        modelValue
          ? editButtonReplaceLabel ?? t('actions.replace')
          : editButtonSelectLabel ?? t('actions.select')
      "
      @click="openFilePicker"
    />
  </div>
</template>
<script>
import { PhDotsThreeCircle, PhFile, PhUploadSimple } from '@phosphor-icons/vue'
import { useI18n } from 'vue-i18n'

import notify from '@/mixins/notify'
import { download } from '@/utils/download'

import { OwnActionMenu } from '../actions/OwnActionMenu'
import { OwnButton } from '../OwnButton'
import { OwnType } from '../OwnType'

export default {
  name: 'OwnFile',
  components: {
    OwnActionMenu,
    OwnButton,
    OwnType,
    PhDotsThreeCircle,
    PhFile,
    PhUploadSimple,
  },
  mixins: [notify],
  inheritAttrs: false,
  emits: ['update:modelValue'],
  setup() {
    const { t } = useI18n()

    return { t }
  },
  props: {
    /**
     * Value for the `<input>`'s [`accept` attribute](https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/accept).
     */
    accept: {
      type: String,
      default: undefined,
    },

    /** Allow removing the file (setting to null) */
    allowDelete: { type: Boolean, default: false },

    /** When `true`, the input will be disabled */
    disabled: { type: Boolean, default: false },

    /** Label for edit button, if enabled, when a file has been uploaded. */
    editButtonReplaceLabel: { type: String, default: null },

    /** Label for edit button, if enabled, when no file has been uploaded. */
    editButtonSelectLabel: { type: String, default: null },

    /** Has error */
    error: { type: Boolean, default: false },

    /** Hides the edit button, clicking the file still works */
    hideEditButton: { type: Boolean, default: false },

    /** @model URL of file */
    modelValue: { type: String, default: undefined },
  },
  computed: {
    actions() {
      const { allowDelete } = this

      const defaultActions = [
        {
          label: this.t('actions.replace'),
          value: 'replace',
        },
        {
          label: this.t('actions.download'),
          value: 'download',
        },
      ]

      const removeAction = [
        {
          color: 'danger',
          label: this.t('actions.remove'),
          value: 'remove',
        },
      ]

      if (allowDelete) {
        return [defaultActions, removeAction]
      } else {
        return defaultActions
      }
    },
  },
  watch: {
    modelValue(newFile) {
      // We do this so the same file can be uploaded again
      // Otherwise, by default, the uploader will ignore files with the same filename
      if (newFile === null) {
        this.$refs.uploader.value = null
      }
    },
  },
  methods: {
    getNormalizedFiles(event) {
      if (event?.target?.files?.length) {
        return event.target.files
      } else if (
        event instanceof DragEvent &&
        event?.dataTransfer?.files?.length
      ) {
        return event.dataTransfer.files
      }

      return []
    },
    onActionSelect(action) {
      if (action === 'replace') {
        this.openFilePicker()
      }

      if (action === 'remove') {
        this.removeFile()
      }

      if (action === 'download') {
        download(this.modelValue)
      }
    },
    onDrop(event) {
      this.onFileUpload(event)
    },
    onFileUpload(event) {
      const files = this.getNormalizedFiles(event)

      if (files.length > 1) {
        this.$notify(this.t('errors.too-many-files'), 'error')
      } else if (files.length === 0) {
        this.$notify(this.t('errors.not-enough-files'), 'error')
      } else {
        this.$emit('update:modelValue', files[0])
      }
    },
    openFilePicker() {
      const uploaderRef = this.$refs.uploader

      if (uploaderRef) {
        uploaderRef.click()
      }
    },
    removeFile() {
      this.$emit('update:modelValue', null)
    },
  },
}
</script>
<style lang="scss">
.own-file {
  width: 100%;
  border-radius: 8px;
  background-color: $background-secondary;
  border: 1px dashed $background-divider;
  height: 160px;

  &--error {
    border-color: $target-400;
  }

  &__content-container {
    position: relative;
    height: 100%;

    &:disabled {
      filter: grayscale(1);
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  &__no-content {
    width: 100%;
    height: 100%;
    padding: 16px 24px;
  }

  &__actions {
    position: absolute;
    right: 8px;
    top: 8px;
  }

  &__preview-container {
    height: 100%;
    padding: 32px;
  }
}

.file-picker {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  z-index: -1;
  position: absolute;
}
</style>
