import { type BuilderTranslationMap } from '@/lib/builder'

export const textBuilderEn: BuilderTranslationMap = {
  blocks: {
    text: 'Text',
  },
  defaults: {
    blank: {
      name: 'New Text',
    },
  },
  description: '',
  heading: '',
  options: { blank: 'Blank' },
  'resource-title': {
    placeholder: 'Enter text message name',
  },
  title: 'New Text',
}
