import { MenuPaginatedRouteFactory } from './common/MenuPaginatedRouteFactory'

const defaultItem = {
  amount: 500,
  available: true,
  brandId: null,
  displayName: null,
  locationId: null,
  name: null,
}

export default {
  ...MenuPaginatedRouteFactory('items', defaultItem),
  /**
   * Add a modifier set to an item
   * @param {string} itemId - Id of item
   * @param {string} modifierSetId - Id of modifier set to add
   */
  async addModifierSet(itemId, modifierSetId) {
    try {
      const { modifierSets } = await this.getDetails(itemId)

      const newModifierSets = [...modifierSets, modifierSetId]

      const dedupedItems = Array.from(new Set(newModifierSets))

      return this.update(itemId, { modifierSets: dedupedItems })
    } catch (error) {
      throw new Error('handle `items2.addModifierSet` error')
    }
  },

  /**
   * Create a brand level resource from an existing location level resource
   * @param {object} locationLevelResource Resource
   * @returns {Promise} New Resource
   */
  createBrandResource(locationLevelResource) {
    const brandLevelResource = {
      ...locationLevelResource,
      brandLevelId: null,
      id: null,
      locationId: null,
      modifierSets: [],
    }

    return this.create(brandLevelResource, { locations: false })
  },

  /**
   * Creates location level resources from a brand level resource
   * @param {object} brandLevelResource Resource
   * @param {Array} locationIds Ids to create location resources
   */
  createLocationResource(brandLevelResource, locationIds) {
    const locationLevelResources = locationIds.map((locationId) => ({
      ...brandLevelResource,
      brandLevelId: brandLevelResource.id,
      id: null,
      locationId,
      modifierSets: [],
    }))

    return Promise.all(
      locationLevelResources.map((newResource) => this.create(newResource))
    )
  },

  /**
   * Remove a modifier set from an item
   * @param {string} itemId - Id of item
   * @param {string} modifierSetId - Id of modifier set to remove
   */
  async removeModifierSet(itemId, modifierSetId) {
    try {
      const { modifierSets } = await this.getDetails(itemId)

      const newModifierSets = modifierSets.filter((id) => id !== modifierSetId)

      return this.update(itemId, { modifierSets: newModifierSets })
    } catch (error) {
      throw new Error('handle `items2.removeModifierSet` error')
    }
  },
}
