/**
 * Chunks a string into an array of strings by size
 * @param {string} str source string
 * @param {number} size chunk size
 * @returns {string[]} an array of strings
 */
const chunk = (str, size = 1) => {
  const chunked = []
  let index = 0
  while (index < str.length) {
    chunked.push(str.substr(index, size))
    index += size
  }
  return chunked
}

export default chunk
