<template>
  <OTCSendConfirmationDialog
    v-if="showConfirmation"
    :builder-config="textBuilderConfig"
    @close="showConfirmation = false"
  />
  <SendOTCDialog
    v-else
    :action="action"
    :block-modules="blockModules"
    :methods="methods"
    store-module="textBuilder"
    :model-value="show"
    @update:model-value="$emit('close-dialog')"
    @submit="onSubmit"
  >
    <template #custom-content="{ disable, executeAt }">
      <TextLimits :execute-at="executeAt" @update="disable($event)" />
    </template>
  </SendOTCDialog>
</template>

<script lang="ts" setup>
import { ref } from 'vue'

import OTCSendConfirmationDialog from '../../../components/otc/OTCSendConfirmationDialog.vue'
import SendOTCDialog from '../../../components/otc/send-dialog/SendOTCDialog.vue'
import { textBuilderConfig } from '../config/textBuilderConfig'
import { methods } from '../methods'

import TextLimits from './TextLimits.vue'

withDefaults(
  defineProps<{
    /**
     * Specific sub-operations to perform with the dialog
     */
    action?: 'schedule' | 'subject' | 'send'
    blockModules?: Array<unknown>
    show: boolean
  }>(),
  {
    action: undefined,
    blockModules: () => [],
    show: false,
  }
)
const emit = defineEmits(['close-dialog'])

const showConfirmation = ref(false)
const onSubmit = () => {
  emit('close-dialog')
  showConfirmation.value = true
}
</script>
