<template>
  <div class="star-rating flex-row">
    <PhStar
      v-for="(star, idx) in stars"
      :key="idx"
      :class="[
        'star-rating__star status-warning',
        editable && 'star-rating__star--editable',
      ]"
      :weight="star.weight"
      :size="iconSize"
      @click="handleClick(idx)"
    />
  </div>
</template>
<script>
import { PhStar } from '@phosphor-icons/vue'

import { mapModel } from '@/utils/computed'

export default {
  name: 'StarRating',
  components: {
    PhStar,
  },
  emits: ['update:modelValue'],
  props: {
    /**
     * Star rating can be changed (use model)
     */
    editable: { type: Boolean, default: false },

    /**
     * Star rating model
     * @model
     */
    modelValue: { type: Number, default: undefined },

    /**
     * Rating (1-5)
     */
    rating: { type: Number, default: undefined },

    /**
     * Star icon size
     */
    size: { type: String, default: undefined },
  },
  computed: {
    ...mapModel('starModel'),
    effectiveRating() {
      const { rating, starModel, editable } = this

      if (editable) return starModel

      return rating
    },
    iconSize() {
      const { size } = this

      if (size === 'lg') {
        return 24
      }

      return 16
    },
    stars() {
      const { effectiveRating } = this
      return [
        ...Array(effectiveRating).fill({
          weight: 'fill',
        }),
        ...Array(5 - effectiveRating).fill({
          weight: 'regular',
        }),
      ]
    },
  },
  methods: {
    handleClick(index) {
      const { editable } = this

      if (!editable) return

      this.starModel = index + 1
    },
  },
}
</script>
<style lang="scss" scoped>
.star-rating {
  &__star {
    &--editable {
      cursor: pointer;
    }
  }
}
</style>
