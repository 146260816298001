import defaultState from './state'

export default {
  ADD_SPECIAL_HOURS(state, specialHourEntries) {
    state.specialHours = {
      ...state.specialHours,
      ...specialHourEntries,
    }
  },
  ADD_SPECIAL_HOUR_ENTRY(state, newEntry) {
    const locationId = newEntry.locationId
    const allEntries = [...state.specialHours[locationId], newEntry]

    state.specialHours[locationId] = allEntries
  },
  DELETE_SPECIAL_HOURS(state, { locationId, specialHoursId }) {
    const locationSpecialHours = state.specialHours[locationId]

    const updateIndex = locationSpecialHours.findIndex(
      ({ id }) => id === specialHoursId
    )

    if (updateIndex >= 0) {
      state.specialHours[locationId].splice(updateIndex, 1)
    }
  },
  SANITIZE_SPECIAL_HOURS(state) {
    state.specialHours = defaultState.specialHours
  },
  UPDATE_SPECIAL_HOURS(state, { specialHoursEntry, locationId }) {
    const locationSpecialHours = state.specialHours[locationId]

    const updateIndex = locationSpecialHours.findIndex(
      ({ id }) => id === specialHoursEntry.id
    )

    if (updateIndex >= 0) {
      locationSpecialHours[updateIndex] = {
        ...locationSpecialHours[updateIndex],
        ...specialHoursEntry,
      }
    }

    state.specialHours[locationId] = [...locationSpecialHours]
  },
}
