import type { ActionContext } from 'vuex'

import { ConfiguredClient } from '@/api'
import { type Brand } from '@/core'
import type { CoreState } from '@/core/store/types'

export interface UpdateBrandParams {
  brandId: string
  update: Partial<Brand>
}

export const updateBrand = async (
  { commit }: ActionContext<CoreState, unknown>,
  { brandId, update }: UpdateBrandParams
): Promise<void> => {
  const brand = await ConfiguredClient.brands.brand.updateBrand({
    body: update,
    params: {
      brandId,
    },
  })

  commit('SET_BRAND', brand)
}
