import client from '../client'

/**
 * Check balance for one gift card
 * @param {string} cardNumber gift card number
 */
export const getCardByNumber = async (cardNumber) =>
  client.get('/gift-cards/overview', { number: cardNumber })

/**
 * Charge a gift card
 * @param {string} cardId the id of the card
 * @param {number} chargeAmount the amount in minor amount
 */
export const chargeGiftCard = async (cardId, chargeAmount) =>
  client.post(`/gift-cards/${cardId}/charge`, {
    amount: chargeAmount,
  })
