<i18n locale="en">
  {
    "cta": "Use Coupon",
    "title": "Get {amount} OFF with code {code}",
    "subtitles": {
      "coupon": {
        "order": "On your next order.",
        "order-items": "On your next order, only for select items.",
        "order-timeframe": "On your next order, for {timeframe} only.",
        "order-items-timeframe": "On your next order, only for select items, for {timeframe} only.",
        "min": "On your next order of {min} or more.",
        "min-items": "On your next order of {min} or more, only for select items.",
        "min-timeframe": "On your next order of {min} or more, for {timeframe} only.",
        "min-items-timeframe": "On your next order of {min} or more, only for select items, for {timeframe} only."
      }
    },
    "timeframes": {
      "today": "today",
      "week": "this week",
      "month": "this month",
      "year": "this year"
    }
  }
  </i18n>

<template>
  <div class="flex-col coupon-preview-renderer px-6 py-6">
    <div class="flex-1 h-full flex-col justify-between">
      <div class="flex-col">
        <PreviewTypography
          :text="
            t('title', {
              amount: couponAmount,
              code: coupon.code.toUpperCase(),
            })
          "
          color="primary"
          variant="titleSmall"
        />
        <PreviewTypography
          :text="couponDescription"
          color="secondary"
          variant="paragraphSmall"
        />
      </div>
      <PreviewButton
        variant="primary"
        color="primary"
        size="small"
        :title="t('cta')"
      />
    </div>
  </div>
</template>
<script lang="ts" setup>
import { addDays, formatDistanceStrict, isBefore } from 'date-fns'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { Coupon } from '@/features/menu/types'
import { getCurrencyFormatter, PercentFormatter } from '@/format'
import { PreviewButton, PreviewTypography } from '@/lib/builder'

const props = defineProps<{
  coupon: Coupon
}>()

const { t } = useI18n()

const currencyFormatter = getCurrencyFormatter({ cents: 'show-non-zero' })

const couponAmount = computed(() => {
  if (
    props.coupon.type === 'item-percent' ||
    props.coupon.type === 'ticket-percent'
  ) {
    return PercentFormatter(props.coupon.amount)
  }
  return currencyFormatter(props.coupon.amount)
})

const timeframe = computed(() => {
  if (!props.coupon.end) {
    return undefined
  }

  const startDate = props.coupon.start
    ? new Date(props.coupon.start)
    : new Date()
  const endDate = addDays(new Date(props.coupon.end), 1)

  // Coupon dates are invalid; let's refrain from showing a timeframe
  if (isBefore(endDate, startDate)) {
    return null
  }

  const couponDuration = formatDistanceStrict(endDate, startDate, {
    addSuffix: false,
    roundingMethod: 'ceil',
  })

  // If the coupon expires in the next 24 hours, we want to show it as expiring today since we do not correctly support coupons expiring mid-day, even though the data model allows it
  if (
    couponDuration.includes('second') ||
    couponDuration.includes('minute') ||
    couponDuration.includes('hour')
  ) {
    return 'today'
  }

  switch (couponDuration) {
    case '1 day':
      return t('today')
    case '1 week':
      return t('week')
    case '1 month':
      return t('month')
    case '1 year':
      return t('year')
    default:
      return couponDuration
  }
})

const couponDescription = computed(() => {
  const couponType = ['item-fixed', 'item-percent'].includes(props.coupon.type)
    ? 'item'
    : 'ticket'

  const min = props.coupon.min ? currencyFormatter(props.coupon.min) : null

  const slugChunks = []

  if (min) {
    slugChunks.push('min')
  } else {
    slugChunks.push('order')
  }

  if (couponType === 'item') {
    slugChunks.push('items')
  }

  if (timeframe.value) {
    slugChunks.push('timeframe')
  }

  return t(`subtitles.coupon.${slugChunks.join('-')}`, {
    min,
    timeframe: timeframe.value,
  })
})
</script>

<style lang="scss" scoped>
.coupon-preview-renderer {
  height: 166px;
}
</style>
