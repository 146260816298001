/**
 * Converts nested object to flat path object
 *
 * {
 * test: {
 * 123: function() {}
 * }
 * }
 *
 * to
 *
 * {
 * 'test/123': function() {}
 * }
 * @param {object} objToConvert Object to convert
 */
const convertToPathObject = (objToConvert) => {
  const flatObj = {}

  for (const key in objToConvert) {
    if (typeof objToConvert[key] === 'object') {
      const childPathObject = convertToPathObject(objToConvert[key])

      for (const childKey in childPathObject) {
        flatObj[`${key}/${childKey}`] = childPathObject[childKey]
      }
    } else {
      flatObj[key] = objToConvert[key]
    }
  }

  return flatObj
}

export { convertToPathObject }
