<template>
  <div class="form-builder-add-section-wrapper">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'FormBuilderAddSectionWrapper',
}
</script>

<style lang="scss" scoped>
.form-builder-add-section-wrapper {
  margin: 12px 40px;
  border-left: 1px dashed $background-divider;
  border-right: 1px dashed $background-divider;
  border-radius: 8px;
  overflow: hidden;
}
</style>
