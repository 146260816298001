/**
 * PageWrapper
 * @mixin
 */
export default {
  computed: {
    constrainedWidth() {
      const { maxWidth } = this

      if (maxWidth.endsWith('px') || maxWidth.endsWith('%')) {
        return maxWidth
      }

      return `${maxWidth}px`
    },
  },
  props: {
    /** Max width of content */
    maxWidth: { default: '100%', type: String },
  },
}
