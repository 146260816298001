<i18n locale="en">
{
  "totals": "{start}-{end} of {totalCount}"
}
</i18n>

<template>
  <div
    v-if="!loading && requestData.totalItems && requestData.totalItems > 0"
    class="flex-row gap-3 align-center"
  >
    <OwnCard class="py-2 px-4 flex-row gap-4 align-center" control>
      <button
        :class="[
          hasPrevious ? 'text-color-primary' : 'text-color-secondary',
          !hasPrevious && 'cursor-not-allowed',
        ]"
        :disabled="!hasPrevious"
        @click="onPrevClicked"
      >
        <PhCaretLeft weight="bold" />
      </button>

      <template v-for="(group, idx) of optionGroups" :key="idx">
        <OwnType v-if="idx !== 0" text="..." variant="button" />

        <button
          v-for="option of group"
          :key="option.value"
          @click="onJumpToPage(option.value)"
        >
          <OwnType
            :text="option.label"
            variant="button"
            :color="option.active ? 'brand' : 'secondary'"
          />
        </button>
      </template>

      <button
        :class="[
          hasNext ? 'text-color-primary' : 'text-color-secondary',
          !hasNext && 'cursor-not-allowed',
        ]"
        :disabled="!hasNext"
        @click="onNextClicked"
      >
        <PhCaretRight weight="bold" />
      </button>
    </OwnCard>

    <OwnType
      :text="t('totals', { start, end, totalCount: requestData.totalItems })"
    />
  </div>
</template>

<script lang="ts" setup>
import { PhCaretLeft, PhCaretRight } from '@phosphor-icons/vue'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { PaginatedRequestData, type PaginatedRequestParams } from '@/core/types'
import { OwnCard, OwnType } from '@/ui'

import { getPaginationLayout } from './getPaginationLayout'

const { t } = useI18n()

defineProps<{ loading: boolean }>()

const requestParams = defineModel<PaginatedRequestParams>('requestParams', {
  required: true,
})
const requestData = defineModel<PaginatedRequestData>('requestData', {
  required: true,
})

const hasPrevious = computed(() => {
  return requestParams.value.page > 1
})

const hasNext = computed(() => {
  if (!requestData.value.pageCount) return false

  return requestParams.value.page < requestData.value.pageCount
})

const start = computed(() => {
  return (requestParams.value.page - 1) * requestParams.value.limit + 1
})

const end = computed(() => {
  if (!requestData.value.totalItems) return 0

  return Math.min(
    requestParams.value.page * requestParams.value.limit,
    requestData.value.totalItems
  )
})

const optionGroups = computed(() => {
  return getPaginationLayout(requestParams.value, requestData.value)
})

const onPrevClicked = () => {
  requestParams.value.page = Math.max(requestParams.value.page - 1, 1)
}

const onNextClicked = () => {
  if (!requestData.value.pageCount) return

  requestParams.value.page = Math.min(
    requestParams.value.page + 1,
    requestData.value.pageCount
  )
}

const onJumpToPage = (page: number) => {
  requestParams.value.page = page
}
</script>
