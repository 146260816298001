import {
  type BlockCore,
  type BuilderState,
  type ResourceCore,
} from '@/lib/builder'

export const hasUnsavedChanges = <
  TResource extends ResourceCore,
  TBlock extends BlockCore,
>(
  state: BuilderState<TResource, TBlock>
): boolean => {
  const currentCommands = state._builder_commandQueue

  return currentCommands.length > 0
}
