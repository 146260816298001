<template>
  <Responsive class="own-slide-group" @size-change="onResize">
    <div
      v-if="hasPrev && isDesktop"
      class="own-slide-group__prev"
      @click="scrollTo('prev')"
    >
      <div class="own-slide-group__control-icon">
        <PhCaretLeft />
      </div>
    </div>
    <div ref="slideContainer" class="own-slide-group__container">
      <div
        ref="slideContent"
        class="own-slide-group__content space-x-6"
        :class="[control && 'own-slide-group__content--control']"
        :style="contentStyles"
        @touchstart.passive="onTouchStart"
        @touchmove.passive="onTouchMove"
        @touchend.passive="onTouchEnd"
      >
        <div
          v-for="(item, index) in items"
          :key="index"
          class="own-slide-group__item"
        >
          <!--
            @slot default - holds the content for each carousel entry
              @binding {Object} item
              @binding {number} index
            -->
          <slot :item="item" :index="index" />
        </div>
      </div>
    </div>
    <div
      v-if="hasNext && isDesktop"
      class="own-slide-group__next"
      @click="scrollTo('next')"
    >
      <div class="own-slide-group__control-icon">
        <PhCaretRight />
      </div>
    </div>
  </Responsive>
</template>

<script>
import { PhCaretLeft, PhCaretRight } from '@phosphor-icons/vue'

import { useBreakpoints } from '@/utils'

import Responsive from '../Responsive.vue'

/**
 * This adds friction when scrolling the 'wrong' way when at max offset
 *
 * https://github.com/vuetifyjs/vuetify/blob/master/packages/vuetify/src/components/VSlideGroup/helpers.ts#L1
 * @param {number} offset The attempted offset
 */
const bias = (offset) => {
  const reductionCoefficient = 0.501

  const unsignedOffset = Math.abs(offset)

  const sign = Math.sign(offset)

  return (
    sign *
    (unsignedOffset /
      ((1 / reductionCoefficient - 2) * (1 - unsignedOffset) + 1))
  )
}

export default {
  name: 'OwnSlideGroup',
  components: { PhCaretLeft, PhCaretRight, Responsive },
  props: {
    /** Includes padding for control component shadows */
    control: { type: Boolean, default: false },

    /** The data for the items to be rendered in the carousel list */
    items: { type: Array, required: true },
  },
  data() {
    return {
      containerSize: 0,
      contentSize: 0,
      disableTransition: false,
      isOverflowing: false,
      scrollOffset: 0,
      startOffset: 0,
      startTouch: 0,
    }
  },
  setup() {
    const { isDesktop } = useBreakpoints()

    return { isDesktop }
  },
  computed: {
    contentStyles() {
      const { disableTransition, scrollOffset, contentSize, containerSize } =
        this

      let scrollAmount =
        scrollOffset > contentSize - containerSize
          ? -1 * (contentSize - containerSize) +
            bias(contentSize - containerSize - scrollOffset)
          : -1 * scrollOffset

      if (scrollOffset <= 0) {
        scrollAmount = bias(-1 * scrollOffset)
      }

      return {
        transform: `translateX(${scrollAmount}px)`,
        transition: disableTransition ? 'none' : '',
        willChange: disableTransition ? 'transform' : '',
      }
    },
    hasNext() {
      return (
        this.isOverflowing &&
        this.contentSize > Math.abs(this.scrollOffset) + this.containerSize
      )
    },
    hasPrev() {
      return this.isOverflowing && Math.abs(this.scrollOffset) > 0
    },
  },
  watch: {
    items() {
      this.onResize()
    },
  },
  methods: {
    onResize() {
      if (!this.$refs.slideContainer || !this.$refs.slideContent) return
      this.containerSize = this.$refs.slideContainer.clientWidth
      this.contentSize = this.$refs.slideContent.clientWidth
      this.isOverflowing = this.containerSize + 1 < this.contentSize
    },

    onTouchEnd() {
      const maxScrollOffset = this.contentSize - this.containerSize

      if (this.scrollOffset < 0 || !this.isOverflowing) {
        this.scrollOffset = 0
      } else if (this.scrollOffset >= maxScrollOffset) {
        this.scrollOffset = maxScrollOffset
      }

      this.disableTransition = false
    },

    onTouchMove(e) {
      if (!this.isOverflowing) return

      this.scrollOffset =
        this.startOffset + this.startTouch - e.touches[0].clientX
    },

    onTouchStart(e) {
      this.startOffset = this.scrollOffset
      this.startTouch = e.touches[0].clientX
      this.disableTransition = true
    },

    /**
     * @param {string} target prev or next
     */
    scrollTo(target) {
      const newAbsoluteOffset =
        this.scrollOffset + (target === 'prev' ? -1 : 1) * this.containerSize

      this.scrollOffset = Math.max(
        0,
        Math.min(this.contentSize - this.containerSize, newAbsoluteOffset)
      )
    },
  },
}
</script>

<style lang="scss">
.own-slide-group {
  display: flex;
  position: relative;

  &__container {
    contain: content;
    display: flex;
    flex: 1 1 auto;
    overflow-x: hidden;
    overflow-y: visible;
  }

  &__content {
    display: flex;
    flex: 1 0 auto;
    transition: 0.2s all ease-in;
    white-space: nowrap;

    > * {
      white-space: initial;
    }

    &--control {
      padding: 2px; // Allow shadows to overflow
    }
  }

  &__next,
  &__prev {
    position: absolute;
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
    width: 50px;
    z-index: 9999;
  }

  &__prev {
    background-image: linear-gradient(
      to left,
      rgba(#fff, 0),
      rgba(#fff, 1) 50%
    );
  }

  &__next {
    background-image: linear-gradient(
      to left,
      rgba(#fff, 1) 50%,
      rgba(#fff, 0)
    );
    right: 0;
    justify-content: flex-end;
  }

  &__control-icon {
    display: flex;
    flex-shrink: 1;
    flex-direction: column;
    justify-content: center;
    padding: 5px;
    border-radius: 50%;
    box-shadow: 0 2px 5px rgba(29, 39, 53, 0.23);
  }
}
</style>
