<i18n locale="en">
{
  "labels": {
    "label": "Title",
    "options": "Options",
    "choices": "Choices",
    "required": "Required",
    "add": "Add option"
  }
}
</i18n>

<template>
  <div class="select-editor flex-col gap-8">
    <OwnInputContainer :label="t('labels.label')" :errors="label.status.errors">
      <OwnInput v-model="label.value" :placeholder="t('labels.label')" />
    </OwnInputContainer>
    <div class="flex-col gap-3">
      <OwnType :text="t('labels.options')" color="primary" variant="subtitle" />
      <OwnToggle
        v-model="required.value"
        :label="t('labels.required')"
        control
      />
    </div>
    <div class="flex-col gap-3">
      <OwnType :text="t('labels.choices')" color="primary" variant="subtitle" />
      <Reorderable
        v-slot="{ item, index }"
        v-model="options.value"
        item-key="option"
        class="space-y-3"
        @reorder="onReorder"
      >
        <OwnOption el="div">
          <div class="flex-row align-center justify-between">
            <div class="flex-row gap-3 align-center">
              <div
                class="select-editor__drag-handle drag-handle flex-col align-center"
              >
                <PhDotsSixVertical class="text-color-secondary" />
              </div>
              <OwnType
                class="select-editor__option-text"
                :text="item"
                variant="subtitle"
              />
            </div>
            <button @click="deleteOption(index)">
              <PhTrash class="text-color-secondary" />
            </button>
          </div>
        </OwnOption>
      </Reorderable>
      <OwnOption class="select-editor__new-option" el="div">
        <OwnInput
          v-model="newOption"
          :placeholder="t('labels.add')"
          @keyup.enter="commitNew"
        >
          <template #prepend>
            <PhPlusCircle />
          </template>
          <template #append>
            <button v-if="newOption" @click="commitNew">
              <PhCheckCircle class="misc-brand" />
            </button>
          </template>
        </OwnInput>
      </OwnOption>
    </div>
  </div>
</template>

<script>
import {
  PhDotsSixVertical,
  PhTrash,
  PhPlusCircle,
  PhCheckCircle,
} from '@phosphor-icons/vue'
import { useI18n } from 'vue-i18n'
import { mapGetters } from 'vuex'

import { FormBuilder } from '@/forms'
import {
  OwnInputContainer,
  OwnInput,
  OwnToggle,
  OwnType,
  OwnOption,
  Reorderable,
} from '@/ui'
import { reorder } from '@/utils/helpers'

import { SelectCommands } from '../commands/SelectCommands'

export default {
  name: 'SelectEditor',
  components: {
    OwnInput,
    OwnInputContainer,
    OwnOption,
    OwnToggle,
    OwnType,
    PhCheckCircle,
    PhDotsSixVertical,
    PhPlusCircle,
    PhTrash,
    Reorderable,
  },
  mixins: [
    FormBuilder({
      label: {
        debounce: 250,
        validators: [],
      },
      options: {
        debounce: 250,
        initialValue: [],
        validators: [],
      },
      required: {
        initialValue: false,
        validators: [],
      },
    }),
  ],
  props: {
    /**
     * Id getting passed to the editor component
     */
    id: { type: String, required: true },
  },
  setup() {
    const { t } = useI18n()

    return { t }
  },
  data() {
    return {
      editing: null,
      newOption: '',
      sectionData: undefined,
    }
  },
  computed: {
    ...mapGetters({
      blockById: 'formBuilder/blockById',
    }),
    currentBlockParams() {
      const { id } = this

      return this.blockById(id)?.params
    },
  },
  created() {
    const { currentBlockParams } = this

    this.setInitialFormValues(currentBlockParams)
  },
  methods: {
    addNew() {
      this.editing = 'new'
      this.newOption = ''
    },
    commitNew() {
      const { newOption, formValues } = this

      if (formValues.options.includes(newOption)) {
        this.newOption = ''
        return
      }

      if (newOption) {
        this.options.value = [...formValues.options, newOption]
        this.newOption = ''
      }
    },
    deleteOption(index) {
      const { formValues } = this
      const { options } = formValues

      options.splice(index, 1)
      this.options.value = [...options]
    },
    async onFormControlUpdate(controlName, update) {
      const { id } = this

      await SelectCommands.updateBlock({
        targetId: id,
        update: { params: { [controlName]: update } },
      })
    },
    async onReorder({ newIndex, oldIndex }) {
      const { formValues } = this

      const newOptions = reorder(formValues.options, oldIndex, newIndex)

      await this.onFormControlUpdate('options', newOptions)
    },
  },
}
</script>
<style lang="scss" scoped>
.select-editor {
  &__new-option {
    padding: 0;
    border: none;
  }

  &__drag-handle {
    cursor: grab;
  }

  &__option-text {
    user-select: none;
  }
}
</style>
