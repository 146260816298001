<i18n locale="en">
{
  "to": "to"
}
</i18n>

<template>
  <PreviewSheet class="event-card-preview" background="secondary">
    <OwnSkeletonLoader
      v-if="showLoading"
      class="event-card-preview__image"
      height="300"
    />
    <div
      v-else
      class="event-card-preview__image"
      :style="{ backgroundImage: `url(${event.image})` }"
    ></div>
    <div class="event-card-preview__content flex-col gap-2">
      <OwnSkeletonLoader v-if="showLoading" height="20" />
      <PreviewTypographyLegacy v-else :text="event.name" variant="heading4" />

      <OwnSkeletonLoader v-if="showLoading" height="16" />
      <PreviewTypographyLegacy v-else :text="time" variant="paragraphRegular" />
    </div>
  </PreviewSheet>
</template>

<script>
import { addMinutes, format, isSameDay, parse } from 'date-fns'
import { useI18n } from 'vue-i18n'
import { mapGetters } from 'vuex'

import { PreviewSheet, PreviewTypographyLegacy } from '@/lib/builder'
import { OwnSkeletonLoader } from '@/ui'

const renderDate = (datetime) => {
  return format(datetime, 'eee, MMM do')
}

const renderTime = (datetime) => {
  return format(datetime, 'h:mmaaa')
}

const renderHour = (datetime) => {
  return format(datetime, 'h')
}

const renderEventTime = (startDate, startTime, duration, toText) => {
  const start = parse(
    `${startDate} ${startTime}`,
    'yyyy-MM-dd h:mm aa',
    new Date()
  )
  const end = addMinutes(start, duration)

  if (isSameDay(start, end)) {
    return `${renderDate(start)}, ${renderHour(start)}-${renderTime(end)}`
  } else {
    return `${renderDate(start)} ${renderTime(start)} ${toText} ${renderDate(
      end
    )} ${renderTime(end)}`
  }
}

export default {
  name: 'EventCardPreview',
  components: {
    OwnSkeletonLoader,
    PreviewSheet,
    PreviewTypographyLegacy,
  },
  props: {
    /**
     * Event
     */
    event: { type: Object, required: true },
  },
  setup() {
    const { t } = useI18n()

    return { t }
  },
  computed: {
    ...mapGetters({
      mergedTheme: 'websiteBuilder/mergedTheme',
    }),
    showLoading() {
      const { event } = this

      return event.loading
    },
    time() {
      const { event } = this

      return renderEventTime(
        event.startDate,
        event.startTime,
        event.duration,
        this.t('to')
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.event-card-preview {
  width: 320px;

  &__content {
    padding: 24px 24px 32px 24px;
  }

  &__image {
    width: 320px;
    height: 300px;

    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
</style>
