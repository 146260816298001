import model from './model'

export default {
  RESET_STATE: (state) => {
    Object.keys(model).forEach((k) => {
      state[k] = model[k]
    })
  },

  SET_HOME: (state, home) => {
    state.home = home
  },

  SET_NOTIFICATIONS: (state, newNotifications) => {
    state.user.notifications = newNotifications
  },

  SET_SESSION: (state, { user, session }) => {
    const { csrfToken, expires, scopes } = session
    state.expiresAt = expires
    state.csrfToken = csrfToken
    state.scopes = scopes
    state.user = user
  },
}
