import { type Olympus } from '@owner/olympus-client'

import {
  type BlockCore,
  type BuilderState,
  type ResourceCore,
} from '@/lib/builder'

export const blockAddMutation = <
  TResource extends ResourceCore,
  TBlock extends BlockCore,
>(
  state: BuilderState<TResource, TBlock>,
  payload: Olympus.Funnel.Section
): void => {
  state._builder_currentState.blocks = [
    ...state._builder_currentState.blocks,
    // This cast is actually valid.  TBlock is a generic section.
    payload as unknown as TBlock,
  ]
}
