import mergeWith from 'lodash/mergeWith'
import { type PartialDeep } from 'type-fest'
import { type ActionContext } from 'vuex'

import {
  type BlockCore,
  type BuilderState,
  type ResourceCore,
  useBuilder,
} from '@/lib/builder'
import { type CoreModule } from '@/lib/store'
import { logError } from '@/logger'

export const updateResource = async <
  TResource extends ResourceCore,
  TBlock extends BlockCore,
>(
  {
    state,
    commit,
    dispatch,
  }: ActionContext<BuilderState<TResource, TBlock>, CoreModule>,
  update: PartialDeep<TResource>
): Promise<void> => {
  const currentResource = state._builder_currentState?.resource

  if (!currentResource) {
    logError('Unable to update an undefined resource')
  }

  const moduleName = state.moduleName

  const builderModule = useBuilder(moduleName)

  const createResourceCommand = builderModule.createResourceCommand<TResource>({
    commit: async (currentData, ctx) => {
      return mergeWith(currentData, ctx.update, (_objValue, srcValue) => {
        if (Array.isArray(srcValue)) return srcValue
      })
    },
  })

  await createResourceCommand({
    update,
  })
}
