<template>
  <div class="button" :style="style" :class="buttonClasses">
    <slot />
  </div>
</template>
<script>
import get from 'lodash/get'

import { useMergedTheme } from '../mixins/useMergedTheme'

const BUTTON_COLORS = {
  danger: 'danger.400',
  dimmed: 'text.placeholder',
  info: 'info.300',
  primary: 'brand.primary',
  secondary: 'text.secondary',
  success: 'success.300',
  warning: 'warning.300',
}

const SIZE_MAP = {
  large: ['py-7 px-14'],
  regular: ['py-5 px-10'],
  small: ['py-4 px-6'],
  xsmall: ['py-3 px-4'],
  zero: [],
}

export default {
  name: 'PreviewButtonLegacy',
  mixins: [useMergedTheme],
  props: {
    /** Button color */
    color: {
      type: String,
      default: 'primary',
      validator: (v) =>
        [
          'primary',
          'dimmed',
          'secondary',
          'success',
          'info',
          'warning',
          'danger',
        ].includes(v),
    },

    size: {
      type: String,
      default: 'regular',
      validator: (v) =>
        ['large', 'regular', 'small', 'xsmall', 'zero'].includes(v),
    },

    /** Button variant */
    variant: {
      type: String,
      default: 'contained',
      validator: (v) =>
        ['contained', 'outlined', 'ghost', 'text', 'textUnderline'].includes(v),
    },
  },
  computed: {
    buttonClasses() {
      const { size } = this

      return SIZE_MAP[size]
    },
    primaryColor() {
      const { color, mergedTheme } = this

      if (color) return get(mergedTheme.colors, BUTTON_COLORS[color])

      return mergedTheme.colors.brand.primary
    },
    style() {
      const { variant, primaryColor, mergedTheme } = this

      return {
        background: variant === 'contained' ? primaryColor : 'none',
        borderColor: variant !== 'text' ? primaryColor : undefined,
        borderRadius: `${mergedTheme.borderRadius.control}rem`,
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.button {
  text-align: center;
  border-width: 2px;
  border-style: solid;

  white-space: nowrap;

  width: fit-content;
  padding: 12px 18px;
}
</style>
