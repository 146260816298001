<i18n locale="en">
{
  "labels": {
    "logo": "Logo"
  }
}
</i18n>

<template>
  <EditorLayout>
    <div class="style-editor flex-col gap-8">
      <LogoEditor />
      <BrandColorEditor />
      <ThemePaletteEditor />
      <BorderStyleEditor />
      <FontEditor />
    </div>
  </EditorLayout>
</template>

<script>
import { EditorLayout } from '@/lib/builder'

import BorderStyleEditor from './BorderStyleEditor.vue'
import BrandColorEditor from './BrandColorEditor.vue'
import FontEditor from './FontEditor.vue'
import LogoEditor from './LogoEditor.vue'
import ThemePaletteEditor from './ThemePaletteEditor.vue'

export default {
  name: 'StyleEditor',
  components: {
    BorderStyleEditor,
    BrandColorEditor,
    EditorLayout,
    FontEditor,
    LogoEditor,
    ThemePaletteEditor,
  },
}
</script>
