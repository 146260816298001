import core from '@/core/store'
import checkoutSettings from '@/features/checkoutSettings/store'
import customers from '@/features/customers/store'
import formBuilder from '@/features/form-builder/builder/store'
import hours from '@/features/hours/store'
import emailBuilder from '@/features/marketing/builders/email/store'
import textBuilder from '@/features/marketing/builders/text/store'
import websiteBuilder from '@/features/website-builder/builder/store'

import app from './app'
import session from './session'

export default {
  app,
  checkoutSettings,
  core,
  customers,
  emailBuilder,
  formBuilder,
  hours,
  session,
  textBuilder,
  websiteBuilder,
}
