<template>
  <PreviewSheet class="gallery-preview" radius="0">
    <div class="flex-col gap-14 justify-center align-center">
      <div class="gallery-preview__text flex-col gap-4">
        <PreviewTypography :text="headerContent" variant="heading2" />
        <PreviewTypography
          v-if="subtitle"
          :text="subtitle"
          variant="paragraphRegular"
        />
      </div>

      <OwnGrid class="gallery-preview__img-grid">
        <PreviewSheet
          v-for="(image, idx) in currentBlockParams.images"
          :key="idx"
          class="gallery-preview__img"
          :style="{ backgroundImage: `url(${image})` }"
        />
      </OwnGrid>
    </div>
  </PreviewSheet>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { PreviewSheet, PreviewTypography } from '@/lib/builder'
import { OwnGrid } from '@/ui'

import type { GallerySection } from '../../../WebsiteBuilder.types'

const props = defineProps<{
  content: GallerySection
}>()

const currentBlockParams = computed(() => {
  return props.content?.params
})

const headerContent = computed(() => currentBlockParams.value.title)

const subtitle = computed(() => currentBlockParams.value.description)
</script>

<style lang="scss" scoped>
.gallery-preview {
  padding: 120px;

  &__text {
    max-width: 672px;
    padding: 0 16px;
    text-align: center;
  }

  &__img-grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 16px;
    flex: 1;
    width: 100%;
  }

  &__img {
    aspect-ratio: 1 / 1;
    background-size: cover;
  }
}
</style>
