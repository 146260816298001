import { type RouteRecordRaw } from 'vue-router'

export const domainsRouter: RouteRecordRaw[] = [
  {
    component: async () => import('../views/DomainsView.vue'),
    meta: {
      roles: ['pb:super'],
      title: 'website.domains',
    },
    name: 'brand.domains',
    path: 'domains',
  },
]
