import type { RouteLocationNormalized, RouteRecordRaw } from 'vue-router'

export const giftCardsRouter: RouteRecordRaw[] = [
  {
    children: [
      {
        component: async () => import('../views/GiftCardDetailView.vue'),
        name: 'brand.gift-cards.detail',
        path: ':giftCardId',
        props: (route: RouteLocationNormalized) => ({
          giftCardId: route.params.giftCardId,
        }),
      },
    ],
    component: async () => import('../views/GiftCardsView.vue'),
    meta: {
      title: 'restaurant.gift-cards',
    },
    name: 'brand.gift-cards',
    path: 'gift-cards',
  },
]
