<template>
  <div ref="reorderableList" class="flex-col gap-3">
    <!-- @vue-expect-error key should be part of item -->
    <OwnOption
      v-for="(item, index) of props.items"
      :key="item[itemKey]"
      @click="emit('click', item)"
    >
      <div class="flex-row gap-4 align-start w-full">
        <DragHandle v-if="props.reorderable" />
        <slot name="card" :item="item" :row-idx="index" />
      </div>
    </OwnOption>
  </div>
</template>

<script lang="ts" setup generic="T extends Record<string, unknown>">
import Sortable, { SortableEvent } from 'sortablejs'
import { onMounted, ref } from 'vue'

import { ReorderEvent } from '@/utils/helpers/reorder'

import { OwnOption } from '../../OwnOption'
import DragHandle from '../../reorderable/DragHandle.vue'

const props = defineProps<{
  hasClick: boolean
  items: T[]
  itemKey: string
  reorderable: boolean
}>()

const emit = defineEmits<{
  (event: 'click', value: T): void
  (event: 'reorder', value: ReorderEvent): void
}>()

const reorderableList = ref<HTMLDivElement | null>(null)

onMounted(() => {
  if (props.reorderable && reorderableList.value) {
    Sortable.create(reorderableList.value, {
      animation: 150,
      direction: 'vertical',
      handle: '.drag-handle',
      onEnd: ({ oldIndex, newIndex }: SortableEvent) => {
        if (oldIndex === newIndex) return
        emit('reorder', { newIndex, oldIndex } as ReorderEvent)
      },
    })
  }
})
</script>
