import { type TemplateData } from '@/lib/builder'

import {
  type FormCreate,
  type FormSectionCreate,
} from '../../types/FormBuilder.types'

export const blankTemplate = async (
  brandId: string
): Promise<TemplateData<FormCreate, FormSectionCreate>> => ({
  blocks: [],
  resource: {
    brandId,
    description: '',
    submit: null,
    title: '',
    type: 'custom',
  },
})
