import { type Olympus } from '@owner/olympus-client'
import {
  PhBookOpen,
  PhBriefcase,
  PhCar,
  PhClock,
  PhCreditCard,
  PhForkKnife,
  PhGlobe,
  PhGrainsSlash,
  PhHandbag,
  PhHeart,
  PhHouse,
  PhLeaf,
  PhMapPin,
  PhPhone,
  PhStar,
  PhTote,
  PhTruck,
  PhUsersFour,
} from '@phosphor-icons/vue'
import type { Component } from 'vue'

export const featureIcons: Record<Olympus.Funnel.FeatureIcon, Component> = {
  car: PhCar,
  card: PhCreditCard,
  cart: PhHandbag,
  delivery: PhTruck,
  dish: PhForkKnife,
  domains: PhGlobe,
  'grains-slash': PhGrainsSlash,
  heart: PhHeart,
  leaf: PhLeaf,
  overview: PhHouse,
  phone: PhPhone,
  pin: PhMapPin,
  star: PhStar,
  story: PhBookOpen,
  team: PhUsersFour,
  time: PhClock,
  tote: PhTote,
  work: PhBriefcase,
}
