import { type ComputedRef, computed } from 'vue'

import { useMappedGetter } from '@/store'

import { type WebsiteSection } from '../WebsiteBuilder.types'

export const useWebsiteSection = (
  targetId: string
): ComputedRef<WebsiteSection | undefined> => {
  const currentBlocks = useMappedGetter<WebsiteSection[]>(
    'websiteBuilder/currentBlocks'
  )

  return computed(() =>
    currentBlocks.value.find((block: { id: string }) => block.id === targetId)
  )
}
