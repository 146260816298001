<template>
  <div class="dual-tone-split-pane">
    <div class="dual-tone-split-pane__left-panel">
      <slot name="lhs" />
    </div>
    <div class="dual-tone-split-pane__right-panel">
      <slot name="rhs" />
    </div>
  </div>
</template>

<style lang="scss">
.dual-tone-split-pane {
  display: flex;
  flex-direction: column;
  background-color: $background-secondary;

  flex: 1;
  min-height: 100%;

  @include respond-to('md-and-up') {
    flex-direction: row;
  }

  &__left-panel {
    padding: 32px 16px 48px 16px;
    background-color: $background-primary;

    @include respond-to('md-and-up') {
      flex: 1;
      padding: 56px 100px 80px 100px;
    }
  }

  &__right-panel {
    padding: 48px 16px 80px 16px;
    border-top: 1px dashed $background-divider;

    @include respond-to('md-and-up') {
      flex: 1;
      padding: 56px 100px 80px 100px;
      border-top: none;
      border-left: 1px dashed $background-divider;
    }
  }
}
</style>
