<template>
  <div class="split-page">
    <div>
      <slot name="marketing" />
    </div>

    <div>
      <slot name="register" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.split-page {
  height: 100%;
  width: 100%;
  overflow: scroll;

  @include respond-to('lg-and-up') {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
</style>
