import { type CreateBlockData } from '@/lib/builder'

import {
  type EventCalendarSectionCreate,
  type EventCalendarSection,
} from '../../../WebsiteBuilder.types'

export const createEventCalendarData = ({
  brandId,
}: CreateBlockData<EventCalendarSection>): EventCalendarSectionCreate => ({
  brandId,
  params: null,
  type: 'event-calendar',
})
