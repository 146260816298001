<i18n locale="en">
{
  "options": {
    "America/Anchorage": "Alaska Time (AKST / AKDT)",
    "America/Chicago": "Central Time (CST)",
    "America/New_York": "Eastern Time (EST)",
    "Pacific/Honolulu": "Hawaii Standard Time (HST)",
    "America/Phoenix": "Mountain Standard Time (MST) (no DST)",
    "America/Denver": "Mountain Daylight Time (MDT)",
    "America/Los_Angeles": "Pacific Time (PST)"
  }
}
</i18n>

<template>
  <OwnDropdown
    v-model="modelValue"
    :options="options"
    :filter-keys="['label']"
  />
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'

import { OwnDropdown } from '@/ui'

const { t } = useI18n()

const allowedTimezones = [
  'Pacific/Honolulu',
  'America/Anchorage',
  'America/Los_Angeles',
  'America/Denver',
  'America/Phoenix',
  'America/Chicago',
  'America/New_York',
] as const

export type AllowedTimezone = (typeof allowedTimezones)[number]

const modelValue = defineModel<AllowedTimezone>()

const options = allowedTimezones.map((value) => ({
  label: t(`options.${value}`),
  value,
}))
</script>
