/* eslint-disable sort-keys-fix/sort-keys-fix,vue/sort-keys -- allow for logical ordering */

const typographyMapping = {
  heading1: {
    defaultColor: 'primary',
    defaultFontFamilyVariant: 'primary',
    fontSize: '3.5rem',
    fontWeight: 700,
    lineHeight: '4rem',
  },
  heading2: {
    defaultColor: 'primary',
    defaultFontFamilyVariant: 'primary',
    fontSize: '2.25rem',
    fontWeight: 700,
    lineHeight: '3rem',
  },
  heading3: {
    defaultColor: 'primary',
    defaultFontFamilyVariant: 'primary',
    fontSize: '2rem',
    fontWeight: 700,
    lineHeight: '2.5rem',
  },
  heading4: {
    defaultColor: 'primary',
    defaultFontFamilyVariant: 'primary',
    fontSize: '1.25rem',
    fontWeight: 700,
    lineHeight: '1.5rem',
  },
  titleLarge: {
    defaultColor: 'primary',
    defaultFontFamilyVariant: 'primary',
    fontSize: '1.125rem',
    fontWeight: 700,
    lineHeight: '1.5rem',
  },
  titleRegular: {
    defaultColor: 'primary',
    defaultFontFamilyVariant: 'primary',
    fontSize: '1rem',
    fontWeight: 700,
    lineHeight: '1.25rem',
  },
  titleSmall: {
    defaultColor: 'primary',
    defaultFontFamilyVariant: 'primary',
    fontSize: '0.875rem',
    fontWeight: 700,
    lineHeight: '1.25rem',
  },
  titleExtraSmall: {
    defaultColor: 'primary',
    defaultFontFamilyVariant: 'primary',
    fontSize: '0.75rem',
    fontWeight: 700,
    lineHeight: '1rem',
  },
  paragraphLarge: {
    defaultColor: 'secondary',
    defaultFontFamilyVariant: 'secondary',
    fontSize: '1.5rem',
    fontWeight: 400,
    lineHeight: '2rem',
  },
  paragraphRegular: {
    defaultColor: 'secondary',
    defaultFontFamilyVariant: 'secondary',
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '1.5rem',
  },
  paragraphSmall: {
    defaultColor: 'secondary',
    defaultFontFamilyVariant: 'secondary',
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '1.25rem',
  },
  paragraphExtraSmall: {
    defaultColor: 'secondary',
    defaultFontFamilyVariant: 'secondary',
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: '1.125rem',
  },
  buttonLarge: {
    defaultColor: 'primary',
    defaultFontFamilyVariant: 'primary',
    fontSize: '1.25rem',
    fontWeight: 700,
    lineHeight: '1.75rem',
  },
  buttonRegular: {
    defaultColor: 'primary',
    defaultFontFamilyVariant: 'primary',
    fontSize: '1.125rem',
    fontWeight: 700,
    lineHeight: '1.5rem',
  },
  buttonSmall: {
    defaultColor: 'primary',
    defaultFontFamilyVariant: 'primary',
    fontSize: '0.875rem',
    fontWeight: 700,
    lineHeight: '1rem',
  },
  link: {
    defaultColor: 'primary',
    defaultFontFamilyVariant: 'secondary',
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '1.5rem',
  },
  bold: {
    defaultColor: 'secondary',
    defaultFontFamilyVariant: 'secondary',
    fontSize: '1rem',
    fontWeight: 700,
    lineHeight: '1.5rem',
  },
}

const typographyColors = {
  primary: 'text.primary',
  secondary: 'text.secondary',
  placeholder: 'text.placeholder',
  brand: 'brand.primary',
  success: 'success.300',
  info: 'info.300',
  warning: 'warning.300',
  danger: 'danger.400',
}
/* eslint-enable sort-keys-fix/sort-keys-fix,vue/sort-keys */

export { typographyColors, typographyMapping }
