import { type RouteRecordRaw } from 'vue-router'

export const customersRouter: RouteRecordRaw[] = [
  {
    children: [
      {
        children: [
          {
            component: async () =>
              import('../views/details/panels/CustomerDetailsRecentOrders.vue'),
            meta: {
              title: 'customers',
            },
            name: 'brand.customers.detail.orders',
            path: 'orders',
            props: true,
          },
          {
            component: async () =>
              import('../views/details/panels/CustomerDetailsReviews.vue'),
            meta: {
              title: 'customers',
            },
            name: 'brand.customers.detail.reviews',
            path: 'reviews',
            props: true,
          },
          {
            component: async () =>
              import(
                '../views/details/panels/CustomerDetailsFavoriteItems.vue'
              ),
            meta: {
              title: 'customers',
            },
            name: 'brand.customers.detail.favorites',
            path: 'favorites',
            props: true,
          },
        ],
        component: async () =>
          import('../views/details/CustomerDetailsView.vue'),
        name: 'brand.customers.detail',
        path: ':customerId',
        props: true,
        redirect: { name: 'brand.customers.detail.orders' },
      },
      {
        component: async () =>
          import('../views/details/CustomerDetailsView.vue'),
        meta: {
          title: 'customers',
        },
        name: 'brand.customers.main',
        path: '',
        props: true,
      },
    ],
    component: async () => import('../views/CustomersView.vue'),
    meta: {
      scopes: ['customers:read'],
      title: 'customers',
    },
    name: 'brand.customers',
    path: 'customers',
  },
]
