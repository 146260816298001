<template>
  <div
    :class="[
      'w-full h-full flex-col justify-center align-center',
      `bg-background-${background}`,
    ]"
  >
    <OwnProgressSpinner />
  </div>
</template>

<script>
import { OwnProgressSpinner } from '@/ui'

export default {
  name: 'FullScreenLoader',
  components: {
    OwnProgressSpinner,
  },
  props: {
    background: {
      type: String,
      default: 'primary',
      validator: (v) => ['primary', 'secondary'].includes(v),
    },
  },
}
</script>
