import client from '@/api/client'
import { omit } from '@/utils/omit'

/**
 * Creates Paginated Route Api
 * @param {string} apiRoot Api Root
 * @param {object} defaultResource Default resource
 */
const MenuPaginatedRouteFactory = (apiRoot, defaultResource) => {
  return {
    /**
     * Add relation to relation list.  Note: only available at the parent level, not the grandparent level
     * @param {string} id Target Resource
     * @param {'coupons' | 'categories' | 'items' | 'menus' | 'modifier-sets' | 'modifiers'} relationType Data type of target relation
     * @param {string} relationId Id of the resource to add
     * @returns {Promise} Results
     */
    addRelation: async (id, relationType, relationId) =>
      client.put(`/${apiRoot}/${id}/relations/${relationType}/${relationId}`),

    /**
     * Creates resource using default resource config
     * @param {object} resource instance resource - overwrites default config
     * @param {string} resource.brandId [REQUIRED] - BrandId
     * @param {string} resource.locationId [REQUIRED] - LocationId
     * @param {string} resource.displayName [REQUIRED] - Name of resource
     * @param {object} [params] Query params
     */
    create(resource, params = {}) {
      if (!defaultResource)
        throw new Error('Resource Default needs to be defined')

      const mergedResource = {
        ...defaultResource,
        ...resource,
        displayName: resource.displayName,
        name: resource.displayName,
      }

      return client.post(`/${apiRoot}`, mergedResource, { params })
    },

    /**
     * Create a brand level resource from a location level resource
     * @param {object} locationLevelResource Resource
     */
    createBrandResource(locationLevelResource) {
      throw new Error(
        `implement createBrandResource for ${apiRoot}`,
        locationLevelResource
      )
    },

    /**
     * Creates location level resources from a brand level resource
     * @param {object} brandLevelResource Resource
     * @param {Array} locationIds Ids to create location resources
     */
    createLocationResource(brandLevelResource, locationIds) {
      throw new Error(
        `implement createLocationResource for ${apiRoot}`,
        brandLevelResource,
        locationIds
      )
    },

    /**
     * Delete a resource
     * @param {string} id Resource Id
     * @returns {Promise} Updated Resource
     */
    delete: async (id) => client.delete(`/${apiRoot}/${id}`),

    /**
     * Duplicate a resource
     * @param {object} resource Instance of resource
     * @returns {Promise} New Resource
     */
    duplicate: async (resource) => {
      const sanitizedResource = {
        ...omit('id', resource),
        displayName: `${resource.displayName} (copy)`,
        name: `${resource.name} (copy)`,
      }

      return client.post(`/${apiRoot}`, sanitizedResource)
    },

    /**
     * Gets details
     * @param {string} id Id to get
     * @param {object} [query] Query to send
     * @returns {Promise} Single detail results
     */
    getDetails: async (id, query) => client.get(`/${apiRoot}/${id}`, query),

    /**
     * Gets results from paginated endpoint
     * @param {object} query Query To Send
     * @param {number} query.page Page Number
     * @param {number} query.limit Limit Per Page
     * @param {string} [query.brandId] Owner Brand Id
     * @param {string} [query.locationId] Owner Location Id
     * @param {string} [query.query] Search Query
     * @returns {Promise} Paginate Result
     */
    getGroup: async (query) => client.get(`/${apiRoot}`, query),

    /**
     * Get details of relation
     * @param {string} id Target Resource
     * @param {'coupons' | 'categories' | 'items' | 'menus' | 'modifier-sets' | 'modifiers'} relationType Data type of target relation
     * @returns {Promise} Results
     */
    getRelation: async (id, relationType) =>
      client.get(`/${apiRoot}/${id}/relations/${relationType}`),

    /**
     * Delete relation from the relation list.
     * @param {string} id Target Resource
     * @param {'coupons' | 'categories' | 'items' | 'menus' | 'modifier-sets' | 'modifiers'} relationType Data type of target relation
     * @param {string} relationId Id of the resource to delete
     * @returns {Promise} Results
     */
    removeRelation: async (id, relationType, relationId) =>
      client.delete(
        `/${apiRoot}/${id}/relations/${relationType}/${relationId}`
      ),

    /**
     * Updates a resource
     * @param {string} id Resource Id
     * @param {object} update Update
     * @returns {Promise} Updated Resource
     */
    update: async (id, update) => client.patch(`/${apiRoot}/${id}`, update),
  }
}

export { MenuPaginatedRouteFactory }
