import { type RouteRecordRaw } from 'vue-router'

export const reviewsRouter: RouteRecordRaw[] = [
  {
    children: [
      {
        component: async () => import('../views/ReviewsDetailView.vue'),
        name: 'brand.feedback.detail',
        path: ':reviewId',
        props: (route) => ({
          reviewId: route.params.reviewId,
        }),
      },
    ],
    component: async () => import('../views/ReviewsMainView.vue'),
    meta: {
      title: 'restaurant.feedback',
    },
    name: 'brand.feedback',
    path: 'feedback',
  },
]
