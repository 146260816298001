import { ConfiguredClient } from '@/api'
import { type BuilderFetchArgs, type BuilderFetchResult } from '@/lib/builder'

import { type CampaignResource, type SmsSection } from '../types'

export const fetchResource = async (
  campaignId: string
): Promise<{ resource: CampaignResource; blocks: SmsSection[] }> => {
  const [campaign, [textBlock]] = await Promise.all([
    ConfiguredClient.marketing.v1.campaigns.campaign.getCampaign({
      params: { campaignId },
    }),
    ConfiguredClient.marketing.v1.campaigns.campaign.getBlocks({
      params: { campaignId },
    }),
  ])

  if (textBlock?.type !== 'action' || textBlock?.params?.type !== 'send-sms')
    throw new Error('Invalid first block in campaign')

  const smsTemplateId = textBlock.params.params.templateId as string

  const [{ sections }, blocks] = await Promise.all([
    ConfiguredClient.notifications.v1.templates.sms.sms.getSMSTemplate({
      params: { smsTemplateId },
    }),
    ConfiguredClient.notifications.v1.templates.sms.sms.getSections({
      params: { smsTemplateId },
    }),
  ])

  return {
    blocks,
    resource: {
      brandId: campaign.brandId,
      executeAt: campaign.executeAt ? new Date(campaign.executeAt) : null,
      id: campaignId,
      locationId: campaign.locationId,
      name: campaign.name,
      sections,
      smsTemplateId,
      status: campaign.status,
      transport: campaign.transport,
    },
  }
}

export const fetchTextData = async ({
  resourceId: campaignId,
}: BuilderFetchArgs): Promise<
  BuilderFetchResult<CampaignResource, SmsSection>
> => {
  const [{ resource, blocks }] = await Promise.all([fetchResource(campaignId)])

  return {
    blocks,
    resource,
  }
}
