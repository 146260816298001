<i18n locale="en">
{
  "actions": {
    "add": "Add Brand",
    "change": "Change Brand"
  },
  "empty": "No brand selected"
}
</i18n>

<template>
  <div>
    <div class="flex-col gap-3">
      <OwnCardList v-if="selectedBrand" :model-value="[selectedBrand]" control>
        <template #item="{ item }">
          <div class="flex-row gap-3 justify-between align-center">
            <div class="flex-row gap-3 align-center">
              <BrandLogo :brand="item" />
              <OwnType
                class="text--truncate"
                :text="item.name"
                :title="item.name"
                variant="subtitle"
                color="primary"
              />
            </div>

            <button class="flex-shrink-0 text-color-secondary">
              <PhXCircle size="24" @click="removeSelectedBrand" />
            </button>
          </div>
        </template>
      </OwnCardList>

      <OwnOption v-else el="div">
        <DefaultEmptyState :message="t('empty')">
          <template #icon>
            <PhBookBookmark size="24" />
          </template>
        </DefaultEmptyState>
      </OwnOption>

      <OwnButton
        class="w-full"
        :text="selectedBrand ? t('actions.change') : t('actions.add')"
        @click="showAddBrandDialog = true"
      />
    </div>

    <BrandSelectorDialog
      v-if="showAddBrandDialog"
      @select="onBrandSelect"
      @close="closeDialog"
    />
  </div>
</template>

<script lang="ts" setup>
import { PhBookBookmark, PhXCircle } from '@phosphor-icons/vue'
import { onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { ConfiguredClient } from '@/api'
import { DefaultEmptyState } from '@/components/empty'
import { type Brand } from '@/core'
import { OwnButton, OwnCardList, OwnOption, OwnType } from '@/ui'

import BrandLogo from '../BrandLogo.vue'

import BrandSelectorDialog from './BrandSelectorDialog.vue'

const { t } = useI18n()

const selectedBrandId = defineModel<string>()

const selectedBrand = ref<Brand>()

const showAddBrandDialog = ref(false)

const removeSelectedBrand = () => {
  selectedBrandId.value = undefined
  selectedBrand.value = undefined
}

const loadBrand = async (brandId: string) => {
  if (brandId !== selectedBrandId.value) {
    const result = await ConfiguredClient.brands.brand.getBrand({
      params: {
        brandId,
      },
    })

    if (result) {
      selectedBrand.value = result
      selectedBrandId.value = result.id
    }
  }
}

const closeDialog = () => {
  showAddBrandDialog.value = false
}

const onBrandSelect = (brand: Brand) => {
  selectedBrandId.value = brand.id
  selectedBrand.value = brand

  closeDialog()
}

watch(selectedBrandId, async (value) => {
  if (value) {
    await loadBrand(value)
  }
})

onMounted(async () => {
  if (selectedBrandId.value) {
    await loadBrand(selectedBrandId.value)
  }
})
</script>
