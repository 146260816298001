import { type MutationTree } from 'vuex'

import { type CoreState } from '../types'

import { SET_ACTIVE_BRAND } from './brand/SET_ACTIVE_BRAND'
import { SET_BRANDS } from './brand/SET_BRANDS'
import { SET_THEME } from './brand/SET_THEME'

const brandMutations: MutationTree<CoreState> = {
  SET_ACTIVE_BRAND,
  SET_BRANDS,
  SET_THEME,
}

const coreMutations = {
  ...brandMutations,
}

export { coreMutations }
