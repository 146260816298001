<i18n locale="en">
{
  "export-applications": "Export Applications",
  "export-error": "Could not export applications",
  "new-job": "New Role",
  "views": {
    "applications": "Applications",
    "jobs": "Open Roles"
  }
}
</i18n>
<template>
  <DefaultPageLayout>
    <template #actions>
      <OwnButton
        :processing="isExporting"
        :text="t('export-applications')"
        @click="onExport"
      />
      <OwnButton
        :text="t('new-job')"
        primary
        @click="$router.push({ name: 'location.careers.jobs.new' })"
      />
    </template>
    <template #filter-bar>
      <OwnSegmentedControl
        :options="views"
        el="router-link"
        variant="navigation"
      />
    </template>

    <router-view />
  </DefaultPageLayout>
</template>
<script>
import { useI18n } from 'vue-i18n'
import { mapGetters } from 'vuex'

import { ConfiguredClient } from '@/api'
import { DefaultPageLayout } from '@/components/layouts'
import { logError } from '@/logger'
import notify from '@/mixins/notify'
import { OwnButton, OwnSegmentedControl } from '@/ui'
import { download } from '@/utils'

export default {
  name: 'CareersView',
  components: {
    DefaultPageLayout,
    OwnButton,
    OwnSegmentedControl,
  },
  mixins: [notify],
  setup() {
    const { t } = useI18n()

    return { t }
  },
  data() {
    return {
      isExporting: false,
      views: ['jobs', 'applications'].map((name) => ({
        label: this.t(`views.${name}`),
        value: { name: `location.careers.${name}` },
      })),
    }
  },
  computed: {
    ...mapGetters({
      activeLocationId: 'core/location/active/id',
    }),
  },
  methods: {
    async onExport() {
      const { activeLocationId } = this

      try {
        this.isExporting = true
        const { downloadUrl } =
          await ConfiguredClient.careers.v1.locations.location.exportApplications(
            {
              params: { locationId: activeLocationId },
            }
          )

        download(downloadUrl, 'applications.csv')
      } catch (error) {
        logError(error)
        this.$notify(this.t('export-error'), 'error')
      } finally {
        this.isExporting = false
      }
    },
  },
}
</script>
