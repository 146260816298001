<template>
  <CouponSelectorSingle
    v-if="resource.locationId && resource.brandId"
    class="coupon-editor"
    :model-value="couponId"
    :location-id="resource.locationId"
    :brand-id="resource.brandId"
    @update:model-value="update"
  />
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue'

import { CouponSelectorSingle } from '@/features/menu'
import { useMappedGetter } from '@/store'

import { CampaignResource, CouponSection } from '../../../types'
import { CouponCommands } from '../commands/CouponCommands'

const props = defineProps<{ id: string }>()

const couponId = ref<string>()

const blockById = useMappedGetter<(blockId: string) => CouponSection>(
  'emailBuilder/blockById'
)
const resource = useMappedGetter<CampaignResource>(
  'emailBuilder/currentResource'
)

const currentBlock = computed<CouponSection>(() => {
  return blockById.value(props.id)
})

const update = async (selectedCouponId: string | undefined) => {
  couponId.value = selectedCouponId

  await CouponCommands.updateBlock({
    targetId: props.id,
    update: {
      data: {
        params: {
          couponId: couponId.value,
        },
        type: 'id',
      },
    },
  })
}

onMounted(() => {
  if (currentBlock.value?.data?.type === 'id') {
    couponId.value = currentBlock.value?.data?.params?.couponId
  }
})
</script>
