import { type CreateBlockData } from '@/lib/builder'

import {
  type ReviewsSectionCreate,
  type ReviewsSection,
} from '../../../WebsiteBuilder.types'

export const createReviewsData = ({
  brandId,
}: CreateBlockData<ReviewsSection>): ReviewsSectionCreate => ({
  brandId,
  params: {
    image: null,
    layout: 'generic',
    reviews: [
      {
        comment:
          'Pulvinar elementum integer enim neque volutpat. Nam libero justo laoreet sit. Dignissim enim sit amet venenatis. Vulputate enim nulla aliquet porttitor.',
        image:
          'https://static-content.owner.com/brands/funnel/reviews-section-images/rYSPbAUtjkwGvMLIAx0Rf8aAHB1nEcxZ/khXoR6Z3I8hC3gZ3NXFgWWH7.png?v=4761751329',
        name: 'John D.',
        stars: 5,
      },
      {
        comment:
          'Commodo ullamcorper a lacus vestibulum sed. Nulla pellentesque dignissim enim sit amet venenatis urna. Tortor posuere ac ut consequat. Volutpat ac tincidunt vitae semper quis lectus nulla at.',
        image:
          'https://static-content.owner.com/brands/funnel/reviews-section-images/tJIBWf8quS9QVWq25MOpEQinBBfTOdDr/khXoR6Z3I8hC3gZ3NXFgWWH7.png?v=8591941645',
        name: 'Jane D.',
        stars: 5,
      },
      {
        comment:
          'Eget nunc lobortis mattis aliquam. Malesuada proin libero nunc consequat interdum varius. Vitae justo eget magna fermentum iaculis eu.',
        image:
          'https://static-content.owner.com/brands/funnel/reviews-section-images/YECnB450nmOE7J1oBLKB9twcx13jaiSO/khXoR6Z3I8hC3gZ3NXFgWWH7.png?v=3697994388',
        name: 'Chad M.',
        stars: 5,
      },
    ],
  },
  type: 'reviews',
})
