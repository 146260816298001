import {
  format,
  parse,
  endOfMonth,
  startOfMonth,
  subDays,
  subMonths,
  addDays,
} from 'date-fns'

const HTTP_DATE_FORMAT = 'yyyy-MM-dd'

const formatForHTTP = (date) => {
  if (!date) return date
  return format(date, HTTP_DATE_FORMAT)
}

const formatLabel = (timeFrame) => {
  if (!timeFrame.start || !timeFrame.end) return timeFrame

  const parsedStart = parse(timeFrame.start, HTTP_DATE_FORMAT, new Date())
  const parsedEnd = parse(timeFrame.end, HTTP_DATE_FORMAT, new Date())

  if (timeFrame.start === timeFrame.end) {
    return format(parsedStart, 'MMM dd')
  }
  return `${format(parsedStart, 'MMM dd')}–${format(parsedEnd, 'MMM dd')}`
}

const getDateRange = (value) => {
  const now = new Date()

  let timeFrame

  switch (value) {
    case 'today':
      timeFrame = {
        end: formatForHTTP(addDays(now, 1)),
        start: formatForHTTP(now),
      }

      return {
        ...timeFrame,
        rightText: formatLabel(timeFrame),
      }

    case 'current-month':
      timeFrame = {
        end: formatForHTTP(now),
        start: formatForHTTP(startOfMonth(now)),
      }

      return {
        ...timeFrame,
        rightText: formatLabel(timeFrame),
      }

    case 'last-7-days':
      timeFrame = {
        end: formatForHTTP(now),
        start: formatForHTTP(subDays(now, 7)),
      }

      return {
        ...timeFrame,
        rightText: formatLabel(timeFrame),
      }

    case 'last-30-days':
      timeFrame = {
        end: formatForHTTP(now),
        start: formatForHTTP(subDays(now, 30)),
      }

      return {
        ...timeFrame,
        rightText: formatLabel(timeFrame),
      }

    case 'custom':
      timeFrame = {
        end: formatForHTTP(now),
        start: formatForHTTP(subDays(now, 30)),
      }

      return {
        ...timeFrame,
        rightText: null,
      }

    case 'last-month':
      timeFrame = {
        end: formatForHTTP(endOfMonth(subMonths(now, 1))),
        start: formatForHTTP(startOfMonth(subMonths(now, 1))),
      }

      return {
        ...timeFrame,
        rightText: formatLabel(timeFrame),
      }

    default:
      return {
        end: null,
        rightText: null,
        start: null,
      }
  }
}

export { getDateRange }
