const VITE_DEBUG_ANALYTICS = import.meta.env.VITE_DEBUG_ANALYTICS
const VITE_FULLSTORY_KEY = import.meta.env.VITE_FULLSTORY_KEY

const isFullstoryEnabled =
  (VITE_DEBUG_ANALYTICS === 'true' && !!VITE_FULLSTORY_KEY) ||
  (import.meta.env.MODE === 'production' && VITE_FULLSTORY_KEY)

const fullstoryOrgKey = VITE_FULLSTORY_KEY

export { fullstoryOrgKey, isFullstoryEnabled }
