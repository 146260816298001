<i18n locale="en">
{
  "labels": {
    "title": "Title",
    "options": "Options",
    "required": "Required",
    "placeholder": "Placeholder",
    "multiline": "Allow Multiple Lines",
    "number": "Number"
  }
}
</i18n>

<template>
  <div class="flex-col gap-8">
    <OwnInputContainer :label="t('labels.title')" :errors="label.status.errors">
      <OwnInput v-model="label.value" :placeholder="t('labels.title')" />
    </OwnInputContainer>
    <OwnInputContainer
      :label="t('labels.placeholder')"
      :errors="placeholder.status.errors"
    >
      <OwnInput
        v-model="placeholder.value"
        :placeholder="t('labels.placeholder')"
      />
    </OwnInputContainer>
    <div class="flex-col gap-3">
      <OwnType :text="t('labels.options')" color="primary" variant="subtitle" />
      <OwnToggle
        v-model="required.value"
        :label="t('labels.required')"
        control
      />
      <OwnToggle v-model="number.value" :label="t('labels.number')" control />
      <OwnToggle
        v-model="multiline.value"
        :label="t('labels.multiline')"
        control
      />
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { mapGetters } from 'vuex'

import { FormBuilder } from '@/forms'
import { OwnInputContainer, OwnInput, OwnToggle, OwnType } from '@/ui'

import { TextInputCommands } from '../commands/TextInputCommands'

export default {
  name: 'TextInputEditor',
  components: {
    OwnInput,
    OwnInputContainer,
    OwnToggle,
    OwnType,
  },
  mixins: [
    FormBuilder({
      label: {
        validators: [],
      },
      multiline: {
        initialValue: false,
        validators: [],
      },
      number: {
        initialValue: false,
        validators: [],
      },
      placeholder: {
        validators: [],
      },
      required: {
        initialValue: false,
        validators: [],
      },
    }),
  ],
  props: {
    /**
     * Id getting passed to the editor component
     */
    id: { type: String, required: true },
  },
  data() {
    return {
      sectionData: undefined,
    }
  },
  setup() {
    const { t } = useI18n()

    return { t }
  },
  computed: {
    ...mapGetters({
      blockById: 'formBuilder/blockById',
    }),
    currentBlock() {
      const { id } = this

      return this.blockById(id)
    },
    currentBlockParams() {
      const { currentBlock } = this

      return currentBlock?.params
    },
    type() {
      const { currentBlock } = this

      return currentBlock?.type
    },
  },
  created() {
    const { currentBlockParams } = this

    const { label, placeholder, required, variant } = currentBlockParams

    this.setInitialFormValues({
      label,
      multiline: variant === 'multiline',
      number: variant === 'number',
      placeholder,
      required,
    })
  },
  methods: {
    async onFormControlUpdate(controlName, update) {
      const { id } = this

      if (controlName === 'multiline' || controlName === 'number') {
        let newVariant = null
        if (update) {
          newVariant = controlName

          if (controlName === 'multiline') this.number.value = false
          else this.multiline.value = false
        } else {
          this.number.value = false
          this.multiline.value = false
        }

        await TextInputCommands.updateBlock({
          targetId: id,
          update: { params: { variant: newVariant } },
        })
      } else {
        await TextInputCommands.updateBlock({
          targetId: id,
          update: { params: { [controlName]: update } },
        })
      }
    },
  },
}
</script>
