import { type RouteLocationRaw } from 'vue-router'

import {
  type BlockCore,
  type BuilderState,
  type ResourceCore,
} from '@/lib/builder'

export const setReturnLocationMutation = <
  TResource extends ResourceCore,
  TBlock extends BlockCore,
>(
  state: BuilderState<TResource, TBlock>,
  returnLocation?: RouteLocationRaw
): void => {
  state._builder_returnLocation = returnLocation
}
