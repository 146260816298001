import { type ActionTree } from 'vuex'

import { type CoreState } from '../types'

import { clearAllBrands } from './brand/clearAllBrands'
import { loadBrand } from './brand/loadBrand'
import { setActiveBrand } from './brand/setActiveBrand'
import { setAllBrands } from './brand/setAllBrands'
import { updateBrand } from './brand/updateBrand'
import { updateGeneralLocationSettings } from './location/updateGeneralLocationSettings'
import { updateLocationProcessing } from './location/updateLocationProcessing'

const brandActions: ActionTree<CoreState, unknown> = {
  'brand/load': loadBrand,
  'brand/setActive': setActiveBrand,
  'brand/update': updateBrand,
  'brands/clearAllBrands': clearAllBrands,
  'brands/setAll': setAllBrands,
}

const locationActions: ActionTree<CoreState, unknown> = {
  'location/update/general': updateGeneralLocationSettings,
  'location/update/processing': updateLocationProcessing,
}

const coreActions = {
  ...brandActions,
  ...locationActions,
}

export { coreActions }
