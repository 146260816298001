import { PbError } from '@owner/errors'

class ResourceNotFoundError extends PbError {
  constructor(args) {
    super(args.message, args)
    this.name = 'ResourceNotFoundError'
    this.code = args.code
    this.message = args.message
    this.status = args.status
  }
}

export { ResourceNotFoundError }
