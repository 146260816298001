<i18n locale="en">
{
  "labels": {
    "description": "A brief description or summary that highlights the content or purpose."
  },
  "name": {
    "label": "Email Subject"
  }
}
</i18n>

<template>
  <OwnInputContainer
    :label="t('name.label')"
    :description="t('labels.description')"
    :errors="name.status.errors"
    :max-chars="MAX_NAME_LENGTH"
    :value="name.value"
  >
    <OwnInput v-model="name.value" />
  </OwnInputContainer>
</template>

<script>
import { useI18n } from 'vue-i18n'

import { FormBuilderModelSync, Validators } from '@/forms'
import { OwnInput, OwnInputContainer } from '@/ui'

const MAX_NAME_LENGTH = 200

export default {
  name: 'CreateEmailForm',
  components: {
    OwnInput,
    OwnInputContainer,
  },
  mixins: [
    FormBuilderModelSync({
      name: {
        validators: [
          Validators.required,
          Validators.maxLength(MAX_NAME_LENGTH),
        ],
      },
    }),
  ],
  setup() {
    const { t } = useI18n()

    return { t }
  },
  data() {
    return {
      MAX_NAME_LENGTH,
    }
  },
}
</script>
