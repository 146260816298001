import type { RouteLocationNormalized, RouteRecordRaw } from 'vue-router'

const eventsRouter: RouteRecordRaw[] = [
  {
    children: [
      {
        component: async () => import('../views/EventsDetailView.vue'),
        name: 'brand.events.detail',
        path: ':eventId',
        props: (route: RouteLocationNormalized) => ({
          detailId: route.params.eventId,
        }),
      },
    ],
    component: async () => import('../views/EventsMainView.vue'),
    meta: {
      title: 'website.events',
    },
    name: 'brand.events',
    path: 'events',
  },
]

export { eventsRouter }
