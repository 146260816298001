<i18n locale="en">
{
  "labels": {
    "size": "Size",
    "text": "Text"
  },
  "size-options": {
    "heading": "Headline",
    "subheading": "Subheading",
    "paragraph": "Body"
  },
  "placeholders": {
    "text": "Start typing your message"
  }
}
</i18n>

<template>
  <div class="text-editor flex-col gap-8">
    <OwnInputContainer :label="t('labels.text')" :errors="text.status.errors">
      <OwnTextarea
        v-if="currentBlock?.type === 'paragraph'"
        v-model="text.value"
        :placeholder="t('placeholders.text')"
      />
      <OwnInput
        v-else
        v-model="text.value"
        :placeholder="t('placeholders.text')"
      />
    </OwnInputContainer>
    <OwnInputContainer :label="t('labels.size')" :errors="size.status.errors">
      <OwnSegmentedControl v-model="size.value" :options="sizeOptions" />
    </OwnInputContainer>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import { useI18n } from 'vue-i18n'
import { mapGetters } from 'vuex'

import { FormBuilder, Validators } from '@/forms'
import {
  OwnSegmentedControl,
  OwnInput,
  OwnInputContainer,
  OwnTextarea,
} from '@/ui'

import { TextCommands } from '../commands/TextCommands'

export default {
  name: 'TextEditor',
  components: {
    OwnInput,
    OwnInputContainer,
    OwnSegmentedControl,
    OwnTextarea,
  },
  mixins: [
    FormBuilder({
      size: {
        validateOnInit: true,
        validators: [Validators.required],
      },
      text: {
        validateOnBlur: true,
        validators: [Validators.required],
      },
    }),
  ],
  props: {
    /** ID of the block */
    id: { type: String, required: true },
  },
  setup() {
    const { t } = useI18n()

    return { t }
  },
  data() {
    return {
      sizeOptions: ['heading', 'subheading', 'paragraph'].map((value) => ({
        label: this.t(`size-options.${value}`),
        value,
      })),
    }
  },
  computed: {
    ...mapGetters({
      blockById: 'emailBuilder/blockById',
    }),
    currentBlock() {
      const { id } = this

      return this.blockById(id)
    },
  },
  created() {
    const { currentBlock } = this

    this.text.value = currentBlock?.data?.text ?? ''
    this.size.value = currentBlock?.type
  },
  methods: {
    async applySizeUpdate(size) {
      await TextCommands.updateBlock({
        targetId: this.id,
        update: {
          type: size,
        },
      })
    },
    applyTextUpdate: debounce(async function (text) {
      const { id: targetId, isFormValid } = this

      if (!isFormValid) return

      await TextCommands.updateBlock({
        targetId,
        update: { data: { text } },
      })
    }, 500),
    onFormControlUpdate(controlName, update) {
      if (!this.isFormValid) return

      switch (controlName) {
        case 'text':
          return this.applyTextUpdate(update)
        case 'size':
          return this.applySizeUpdate(update)
      }
    },
  },
}
</script>
