<i18n locale="en">
  {
      "image": "Hero Carousel Image"
  }
</i18n>

<template>
  <MultiImageInput
    :model-value="images"
    :label="t('image')"
    :limit="5"
    :aspect-ratio="imageAspectRatio"
    @change="onChange"
  />
</template>

<script>
import { useI18n } from 'vue-i18n'
import { mapGetters } from 'vuex'

import { MultiImageInput } from '@/components/inputs'
import { logWarn } from '@/logger'

import { LandingHeroCommands } from '../commands/LandingHeroCommands'

export default {
  name: 'HeroCarouselEditor',
  components: {
    MultiImageInput,
  },
  props: {
    /**
     * Id getting passed to the editor component
     */
    id: { type: String, required: true },
  },
  setup() {
    const { t } = useI18n()

    return { t }
  },
  computed: {
    ...mapGetters({
      blockById: 'websiteBuilder/blockById',
    }),
    currentBlockParams() {
      const { id } = this

      return this.blockById(id)?.params
    },
    imageAspectRatio() {
      const { currentBlockParams } = this

      if (currentBlockParams.layout === 'card') {
        return 0.75
      } else {
        return 1.6
      }
    },
    images() {
      const { currentBlockParams } = this

      return currentBlockParams?.carouselAssets?.map(({ image }) => image)
    },
  },
  methods: {
    async applyHeroCarouselUpdate(data) {
      const { id } = this

      await LandingHeroCommands.updateBlock({
        targetId: id,
        update: {
          params: {
            carouselAssets: data.map((image) => ({ image })),
          },
        },
      })
    },
    fireAnalyticsEvent(type) {
      switch (type) {
        case 'upload':
          this.$analytics.track('Hero Carousel Image Added')
          break
        case 'remove':
          this.$analytics.track('Hero Carousel Image Removed')
          break
        case 'reorder':
          this.$analytics.track('Hero Carousel Images Reordered')
          break
        default:
          logWarn(`Hero Carousel - Unsupported analytics event: ${type}`)
      }
    },
    onChange({ type, data }) {
      this.applyHeroCarouselUpdate(data)
      this.fireAnalyticsEvent(type)
    },
  },
}
</script>
