<i18n locale="en">
{
  "labels": {
    "form": "Form",
    "placeholder": "Select..."
  }
}
</i18n>

<template>
  <div class="flex-col gap-8">
    <OwnInputContainer :label="t('labels.form')">
      <OwnDropdown
        v-model="formId.value"
        :disabled="loading"
        :options="options"
        :placeholder-item="placeholder"
      />
    </OwnInputContainer>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { mapGetters } from 'vuex'

import { ConfiguredClient } from '@/api'
import { FormBuilder, Validators } from '@/forms'
import { OwnInputContainer, OwnDropdown } from '@/ui'

import { FormCommands } from '../commands/FormCommands'

export default {
  name: 'FormEditor',
  components: {
    OwnDropdown,
    OwnInputContainer,
  },
  setup() {
    const { t } = useI18n()

    return { t }
  },
  mixins: [
    FormBuilder({
      formId: {
        debounce: 250,
        validators: [Validators.required],
      },
    }),
  ],
  props: {
    /**
     * Id getting passed to the editor component
     */
    id: { type: String, required: true },
  },
  data() {
    return {
      forms: [],
      loading: true,
      placeholder: {
        title: this.t('labels.placeholder'),
        value: null,
      },
    }
  },
  computed: {
    ...mapGetters({
      blockById: 'websiteBuilder/blockById',
    }),
    brandId() {
      const { currentBlock } = this

      return currentBlock?.brandId
    },
    currentBlock() {
      const { id } = this

      return this.blockById(id)
    },
    options() {
      const { forms } = this

      return forms.map((form) => ({
        ...form,
        label: form.title,
        value: form.id,
      }))
    },
  },
  async created() {
    const { brandId, currentBlock } = this

    this.setInitialFormValues({ formId: currentBlock?.params?.formId })

    this.forms = (
      await ConfiguredClient.dashboard.v1.brands.brand.forms.getForms({
        params: {
          brandId,
        },
        query: {
          limit: 25,
        },
      })
    ).results

    this.loading = false
  },
  methods: {
    async onFormControlUpdate(controlName, update) {
      const { id, isFormValid } = this

      if (isFormValid) {
        await FormCommands.updateBlock({
          targetId: id,
          update: { params: { [controlName]: update } },
        })
      }
    },
  },
}
</script>
