import { computed, type ComputedRef, ref, type Ref } from 'vue'

import { ConfiguredClient } from '@/api'

import { type Brand } from '../../types'

interface BrandCache {
  selectedBrands: ComputedRef<Brand[]>
  addToCache: (brands: Brand[]) => void
  loadBrands: (brandIds: string[]) => Promise<void>
}

export const useBrandCache = (selectedBrandIds: Ref<string[]>): BrandCache => {
  const loadedBrands = ref<Brand[]>([])

  const cachedBrands = computed(() => {
    return loadedBrands.value
  })

  const selectedBrands = computed(() => {
    return (
      selectedBrandIds.value
        // look up values in cached data
        .map((id) => cachedBrands.value.find((brand) => brand.id === id))
        // filter out the ones we didn't find / haven't loaded yet
        .filter((value) => value) as Brand[]
    )
  })

  const addToCache = (brands: Brand[]): void => {
    for (const brand of brands) {
      loadedBrands.value.push(brand)
    }
  }

  const loadBrands = async (brandIds: string[]): Promise<void> => {
    const externalRequests = []

    for (const brandId of brandIds) {
      if (!cachedBrands.value.find((brand) => brand.id === brandId)) {
        externalRequests.push(
          ConfiguredClient.brands.brand.getBrand({
            params: {
              brandId,
            },
          })
        )
      }
    }

    loadedBrands.value = await Promise.all(externalRequests)
  }

  return {
    addToCache,
    loadBrands,
    selectedBrands,
  }
}
