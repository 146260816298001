import range from 'lodash/range'

import { type PaginatedRequestData, type PaginatedRequestParams } from '@/core'

export interface PaginationOption {
  active: boolean
  label: string
  value: number
}

const createOption = (active: boolean, value: number): PaginationOption => ({
  active,
  label: value.toString(),
  value,
})

export const getPaginationLayout = (
  requestParams: PaginatedRequestParams,
  requestData: PaginatedRequestData
): PaginationOption[][] => {
  if (!requestData.pageCount) return []

  const activePage = requestParams.page

  if (requestData.pageCount < 7) {
    return [
      range(1, requestData.pageCount + 1).map((page) =>
        createOption(page === activePage, page)
      ),
    ]
  }

  if (activePage < 5) {
    // Active page in the first 4
    return [
      [...range(1, 6).map((page) => createOption(page === activePage, page))],
      [createOption(false, requestData.pageCount)],
    ]
  } else if (activePage > requestData.pageCount - 4) {
    // Active page in the last 4
    return [
      [createOption(false, 1)],
      [
        ...range(requestData.pageCount - 4, requestData.pageCount + 1).map(
          (page) => createOption(page === activePage, page)
        ),
      ],
    ]
  } else {
    // Active page in the middle
    return [
      [createOption(false, 1)],
      [
        ...range(activePage - 1, activePage + 2).map((page) =>
          createOption(page === activePage, page)
        ),
      ],
      [createOption(false, requestData.pageCount)],
    ]
  }
}
