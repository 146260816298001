import {
  format,
  parse,
  differenceInMinutes,
  areIntervalsOverlapping,
} from 'date-fns'

export const defaultHourSet = () => ({ end: '9:00 PM', start: '9:00 AM' })

export const minuteDiff = (a, b) => {
  const times = [a, b].map((time) => parse(time, 'h:mm a', new Date()))
  return differenceInMinutes(...times)
}

export const segmentsOverlap = (segments) => {
  if (segments.length <= 1) return false

  const inputFormat = 'h:mm a'

  const parsedSegments = segments.map((segment) => ({
    end: parse(segment.end, inputFormat, new Date()),
    start: parse(segment.start, inputFormat, new Date()),
  }))

  for (let i = 0; i < parsedSegments.length; i++) {
    for (let j = i + 1; j < parsedSegments.length; j++) {
      if (areIntervalsOverlapping(parsedSegments[i], parsedSegments[j])) {
        return true
      }
    }
  }

  return false
}

export const dayKeys = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
]

const formatTimePickerOption = (hour, minute, meridian) => {
  const time = `${hour}:${minute} ${meridian}`

  return {
    filterKey: `${hour}${minute}${meridian}`,
    fullFilterKey: time,
    label: time,
    short: minute === '00' ? `${hour}${meridian}` : '',
    value: time,
  }
}

export const timePickerOptions = Array.from({ length: 96 }, (_, totalIndex) => {
  const index = totalIndex >= 48 ? totalIndex - 48 : totalIndex

  const hour = index <= 3 ? '12' : `${Math.floor(index / 4)}`
  const minute = ['00', '15', '30', '45'][index % 4]
  const meridian = totalIndex >= 48 ? 'PM' : 'AM'

  return formatTimePickerOption(hour, minute, meridian)
})

/**
 * @param {string} [locale] The current locale for the browser
 */
export const getLocalizedTimezone = (locale) => {
  const shortName = Intl.DateTimeFormat(locale, { timeZoneName: 'short' })
    .formatToParts()
    .find(({ type }) => type === 'timeZoneName').value

  const longName = Intl.DateTimeFormat(locale, { timeZoneName: 'long' })
    .formatToParts()
    .find(({ type }) => type === 'timeZoneName').value

  return `${longName} (${shortName})`
}

export const getTimePickerOptions = ({ sameDay, nowTitle } = {}) => {
  const options = []

  if (nowTitle) {
    const [hour, minute, meridian] = ['h', 'mm', 'a'].map((pattern) =>
      format(new Date(), pattern)
    )
    options.push({
      ...formatTimePickerOption(hour, minute, meridian),
      label: nowTitle,
    })
  }

  const defaultOptions = sameDay
    ? timePickerOptions.filter(
        ({ value }) => parse(value, 'h:mm a', new Date()) > new Date()
      )
    : timePickerOptions

  options.push(...defaultOptions)

  return options
}
