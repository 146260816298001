const convertViewToSort = (view) => {
  switch (view) {
    case 'top':
      return ['totalSpend:desc']

    case 'rewards':
      return ['usedPoints:desc']

    default:
      return ['createdAt:desc']
  }
}

export default {
  decrementPage: ({ state, commit }) => {
    commit('UPDATE_PAGE', state.query.page - 1)
  },
  incrementPage: ({ state, commit }) => {
    commit('UPDATE_PAGE', state.query.page + 1)
  },
  resetState: ({ commit }) => {
    commit('RESET_STATE')
  },
  setActiveAction: ({ commit }, action) => {
    commit('SET_ACTION', action)
  },
  setCurrentView: ({ commit }, newView) => {
    commit('SET_VIEW', newView)
    commit('SET_QUERY_SORT', convertViewToSort(newView))
  },
  setFilters: ({ dispatch }, filters) => {
    for (const key of Object.keys(filters)) {
      if (key === 'dayRange') {
        const filter = filters[key]

        if (filter.enabled) {
          dispatch('setQueryDate', filter.data)
        } else {
          dispatch('setQueryDate', { end: null, start: null })
        }
      }
    }
  },
  setQueryDate: ({ commit }, { start, end }) => {
    if (start) {
      commit('SET_QUERY_START', start)
    } else {
      commit('UNSET_QUERY_START')
    }
    if (end) {
      commit('SET_QUERY_END', end)
    } else {
      commit('UNSET_QUERY_END')
    }
  },
  setQuerySearch: ({ commit }, searchTerm) => {
    if (!searchTerm || searchTerm === '') {
      commit('UNSET_QUERY_SEARCH')
    } else {
      commit('SET_QUERY_SEARCH', searchTerm)
    }
  },
}
