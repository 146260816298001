import store from '@/store'

const getHomeRoute = () => store.getters['session/home']

/**
 * Returns a redirect route object
 * @param {object} to The original route object
 * @param {object} to.params The original route params
 * @param {string|object} [redirect] Name of the redirection route
 * @param {object} [query] the query params to append
 * @returns {object} a route object
 */
const getRedirect = ({ params }, redirect, query = null) => {
  redirect = redirect
    ? typeof redirect === 'string'
      ? { name: redirect }
      : redirect
    : getHomeRoute()
  return {
    params,
    query,
    ...redirect,
  }
}

export { getRedirect }
