<i18n locale="en">
  {
    "actions": {
      "immediate": "Send Now",
      "scheduled": "Schedule"
    },
    "errors": {
      "customer-count": "There was a problem on our end and we can’t send this right now"
    },
    "locations": {
      "all": "across all locations",
      "specific": "from {location}"
    },
    "messages": {
      "loading": "Calculating number of customers for campaign...",
      "immediate": "No customers are targeted for this campaign | You’re about to send this to 1 customer {location} | You’re about to send this to {count} of your customers {location}",
      "scheduled": "No customers are targeted for this campaign | You’re about to schedule this for 1 customer {location} | You’re about to schedule this for {count} of your customers {location}"
    }
  }
  </i18n>

<template>
  <div class="flex-row gap-3 align-center justify-end">
    <OwnType :text="customerMessage" align="right" />
    <OwnButton
      class="text--no-wrap"
      :disabled="customerCount === 0 || isLoadingCustomerCount || disabled"
      :text="t(`actions.${type}`)"
      :processing="saving"
      primary
      @click="schedule"
    />
  </div>
</template>

<script>
import { isFuture } from 'date-fns'
import { useI18n } from 'vue-i18n'
import { mapGetters } from 'vuex'

import { ConfiguredClient } from '@/api'
import { logError } from '@/logger'
import notify from '@/mixins/notify'
import { OwnButton, OwnType } from '@/ui'

export default {
  name: 'ScheduleOTCFooter',
  components: { OwnButton, OwnType },
  mixins: [notify],
  props: {
    /** The current campaign */
    campaign: { type: Object, required: true },

    /** Current scheduled date for the campaign */
    date: { type: Date, required: true },

    /** Disables scheduling */
    disabled: { type: Boolean, required: true },

    /** Current loading state */
    saving: { type: Boolean, default: false },
  },
  emits: ['schedule'],
  data() {
    return {
      customerCount: 0,
      isLoadingCustomerCount: false,
      isScheduling: false,
    }
  },
  setup() {
    const { t } = useI18n()

    return { t }
  },
  computed: {
    ...mapGetters({ locations: 'core/brand/active/locations' }),
    customerMessage() {
      const {
        campaign: { locationId },
        customerCount: count,
        isLoadingCustomerCount,
        locations,
        type,
      } = this

      if (isLoadingCustomerCount) return this.t('messages.loading')

      const locationName = (
        locations.find(({ id }) => id === locationId) ?? locations[0]
      ).name

      const location = this.t(`locations.${locationId ? 'specific' : 'all'}`, {
        location: locationName,
      })

      return this.t(`messages.${type}`, count, {
        count: count.toLocaleString(),
        location,
      })
    },
    type() {
      return isFuture(this.date) ? 'scheduled' : 'immediate'
    },
  },
  mounted() {
    this.loadCustomerCount()
  },
  methods: {
    async loadCustomerCount() {
      const { campaign } = this

      try {
        this.isLoadingCustomerCount = true

        const { eligible } =
          await ConfiguredClient.marketing.v1.campaigns.campaign.getEligibleParticipants(
            { params: { campaignId: campaign.id } }
          )

        this.customerCount = eligible
      } catch (error) {
        logError(error)
        this.$notify(this.t('errors.customer-count'), 'error')
      } finally {
        this.isLoadingCustomerCount = false
      }
    },
    async schedule() {
      this.$emit('schedule')
    },
  },
}
</script>
