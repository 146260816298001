import { checkoutSettingsApi } from '@/api'

export default {
  async loadCheckoutSettings({ commit, dispatch, rootGetters }) {
    const locationIds = rootGetters['core/locations/ids']

    await dispatch('sanitizeSettings')

    // maps locationIds to loaded checkout settings
    const checkoutSettings = await Promise.all(
      locationIds.map(async (locationId) => {
        try {
          return [
            locationId,
            await checkoutSettingsApi.getCheckoutSettings(locationId),
          ]
        } catch (err) {
          return null
        }
      })
    )

    const settings = Object.fromEntries(
      checkoutSettings.filter((settingsPair) => !!settingsPair)
    )

    commit('ADD_SETTINGS', { settings })
  },
  sanitizeSettings({ commit }) {
    commit('SANITIZE_SETTINGS')
  },
  async updateSettings({ commit }, { locationId, settingsUpdate }) {
    const updatedSettings = await checkoutSettingsApi.updateCheckoutSettings(
      locationId,
      settingsUpdate
    )

    commit('UPDATE_SETTINGS', {
      locationId,
      updatedSettings,
    })
  },
}
