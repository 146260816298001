import client from '../client'

/**
 * Get invoice by location Id
 * @param {object} query See below
 * @param {string} query.brandId brandId to query
 */
export const getInvoice = ({ brandId }) =>
  client.get('/billing/invoices', { brandId })

/**
 * Get Invoice by id
 * @param {object} query see below
 * @param {string} query.brandId brandId for query
 * @param {string} query.invoiceId invoiceId to query
 */
export const getInvoiceById = ({ brandId, invoiceId }) =>
  client.get(`/billing/invoices/${invoiceId}`, { brandId })

/**
 *
 *Retry Payment
 * @param {object} query See below
 * @param {string} query.invoiceId Invoice Id
 * @param {string} query.locationId Location Id
 */
export const retryInvoicePayment = async ({ invoiceId, locationId }) =>
  client.post(`/locations/${locationId}/invoices/${invoiceId}/retry-payment`)
