import merge from 'lodash/merge'

import { useBuilder } from '@/lib/builder'

import { type FormSection } from '../FormBlockModule.types'

const websiteBuilderQueue = useBuilder('websiteBuilder')

const FormCommands = {
  updateBlock: websiteBuilderQueue.createBlockCommand<FormSection>({
    commit: async (currentData, ctx) => {
      return merge(currentData, ctx.update)
    },
  }),
}

export { FormCommands }
