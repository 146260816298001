import { createStore } from 'vuex'

import modules from './modules'
import plugins from './plugins'

export default createStore({
  // @ts-expect-error Importing JS file
  modules,
  plugins,
  strict: false,
})

export * from './utils/useMappedGetter'
export * from './utils/useMappedAction'
