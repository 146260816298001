import { PbError } from '@owner/errors'

import { authApi, brandsApi, locationsApi, ConfiguredClient } from '@/api'
import analytics from '@/plugins/analytics/analytics'
import { FeatureFlags } from '@/plugins/featureFlags'
import { router } from '@/router/router'
import { checkRole } from '@/utils/acl'

export default {
  login: async ({ dispatch }, { email, password }) => {
    const { session, user } = await authApi.login({ email, password })

    await dispatch('setSession', { session, user })

    await dispatch('resumeSession')
  },

  logout: async ({ commit }) => {
    commit('RESET_STATE')

    commit(`core/RESET_STATE`, null, { root: true })

    analytics.track('Logout')

    analytics.reset()

    await authApi.logout().catch(() => undefined)

    await router.push({
      name: 'auth.login',
    })
  },

  multiFactorAuthLogin: async ({ dispatch }, { session, user }) => {
    await dispatch('setSession', { session, user })

    await dispatch('resumeSession')
  },

  registerUser: async ({ dispatch }, { formData }) => {
    const { session, user } = await authApi.register(formData)

    await dispatch('setSession', { session, user })

    return user
  },

  resetPassword: async ({ dispatch }, { password, token }) => {
    const { session, user } = await authApi.resetPassword(password, token)

    await dispatch('setSession', { session, user })

    await dispatch('resumeSession')
  },

  resumeSession: async ({ getters }) => {
    const sanitizePath = (path) => {
      const { name, params, query } = router.resolve(path)

      return name !== 'not-found' && name !== ''
        ? { name, params, query }
        : getters['home']
    }

    const redirectFrom = router.currentRoute.value.query.redirectFrom

    if (!redirectFrom) {
      return router.push(getters.home)
    } else {
      return router.push(sanitizePath(redirectFrom))
    }
  },

  setSession: async ({ dispatch, commit, getters }, { session, user }) => {
    commit('SET_SESSION', { session, user })

    FeatureFlags.setContextField('userId', user.email)

    // If user is super-level, set home to brand overview
    if (checkRole(getters.role, 'pb:super')) {
      commit('SET_HOME', { name: 'brands' })
    } else {
      const userBrandIds = user.brands

      // If user is brand-level, pull their brand and set it before navigating away
      const { results: brands } = await brandsApi.getBrands({
        limit: 100,
      })

      const { results: locations } = await locationsApi.getLocations({
        brandId: userBrandIds,
        limit: 100,
      })

      if (!brands.length) {
        dispatch('logout')
        throw new PbError('User not associated with a brand')
      }

      const defaultBrand = brands[0]

      await dispatch('core/brands/setAll', brands, { root: true })
      await dispatch('core/locations/setAll', locations, { root: true })

      commit('SET_HOME', {
        name: 'brand.home',
        params: { brandId: defaultBrand.id },
      })
    }

    analytics.identify(user.id)

    analytics.setUserData(
      {
        brands: user.brands,
        createdAt: user.createdAt,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        locations: user.locations,
        name: `${user.firstName} ${user.lastName}`,
        role: user.role,
      },
      true
    )

    analytics.track('Login')
  },

  /**
   * Update User Notifications
   * @param {object} Vuex ignore
   * @param {Function} Vuex.commit ignore
   * @param {object} Vuex.getters ignore
   * @param {object} notifications Update for user
   */
  updateUserNotifications: async ({ commit, getters }, notifications) => {
    const { id, notifications: currentNotifications } = getters.user

    const updatedNotifications =
      await ConfiguredClient.users.user.notifications.updateNotifications({
        body: {
          ...currentNotifications,
          ...notifications,
        },
        params: {
          userId: id,
        },
      })

    commit('SET_NOTIFICATIONS', updatedNotifications)
  },
}
