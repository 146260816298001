import mergeWith from 'lodash/mergeWith'
import { type PartialDeep } from 'type-fest'
import { type ActionContext } from 'vuex'

import {
  type BlockCore,
  type BuilderState,
  type ResourceCore,
  useBuilder,
} from '@/lib/builder'
import { type CoreModule } from '@/lib/store'
import { logError } from '@/logger'
import { removeObserver } from '@/utils/helpers'

export const resourceSectionDelete = async <
  TResource extends ResourceCore,
  TBlock extends BlockCore,
>(
  {
    state,
    commit,
    dispatch,
  }: ActionContext<BuilderState<TResource, TBlock>, CoreModule>,
  deleteId: string
): Promise<void> => {
  const currentResource = state._builder_currentState?.resource

  if (!currentResource) {
    logError('Unable to add section to undefined resource')
  }

  const moduleName = state.moduleName

  const builderModule = useBuilder(moduleName)

  const createResourceCommand = builderModule.createResourceCommand<TResource>({
    commit: async (currentData, ctx) => {
      return mergeWith(currentData, ctx.update, (_objValue, srcValue, key) => {
        // Overwrite `sections` (array op) rather than merging
        if (key === 'sections') {
          return srcValue
        }
      })
    },
  })

  const sanitizedResource = removeObserver(currentResource) as TResource

  await createResourceCommand({
    update: {
      sections: sanitizedResource.sections.filter(
        (section) => section !== deleteId
      ),
    },
  } as { update: PartialDeep<TResource> })
}
