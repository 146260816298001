export const timeEn = {
  availability: {
    'all-day': 'Available all day',
    'not-available': 'Not available',
  },
  day: {
    friday: 'Friday',
    monday: 'Monday',
    saturday: 'Saturday',
    sunday: 'Sunday',
    thursday: 'Thursday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
  },
}
