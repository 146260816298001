import { type RouteMeta, type RouteRecordRaw } from 'vue-router'

const combineMeta = (
  meta: RouteMeta,
  { acl, authenticated, roles, scopes }: RouteMeta
): RouteMeta => {
  if (acl) meta.acl = Object.assign(acl, meta.acl || {})
  if (authenticated !== undefined) meta.authenticated = authenticated
  meta.roles = meta.roles ?? roles
  meta.scopes = meta.scopes ?? scopes
  return meta
}

/**
 * Applies permissions to a list of children
 * @param children A list of child routes
 * @param meta Meta properties to apply to children
 */
export const applyMetaToChildren = (
  children: RouteRecordRaw[],
  meta: RouteMeta
): RouteRecordRaw[] =>
  children.map((child) => {
    if (child.children)
      child.children = applyMetaToChildren(child.children, meta)
    child.meta = combineMeta(child.meta ?? {}, meta)
    return child
  })
