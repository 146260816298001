<template>
  <div class="preview-checkbox" :style="style"></div>
</template>

<script>
import { useMergedTheme } from './mixins/useMergedTheme'

export default {
  name: 'PreviewCheckbox',
  mixins: [useMergedTheme],
  computed: {
    style() {
      const { mergedTheme } = this

      return {
        borderColor: mergedTheme.colors.background.divider,
        borderRadius: `${mergedTheme.borderRadius.control}rem`,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.preview-checkbox {
  border-style: solid;
  border-width: 1px;
  height: 24px;
  width: 24px;
  padding: 2px;
  margin-top: 4px;
  margin-bottom: 4px;
  flex-shrink: 0;
}
</style>
