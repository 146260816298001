<template>
  <div
    class="left-flyout-layout h-full w-full"
    :class="[isOpen && 'left-flyout-layout--open']"
    :style="{
      '--left-flyout-width': `${flyoutWidth}px`,
      '--left-flyout-width-negative': `-${flyoutWidth}px`,
    }"
  >
    <div
      class="left-flyout-layout__flyout"
      :class="[isOpen && 'left-flyout-layout__flyout--open']"
    >
      <div v-if="isOpen" class="h-full">
        <slot name="flyout-content" />
      </div>
    </div>

    <div
      class="left-flyout-layout__main h-full"
      :class="[isOpen && 'left-flyout-layout__main--open']"
    >
      <slot name="default" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    flyoutWidth?: number | string
    isOpen?: boolean
  }>(),
  {
    flyoutWidth: 400,
    isOpen: false,
  }
)

const flyoutWidth = computed(() => {
  return `${props.flyoutWidth}px`
})
</script>

<style lang="scss" scoped>
.left-flyout-layout {
  $transition:
    width 0.1s ease-in-out,
    left 0.1s ease-in-out;

  position: relative;

  &__flyout {
    transition: $transition;
    position: absolute;
    bottom: 0;
    width: 100%;
    flex-shrink: 0;
    z-index: 125;
    left: -100%;
    top: 0;

    @include respond-to('md-and-up') {
      width: v-bind(flyoutWidth);
      left: calc(v-bind(flyoutWidth) * -1);
    }

    &--open {
      left: 0;
    }
  }

  &__main {
    transition: $transition;
    width: 100%;
    flex-shrink: 0;
    z-index: 120;
    position: absolute;
    right: 0;
    top: 0;

    &--open {
      width: 0;

      @include respond-to('md-and-up') {
        width: calc(100% - v-bind(flyoutWidth));
      }
    }
  }
}
</style>
