import { type CoreState } from './types'

const coreState: CoreState = {
  activeBrand: null,
  activeLocation: null,
  brands: [],
  locations: [],
  theme: undefined,
}

export { coreState }
