<template>
  <div class="preview-sheet" :style="sheetStyles" :class="sheetClasses">
    <slot />
  </div>
</template>

<script>
import get from 'lodash/get'

import { hexToRgba } from '@/utils/color'

import { useMergedTheme } from './mixins/useMergedTheme'

const BACKGROUND_COLORS = {
  divider: 'background.divider',
  primary: 'background.primary',
  secondary: 'background.secondary',
}

export default {
  name: 'PreviewSheet',
  mixins: [useMergedTheme],
  props: {
    /**
     * Background color
     */
    background: {
      type: String,
      default: 'primary',
      validator: (v) =>
        [
          'primary',
          'primary80',
          'secondary',
          'divider',
          'opacity',
          'transparent',
          'translucent',
        ].includes(v),
    },

    /**
     * border
     */
    border: {
      type: String,
      default: 'none',
      validator: (v) =>
        ['primary', 'secondary', 'divider', 'transparent', 'none'].includes(v),
    },

    /** Renders the sheet as a placeholder for not-configured data */
    placeholder: { type: Boolean, default: false },

    /**
     * Rounded Value
     */
    radius: {
      type: String,
      default: 'small',
      validator: (v) =>
        ['control', 'small', 'medium', 'large', '0'].includes(v),
    },

    /**
     * Drop shadow
     */
    shadow: {
      type: String,
      default: 'none',
      validator: (v) => ['none', 'short', 'long'].includes(v),
    },
  },
  computed: {
    borderConfig() {
      const { border, computedBorderColor, placeholder } = this

      if (placeholder) {
        return {
          borderColor: computedBorderColor,
          borderStyle: 'dashed',
          borderWidth: '1px',
        }
      } else if (border === 'none' || !computedBorderColor) {
        return {}
      } else {
        return {
          borderColor: computedBorderColor,
          borderStyle: 'solid',
          borderWidth: '1px',
        }
      }
    },
    computedBackgroundColor() {
      const { background, mergedTheme } = this

      if (background === 'transparent') return 'transparent'
      if (background === 'opacity') return 'rgba(0,0,0,0.03)'
      if (background === 'primary80')
        return `${get(mergedTheme.colors, BACKGROUND_COLORS.primary)}CD`

      if (background === 'translucent') {
        return hexToRgba(mergedTheme?.colors.background.primary, 0.8)
      }

      return get(mergedTheme.colors, BACKGROUND_COLORS[background])
    },
    computedBlur() {
      const { background } = this

      if (background === 'translucent') {
        return {
          backdropFilter: 'blur(40px)',
        }
      }

      return {}
    },
    computedBorderColor() {
      const { border, mergedTheme, placeholder, background } = this

      if (background === 'translucent') return 'rgb(229 229 229 / 0.25)'

      if (placeholder) return get(mergedTheme.colors, 'background.divider')

      if (border === 'none') return undefined

      if (border === 'transparent') return 'transparent'

      return get(mergedTheme.colors, BACKGROUND_COLORS[border])
    },
    computedRadius() {
      const { radius, mergedTheme } = this

      switch (radius) {
        case '0':
          return 0
        case 'control':
          return `${mergedTheme.borderRadius.control}rem`
        case 'small':
          return `${mergedTheme.borderRadius.small}rem`
        case 'medium':
          return `${mergedTheme.borderRadius.medium}rem`
        case 'large':
          return `${mergedTheme.borderRadius.large}rem`
        default:
          return `${mergedTheme.borderRadius.small}rem`
      }
    },

    sheetClasses() {
      const { shadow } = this

      const shadowClass = `preview-sheet--shadow-${shadow}`

      return [shadowClass]
    },
    sheetStyles() {
      const {
        borderConfig,
        computedRadius,
        computedBackgroundColor,
        computedBlur,
      } = this

      return {
        ...borderConfig,
        backgroundColor: computedBackgroundColor,
        borderRadius: computedRadius,
        ...computedBlur,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.preview-sheet {
  &--shadow-short {
    box-shadow: 0 4px 24px 0 hsla(0, 0%, 5%, 0.05);
  }

  &--shadow-long {
    box-shadow: 0 14px 42px 0 hsla(0, 0%, 5%, 0.05);
  }
}
</style>
