import type { ActionContext } from 'vuex'

import { ConfiguredClient } from '@/api'
import type { Location } from '@/core'
import type { CoreState } from '@/core/store/types'

export interface UpdateProcessingParams {
  locationId: string
  update: Location['processing']
}

export const updateLocationProcessing = async (
  { commit }: ActionContext<CoreState, unknown>,
  { locationId, update }: UpdateProcessingParams
): Promise<void> => {
  const location = await ConfiguredClient.locations.location.updateLocation({
    body: {
      processing: update,
    },
    params: {
      locationId,
    },
  })

  commit('SET_LOCATION', location)
}
