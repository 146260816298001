<template>
  <div class="flex-row">
    <SearchInput v-model="searchQuery" />
  </div>
</template>

<script>
import { SearchInput } from '@/components/inputs'
import { mapModel } from '@/utils/computed'

export default {
  name: 'TableHeader',
  components: {
    SearchInput,
  },
  props: {
    modelValue: { type: String, default: undefined },
  },
  emits: ['update:modelValue'],
  computed: {
    ...mapModel('searchQuery'),
  },
}
</script>
