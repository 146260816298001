/**
 * Convert a file to a base64 data URL.
 * @param file A file, for example from `<input type="file">.
 */
export async function toDataUrl(file: File): Promise<string> {
  return await new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      const result = reader.result?.toString()

      if (!result) {
        return reject(
          new Error(
            'Failed to convert file to data URL (reader returned nothing)'
          )
        )
      }

      return resolve(result)
    }
    reader.onerror = reject
  })
}
