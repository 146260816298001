<i18n locale="en">
{
  "delete": "Delete"
}
</i18n>

<template>
  <div class="flex-col gap-8">
    <slot />

    <OwnButton
      v-if="$attrs.onDelete"
      :text="deleteLabel ?? t('delete')"
      danger
      @click="$emit('delete')"
    >
      <template #icon-right>
        <PhTrash size="24" />
      </template>
    </OwnButton>
  </div>
</template>

<script lang="ts" setup>
import { PhTrash } from '@phosphor-icons/vue'
import { useI18n } from 'vue-i18n'

import { OwnButton } from '@/ui'

withDefaults(
  defineProps<{
    deleteLabel?: string | null
  }>(),
  {
    deleteLabel: null,
  }
)

defineEmits<{ delete: [] }>()

const { t } = useI18n()
</script>
