import mergeWith from 'lodash/mergeWith'

import { useBuilder } from '@/lib/builder'

import { type LandingHeroSection } from '../../../WebsiteBuilder.types'

const websiteBuilderQueue = useBuilder('websiteBuilder')

const LandingHeroCommands = {
  updateBlock: websiteBuilderQueue.createBlockCommand<LandingHeroSection>({
    commit: async (currentData, ctx) => {
      return mergeWith(currentData, ctx.update, (_objValue, srcValue, key) => {
        // Do not merge `carouselAssets` because it is an array
        if (key === 'carouselAssets') {
          return srcValue
        }
      })
    },
  }),
}

export { LandingHeroCommands }
