<template>
  <PhCircleNotch class="own-progress-spinner" :class="color" :size="size" />
</template>

<script>
import { PhCircleNotch } from '@phosphor-icons/vue'

export default {
  name: 'OwnProgressSpinner',
  components: {
    PhCircleNotch,
  },
  props: {
    /** A color class */
    color: { type: String, default: 'text-color-secondary' },

    /** Size of the spinner in pixels */
    size: { type: [Number, String], default: 40 },
  },
}
</script>

<style lang="scss">
.own-progress-spinner {
  animation: spin 1s linear infinite;

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
