<template>
  <PreviewSheet>
    <div class="feature-card flex-col gap-4 align-center">
      <div
        class="feature-card__icon-wrapper flex-col align-center justify-center"
      >
        <Component :is="icon" size="24" :style="iconStyle" />
      </div>
      <PreviewTypography
        :text="feature.title"
        variant="paragraphSmall"
        color="primary"
      />
      <PreviewTypography
        v-if="feature.description"
        :text="feature.description"
        variant="paragraphSmall"
        color="secondary"
      />
    </div>
  </PreviewSheet>
</template>

<script lang="ts" setup>
import { type Component, computed } from 'vue'

import {
  PreviewSheet,
  PreviewTypography,
  useMergedThemeComp,
} from '@/lib/builder'

import { featureIcons as icons } from '../../../common/feature-icons'
import { type FeaturesSection } from '../../../WebsiteBuilder.types'

const props = defineProps<{
  feature: FeaturesSection['params']['features'][number]
}>()

const theme = useMergedThemeComp()

const iconStyle = computed(() => {
  return {
    color: theme.value?.colors.text.secondary,
  }
})

const icon = computed(() => {
  return icons[props.feature.icon] as Component
})
</script>

<style lang="scss" scoped>
.feature-card {
  padding: 12px 0;
  flex: 0 0 auto;
  text-align: center;
  width: 220px;

  &__icon-wrapper {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }
}
</style>
