import client from '../client'

/**
 * Update the brand object
 * @param {string} brandId Id of brand being updated
 * @param {object} updatedSettings - updated brand settings
 * @returns {Promise} Resolves an updated brand object
 */
export const updateBrand = (brandId, updatedSettings) =>
  client.patch(`/brands/${brandId}`, updatedSettings)

/**
 * Fetches a brand object
 * @param {string} brandId ID of the brand you're fetching
 */
export const getBrand = (brandId) => client.get(`brands/${brandId}`)

/**
 * Fetches a paginated list of brands
 * @param {object} query A Brand-filtering query
 */
export const getBrands = (query) => client.get(`brands`, query)

/**
 * Updates brand links
 * @param {string} brandId Id of brand to change
 * @param {object} links Content to send to the server
 * @param {boolean} links.olo external OLO link
 * @param {boolean} links.reservations external reservations link
 */
export const updateLinks = (brandId, links) =>
  client.put(`brands/${brandId}/links`, links)

/**
 * Updates Brand Assets
 * @param {string} brandId Id of brand to change
 * @param {object} assets Assets Object
 * @param {string|null} assets.logo Logo
 * @param {string|null} assets.bannerImage Banner Image
 */
export const updateBrandAssets = (brandId, assets) =>
  client.patch(`brands/${brandId}/`, { assets })

/**
 * Gets a brand theme
 * Note - this is for the unmerged brand theme on Hermes only
 * @param {string} brandId Owner brand id
 */
export const getBrandTheme = (brandId) =>
  client.get(`brands/${brandId}/theme/brand`)

/**
 * Updates selected brightness palette for a brand
 * @param {string} brandId Owner brand id
 * @param {string} palette Selected palette `light | dark`
 */
export const updateBrightnessPalette = (brandId, palette) =>
  client.patch(`brands/${brandId}/theme/overrides/palette`, { palette })

/**
 * Updates brand theme overrides
 * @param {string} brandId Owner brand id
 * @param {object} overrides Brand theme overrides
 */
export const updateThemeOverrides = (brandId, overrides) =>
  client.patch(`brands/${brandId}/theme/overrides`, overrides)
