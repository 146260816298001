export const resourceTypes = [
  'menu',
  'category',
  'item',
  'modifier-set',
  'modifier',
  'coupon',
] as const

export const resourceActions = ['clone', 'remove'] as const
export const resourceActionsSingle = ['remove'] as const
