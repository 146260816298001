<i18n locale="en">
{
  "title": "Uh oh..."
}
</i18n>

<template>
  <OwnDialogHeading :title="t('title')">
    <template #icon-left>
      <PhWarning size="32" class="status-warning" />
    </template>
  </OwnDialogHeading>
</template>

<script lang="ts" setup>
import { PhWarning } from '@phosphor-icons/vue'
import { useI18n } from 'vue-i18n'

import { OwnDialogHeading } from '@/ui'

const { t } = useI18n()
</script>
