import { type RouteRecordRaw } from 'vue-router'

export const devicesRouter: RouteRecordRaw[] = [
  {
    component: async () => import('../views/DevicesView.vue'),
    meta: {
      scopes: ['devices:read'],
      title: 'restaurant.devices',
    },
    name: `brand.devices`,
    path: 'devices',
  },
]
