<template>
  <PreviewSheet
    class="content-preview"
    :class="[
      layout === 'generic' && 'content-preview--generic',
      layout === 'cover' && 'content-preview--cover',
    ]"
    border="primary"
    radius="0"
  >
    <PreviewSheet
      class="content-preview__content-sheet"
      :radius="layout === 'cover' ? undefined : '0'"
    >
      <div
        :class="[
          'content-preview__layout',
          layout === 'generic' && 'content-preview__layout--generic',
          layout === 'cover' && 'content-preview__layout--cover',
          currentBlockParams.alignment !== 'right'
            ? 'flex-row'
            : 'flex-row-reverse',
          `justify-${justify}`,
          `align-${layout === 'generic' ? 'center' : 'start'}`,
        ]"
      >
        <div
          v-if="image && layout === 'cover'"
          class="content-preview__cover-background"
          :style="{
            backgroundImage: `url(${image})`,
          }"
        ></div>
        <PreviewSheet
          v-if="
            layout === 'generic' && currentBlockParams.media?.type === 'image'
          "
          class="content-preview__image"
          :style="{
            backgroundImage: `url(${currentBlockParams.media.params.image})`,
          }"
        />
        <div
          v-if="
            layout === 'generic' && currentBlockParams.media?.type === 'video'
          "
          class="content-preview__video-wrapper"
        >
          <iframe
            class="content-preview__video"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            :src="`https://www.youtube.com/embed/${currentBlockParams.media.params.params.id}`"
          ></iframe>
        </div>
        <PreviewSheet
          :class="[layout === 'cover' && 'content-preview__cover-stack-sheet']"
          :background="layout === 'cover' ? 'primary80' : undefined"
        >
          <div
            :class="[
              'content-preview__typography-wrapper',
              layout === 'cover' &&
                'content-preview__typography-wrapper--cover',
              layout === 'generic' &&
                'content-preview__typography-wrapper--generic',
              'flex-col gap-8 justify-center',
            ]"
          >
            <PreviewTypography
              v-if="currentBlockParams.header"
              :text="currentBlockParams.header"
              variant="heading2"
            />
            <PreviewTypography
              v-if="currentBlockParams.body"
              class="content-preview__body"
              :text="currentBlockParams.body"
              variant="titleSmall"
              color="secondary"
            />
            <PreviewButton
              v-if="
                !!currentBlockParams.action &&
                currentBlockParams.action.params.label
              "
              :title="currentBlockParams.action.params.label"
              caret
            />
          </div>
        </PreviewSheet>
      </div>
    </PreviewSheet>
  </PreviewSheet>
</template>
<script lang="ts" setup>
import { computed } from 'vue'

import { PreviewButton, PreviewSheet, PreviewTypography } from '@/lib/builder'

import type { ContentSection } from '../../../WebsiteBuilder.types'

const props = defineProps<{
  content: ContentSection
}>()

const currentBlockParams = computed(() => {
  return props.content?.params
})

const layout = computed(() => {
  if (currentBlockParams.value.layout === 'cover') {
    const media = currentBlockParams.value.media?.params

    return media && 'image' in media ? 'cover' : 'generic'
  }

  return 'generic'
})

const justify = computed(() => {
  if (currentBlockParams.value.alignment === 'center') {
    return 'center'
  } else if (currentBlockParams.value.alignment === 'right') {
    return 'between'
  } else {
    return 'start'
  }
})

const image = computed(() => {
  const media = currentBlockParams.value.media?.params

  return media && 'image' in media ? media.image : null
})
</script>

<style lang="scss" scoped>
.content-preview {
  &--cover {
    padding: 8px;
  }

  &__content-sheet {
    overflow: hidden;
  }

  &__layout {
    gap: 126px;
    position: relative;

    &--cover {
      padding: 80px;
    }

    &--generic {
      padding: 120px;
    }
  }

  &__cover-background {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-size: cover;
    background-position: center center;
  }

  &__cover-stack-sheet {
    padding: 80px;
    z-index: 10;
    min-height: 640px;
    display: flex;
    backdrop-filter: blur(56px);
  }

  &__image {
    width: 520px;
    height: 520px;
    flex-shrink: 0;

    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__video-wrapper {
    position: relative;
    aspect-ratio: 16 / 9;
  }

  &__video {
    position: relative;
    width: 540px;
    height: 303px;
    border: 0;
  }

  &__typography-wrapper {
    max-width: 720px;

    &--cover {
      position: relative;
      z-index: 20;
      max-width: 560px;
    }
  }
}
</style>
