<template>
  <button
    :class="[
      'own-dropdown-menu-item',
      props.active && 'own-dropdown-menu-item__active',
      colorClass,
      'flex-row gap-2 align-center',
    ]"
    @click="$emit('click')"
  >
    <slot name="left">
      <Component
        :is="props.icon"
        v-if="props.icon"
        class="flex-shrink-0"
        size="24"
      />
    </slot>

    <OwnType
      class="flex-1 text--truncate"
      el="span"
      :text="props.label"
      variant="subtitle"
      color="inherit"
      :title="props.label"
    />

    <slot name="right" />
  </button>
</template>

<script lang="ts" setup>
import { type Component, computed } from 'vue'

import { OwnType } from '../../OwnType'

const props = withDefaults(
  defineProps<{
    active?: boolean
    color?: 'neutral' | 'danger'
    icon?: Component
    label: string
  }>(),
  {
    active: false,
    color: 'neutral',
    icon: undefined,
  }
)

defineEmits<(event: 'click') => void>()

const colorClass = computed<string>(() => {
  if (props.color === 'danger') {
    return 'status-danger'
  }

  return props.active ? 'text-color-primary' : 'text-color-secondary'
})
</script>

<style lang="scss">
.own-dropdown-menu-item {
  padding: 16px;
  border-radius: 4px;

  transition: background-color 100ms ease-in;

  &:hover {
    background-color: $background-highlight;
  }

  &__active {
    background-color: $background-highlight;
  }
}
</style>
