import { type BuilderModuleConfig } from '@/lib/builder'
import i18nPlugin from '@/plugins/i18n'
import { locationAnalytics } from '@/utils'

import {
  type Form,
  type FormCreate,
  type FormSection,
  type FormSectionCreate,
  type FormSectionUpdate,
  type FormUpdate,
} from '../../types/FormBuilder.types'
import { FormBuilderBlocks } from '../blocks'
import FormPublishDialog from '../components/form-publish-dialog/FormPublishDialog.vue'
import FormBuilderAddSectionWrapper from '../components/FormBuilderAddSectionWrapper.vue'
import FormBuilderPreviewWrapper from '../components/FormBuilderPreviewWrapper.vue'
import { FormFooterStaticModule } from '../components/preview/footer'
import { FormHeadingStaticModule } from '../components/preview/heading'
import FormBuilderSubmissionContent from '../components/submissions/FormBuilderSubmissionContent.vue'
import FormBuilderSubmissionDetail from '../components/submissions/FormBuilderSubmissionDetail.vue'
import { createResourceHandler } from '../methods/createResourceHandler'
import { fetchFormData } from '../methods/fetchFormData'
import { updateResourceHandler } from '../methods/updateResourceHandler'
import templates from '../templates'

const i18n = i18nPlugin.global

export const formBuilderConfig: BuilderModuleConfig<
  Form,
  FormCreate,
  FormUpdate,
  FormSection,
  FormSectionCreate,
  FormSectionUpdate
> = {
  blocks: FormBuilderBlocks,
  components: {
    preview: {
      addSectionWrapper: FormBuilderAddSectionWrapper,
      footer: FormFooterStaticModule,
      heading: FormHeadingStaticModule,
      wrapper: FormBuilderPreviewWrapper,
    },
    publishDialog: FormPublishDialog,
  },
  customPages: [
    {
      analyticsEvents: [],
      components: {
        contentOverride: FormBuilderSubmissionContent,
      },
      methods: {},
      pageTitle: ' ',
      perResource: true,
      slug: 'submissions',
    },
    {
      analyticsEvents: [],
      components: {
        contentOverride: FormBuilderSubmissionDetail,
      },
      methods: {},
      pageTitle: ' ',
      perResource: true,
      slug: 'submissions/:submissionId',
    },
  ],
  methods: {
    createResource: createResourceHandler,
    fetchDataHandler: fetchFormData,
    updateResource: updateResourceHandler,
  },
  options: {
    analyticsEvents: [
      {
        handler: (resource, brand) => ({
          ...locationAnalytics(brand),
        }),
        label: 'Form Edit Started',
        type: 'start',
      },
      {
        handler: (resource, brand) => ({
          ...locationAnalytics(brand),
          id: resource.id,
          title: resource.title,
        }),
        label: 'Form Edit Successful',
        type: 'update',
      },
      {
        handler: (resource, brand) => ({
          ...locationAnalytics(brand),
          id: resource.id,
          title: resource.title,
        }),
        label: 'Form Edit Failed',
        type: 'failed',
      },
    ],
    createAnalyticsEvents: [
      {
        handler: (brand) => ({
          ...locationAnalytics(brand),
        }),
        label: 'Form Creation Started',
        type: 'start',
      },
      {
        handler: (brand, resource, type) => ({
          ...locationAnalytics(brand),
          id: resource?.id,
          type,
        }),
        label: 'Form Template Selected',
        type: 'template-select',
      },
      {
        handler: (brand) => ({
          ...locationAnalytics(brand),
        }),
        label: 'Form Creation Failed',
        type: 'failed',
      },
    ],
    home: {
      name: 'brand.website.forms.home',
    },
    resourceName: 'forms',
    resourceTitle: {
      key: 'title',
      placeholder: i18n.t('form-builder.resource-title.placeholder'),
    },
    root: 'brand.website',
    storeModule: 'formBuilder',
    templates,
  },
}
