<template>
  <canvas
    ref="canvas"
    class="canvas-color-picker"
    width="167px"
    height="167px"
    @mousedown="onMouseDown"
    @mouseup="onMouseUp"
    @mousemove="onMouseMove"
    @mouseleave="onMouseLeave"
  ></canvas>
</template>
<script>
const componentToHex = (component) => {
  const hex = component.toString(16)
  return hex.length === 1 ? '0' + hex : hex
}

const convertToHex = (rgba) => {
  return `#${componentToHex(rgba[0])}${componentToHex(rgba[1])}${componentToHex(
    rgba[2]
  )}`
}

export default {
  name: 'CanvasColorPicker',
  emits: ['commit', 'preview'],
  data() {
    return {
      ctx: null,
      isMouseDown: false,
      mouseCoords: {
        x: 0,
        y: 0,
      },
      previewColor: '',
      trackingInterval: undefined,
    }
  },
  mounted() {
    const colorPickerCanvas = this.$refs.canvas

    if (colorPickerCanvas) {
      this.ctx = colorPickerCanvas.getContext('2d')
    }
    this.fillWithGradient()
  },
  methods: {
    clearTrackingInterval() {
      clearInterval(this.trackingInterval)
    },
    fillWithGradient() {
      let gradient = this.ctx.createLinearGradient(
        0,
        0,
        this.ctx.canvas.width,
        0
      )

      gradient.addColorStop(0, 'rgb(255, 0, 0)')
      gradient.addColorStop(0.15, 'rgb(255, 0, 255)')
      gradient.addColorStop(0.33, 'rgb(0, 0, 255)')
      gradient.addColorStop(0.49, 'rgb(0, 255, 255)')
      gradient.addColorStop(0.67, 'rgb(0, 255, 0)')
      gradient.addColorStop(0.84, 'rgb(255, 255, 0)')
      gradient.addColorStop(1, 'rgb(255, 0, 0)')

      this.ctx.fillStyle = gradient
      this.ctx.fillRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height)

      gradient = this.ctx.createLinearGradient(0, 0, 0, this.ctx.canvas.height)
      gradient.addColorStop(0, 'rgba(255, 255, 255, 1)')
      gradient.addColorStop(0.5, 'rgba(255, 255, 255, 0)')
      gradient.addColorStop(0.5, 'rgba(0, 0, 0, 0)')
      gradient.addColorStop(1, 'rgba(0, 0, 0, 1)')

      this.ctx.fillStyle = gradient
      this.ctx.fillRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height)
    },
    getChosenColor(mouseCoords) {
      if (this.ctx) {
        const x = mouseCoords.x
        const y = mouseCoords.y

        const pixel = this.ctx.getImageData(x, y, 1, 1)

        return convertToHex(pixel.data).toUpperCase()
      }

      return undefined
    },
    onMouseDown(event) {
      this.isMouseDown = true

      this.mouseCoords = {
        x: event.offsetX,
        y: event.offsetY,
      }

      this.trackingInterval = setInterval(this.triggerColorPreview, 50)
    },
    onMouseLeave() {
      this.isMouseDown = false
      this.clearTrackingInterval()
    },
    onMouseMove(event) {
      this.mouseCoords = {
        x: event.offsetX,
        y: event.offsetY,
      }
    },
    onMouseUp(event) {
      this.isMouseDown = false

      this.clearTrackingInterval()

      const mouseCoords = {
        x: event.offsetX,
        y: event.offsetY,
      }

      const newColor = this.getChosenColor(mouseCoords)

      this.$emit('commit', newColor)
    },
    triggerColorPreview() {
      if (this.isMouseDown) {
        const newColor = this.getChosenColor(this.mouseCoords)

        if (newColor !== this.previewColor) {
          this.previewColor = newColor
          this.$emit('preview', newColor)
        }
      }
    },
  },
}
</script>
<style lang="scss">
.canvas-color-picker {
  border-radius: 2px;
  cursor: crosshair;
}
</style>
