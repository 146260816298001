import { PhYoutubeLogo } from '@phosphor-icons/vue'

import { type BuilderBlockModule } from '@/lib/builder'
import i18nPlugin from '@/plugins/i18n'

import {
  type WebsiteResource,
  type VideoSectionCreate,
  type VideoSection,
  type VideoSectionUpdate,
} from '../../WebsiteBuilder.types'
import { websiteBlockMethods } from '../common/methods'

import VideoEditor from './editor/VideoEditor.vue'
import { createVideoData } from './methods/createVideoData'
import VideoPreview from './preview/VideoPreview.vue'
const i18n = i18nPlugin.global

export const VideoBlockModule: BuilderBlockModule<
  VideoSection,
  VideoSectionCreate,
  VideoSectionUpdate,
  WebsiteResource
> = {
  createData: createVideoData,
  editor: VideoEditor,
  meta: [
    {
      icon: PhYoutubeLogo,
      key: 'video',
      label: i18n.t('website-builder.blocks.video'),
      sort: 7,
    },
  ],
  methods: websiteBlockMethods,
  preview: VideoPreview,
}
