import { ConfiguredClient } from '@/api'
import { type TemplateData } from '@/lib/builder'

import {
  type WebsiteResourceCreate,
  type WebsiteSectionCreate,
} from '../WebsiteBuilder.types'

export const blankTemplate = async (
  brandId: string
): Promise<TemplateData<WebsiteResourceCreate, WebsiteSectionCreate>> => {
  const funnel = await ConfiguredClient.funnel.v1.brands.brand.getFunnel({
    params: { brandId },
  })

  return {
    blocks: [],
    resource: {
      brandId,
      funnel,
      path: { params: { locationId: null, slug: 'custom' }, type: 'custom' },
      sections: [],
      title: 'blank',
    },
  }
}
