import type { ActionContext } from 'vuex'

import { type CoreBrand, type CoreState } from '@/core/store/types'

export const setAllBrands = async (
  { commit }: ActionContext<CoreState, unknown>,
  brands: CoreBrand[]
): Promise<void> => {
  commit('SET_BRANDS', brands)
}
