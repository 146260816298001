import defaultState from './state'

export default {
  RESET_STATE: (state) => {
    state.action = defaultState.action
    state.filters = defaultState.filters
    state.query = defaultState.query
    state.view = defaultState.view
  },
  SET_ACTION: (state, newAction) => {
    state.action = newAction
  },
  SET_QUERY_END: (state, end) => {
    state.query.end = end
  },
  SET_QUERY_SEARCH: (state, searchTerm) => {
    state.query.query = searchTerm
  },
  SET_QUERY_SORT: (state, newSort) => {
    state.query.sort = newSort
  },
  SET_QUERY_START: (state, start) => {
    state.query.start = start
  },
  SET_VIEW: (state, newView) => {
    state.view = newView
  },
  UNSET_QUERY_END: (state) => {
    if (state.query.end) {
      delete state.query.end
    }
  },
  UNSET_QUERY_SEARCH: (state) => {
    if (state.query.query) {
      delete state.query.query
    }
  },
  UNSET_QUERY_START: (state) => {
    if (state.query.start) {
      delete state.query.start
    }
  },
  UPDATE_PAGE: (state, currentPage) => {
    state.query.page = currentPage
  },
}
