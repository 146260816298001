<i18n locale="en">
{
  "actions": {
    "send": "Send",
    "save-draft": "Save as draft"
  }
}
</i18n>

<template>
  <div class="flex-row gap-6 align-center justify-end">
    <button @click="$emit('save')">
      <OwnType :text="t('actions.save-draft')" color="brand" variant="button" />
    </button>
    <OwnButton
      class="flex-1"
      :processing="props.loading"
      :text="t('actions.send')"
      primary
      @click="$emit('publish')"
    >
      <template #icon-right>
        <PhArrowCircleUp size="24" />
      </template>
    </OwnButton>
  </div>
</template>

<script lang="ts" setup>
import { PhArrowCircleUp } from '@phosphor-icons/vue'
import { useI18n } from 'vue-i18n'

import { OwnButton, OwnType } from '@/ui'

const props = defineProps<{ loading: boolean }>()

defineEmits<{ publish: []; save: [] }>()

const { t } = useI18n()
</script>
