<i18n locale="en">
{
  "title": "Add Brand",
  "actions": {
    "add": "Add Brand | Add Brand | Add Brands"
  },
  "labels": {
    "empty": "No Brand Found"
  }
}
</i18n>

<template>
  <OwnDialog :model-value="true" @cancel="emit('close')">
    <template #heading>
      <OwnDialogHeading :title="t('title')" @cancel="emit('close')" />
    </template>

    <div class="brand-selector-dialog__filter-bar">
      <SearchInput
        ref="searchInput"
        v-model="searchQuery"
        class="w-full"
        borderless
      />
    </div>

    <OwnDialogContent scroll>
      <!-- @vue-expect-error Checkbox Generic Needs work -->
      <OwnCheckboxGroup
        v-if="displayedOptions.length > 0"
        :model-value="selectedBrandIds"
        :items="displayedOptions"
        :disabled-items="alreadySelected"
        @select="onBrandSelect"
        @deselect="onBrandDeselect"
      >
        <template #prefix="{ item }">
          <!-- @vue-expect-error Checkbox Generic Needs work -->
          <BrandLogo :brand="item" />
        </template>
      </OwnCheckboxGroup>

      <DefaultEmptyState v-else :message="t('labels.empty')">
        <template #icon>
          <PhBookBookmark size="24" />
        </template>
      </DefaultEmptyState>
    </OwnDialogContent>

    <OwnDialogFooter>
      <OwnButton
        :text="t('actions.add', selectedBrands.length)"
        primary
        @click="onConfirmSelection"
      >
        <template #icon-right>
          <PhCheckCircle size="24" />
        </template>
      </OwnButton>
    </OwnDialogFooter>
  </OwnDialog>
</template>

<script lang="ts" setup>
import { PhBookBookmark, PhCheckCircle } from '@phosphor-icons/vue'
import { ComponentPublicInstance, computed, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { ConfiguredClient } from '@/api'
import { DefaultEmptyState } from '@/components/empty'
import { SearchInput } from '@/components/inputs'
import { logError } from '@/logger'
import {
  OwnButton,
  OwnCheckboxGroup,
  OwnDialog,
  OwnDialogHeading,
  OwnDialogContent,
  OwnDialogFooter,
} from '@/ui'

import { type Brand } from '../../types'
import BrandLogo from '../BrandLogo.vue'

defineProps<{
  alreadySelected: string[]
}>()

const emit = defineEmits<{
  (event: 'close'): void
  (event: 'select', value: Brand[]): void
}>()

const { t } = useI18n()

const searchQuery = ref<string | undefined>(undefined)

const isLoading = ref(true)
const displayedOptions = ref<Brand[]>([])

const selectedBrands = ref<Brand[]>([])

const searchInput = ref<ComponentPublicInstance | null>(null)

const selectedBrandIds = computed(() =>
  selectedBrands.value.map((brand) => brand.id)
)

const onBrandSelect = (brandId: string) => {
  const selectedBrand = displayedOptions.value.find(
    (brand) => brand.id === brandId
  )

  if (selectedBrand) {
    selectedBrands.value.push(selectedBrand)
  }
}

const onBrandDeselect = (brandId: string) => {
  const targetIdx = selectedBrands.value.findIndex(
    (brand) => brand.id === brandId
  )

  if (targetIdx !== -1) {
    selectedBrands.value.splice(targetIdx, 1)
  }
}

const onConfirmSelection = () => {
  // User hasn't selected anything, just dismiss the dialog
  if (selectedBrands.value.length === 0) return emit('close')

  emit('select', selectedBrands.value)
}

const fetchBrands = async () => {
  try {
    const { results } = await ConfiguredClient.brands.getBrands({
      query: {
        query: searchQuery.value,
      },
    })

    displayedOptions.value = results.map((brand) => ({
      ...brand,
      label: brand.name,
    }))
  } catch (err) {
    logError(err)
  } finally {
    isLoading.value = false
  }
}

watch(searchQuery, async () => {
  await fetchBrands()
})

onMounted(async () => {
  isLoading.value = true
  await fetchBrands()
})
</script>

<style lang="scss" scoped>
.brand-selector-dialog {
  &__filter-bar {
    padding: 16px 24px;
    border-bottom: 1px dashed $background-divider;
  }
}
</style>
