import { defineAsyncComponent } from 'vue'

export const MenuSelector = defineAsyncComponent(
  async () => await import('./multi/MenuSelector.vue')
)
export const MenuSelectorSingle = defineAsyncComponent(
  async () => await import('./single/MenuSelectorSingle.vue')
)

export const MenuCouponSelector = defineAsyncComponent(
  async () => await import('./multi/CouponSelector.vue')
)
export const CouponSelectorSingle = defineAsyncComponent(
  async () => await import('./single/CouponSelectorSingle.vue')
)

export const MenuCategorySelector = defineAsyncComponent(
  async () => await import('./multi/CategorySelector.vue')
)
export const CategorySelectorSingle = defineAsyncComponent(
  async () => await import('./single/CategorySelectorSingle.vue')
)

export const MenuItemSelector = defineAsyncComponent(
  async () => await import('./multi/ItemSelector.vue')
)
export const ItemSelectorSingle = defineAsyncComponent(
  async () => await import('./single/ItemSelectorSingle.vue')
)

export const ModifierSetSelector = defineAsyncComponent(
  async () => await import('./multi/ModifierSetSelector.vue')
)
export const ModifierSetSelectorSingle = defineAsyncComponent(
  async () => await import('./single/ModifierSetSelectorSingle.vue')
)

export const ModifierSelector = defineAsyncComponent(
  async () => await import('./multi/ModifierSelector.vue')
)
export const ModifierSelectorSingle = defineAsyncComponent(
  async () => await import('./single/ModifierSelectorSingle.vue')
)
