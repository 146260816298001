import merge from 'lodash/merge'

import { ConfiguredClient } from '@/api'
import { type TemplateData } from '@/lib/builder'

import { ContentBlockModule } from '../blocks/content'
import {
  type WebsiteResourceCreate,
  type WebsiteSectionCreate,
} from '../WebsiteBuilder.types'

export const articleTemplate = async (
  brandId: string
): Promise<TemplateData<WebsiteResourceCreate, WebsiteSectionCreate>> => {
  const funnel = await ConfiguredClient.funnel.v1.brands.brand.getFunnel({
    params: { brandId },
  })

  return {
    blocks: [
      {
        ...merge(ContentBlockModule.createData({ brandId, type: 'content' }), {
          params: { action: null },
        }),
      },
      {
        ...merge(ContentBlockModule.createData({ brandId, type: 'content' }), {
          params: {
            action: null,
            alignment: 'right',
            media: {
              params: {
                image:
                  'https://static-content.owner.com/81a84951-26b3-4390-a14b-84072a6dde57',
              },
            },
          },
        }),
      },
    ],
    resource: {
      brandId,
      funnel,
      path: { params: { locationId: null, slug: 'custom' }, type: 'custom' },
      sections: [],
      title: 'article',
    },
  }
}
