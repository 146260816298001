import { ConfiguredClient } from '@/api'

import {
  type WebsiteResource,
  type WebsiteResourceCreate,
} from '../WebsiteBuilder.types'

export const createResourceHandler = async (
  createData: WebsiteResourceCreate
): Promise<WebsiteResource> => {
  const { brandId, path, title, sections } = createData

  const [brand, funnel, page, nav, theme] = await Promise.all([
    ConfiguredClient.brands.brand.getBrand({
      params: {
        brandId,
      },
    }),
    ConfiguredClient.funnel.v1.brands.brand.getFunnel({
      params: {
        brandId,
      },
    }),
    ConfiguredClient.funnel.v1.pages.createPage({
      body: {
        brandId,
        path,
        sections,
        title,
      },
    }),
    ConfiguredClient.dashboard.v1.brands.brand.website.getNavItems({
      params: {
        brandId,
      },
    }),
    ConfiguredClient.brands.brand.theme.getBrandTheme({
      params: {
        brandId,
      },
    }),
  ])

  return {
    ...page,
    brand,
    funnel,
    nav,
    theme,
  }
}
