<i18n locale="en">
{
  "errors": {
    "load": "Unable to load item"
  }
}
</i18n>

<template>
  <OwnType v-if="itemName" :text="itemName" color="secondary" />
</template>

<script>
import { useI18n } from 'vue-i18n'

import { itemsApi2 } from '@/api'
import notify from '@/mixins/notify'
import { OwnType } from '@/ui'

export default {
  name: 'ItemName',
  components: {
    OwnType,
  },
  mixins: [notify],
  props: {
    /**
     * Item Id to fetch details with
     */
    itemId: { type: String, required: true },

    /**
     * Override default text classes
     */
    overrideClasses: { type: String, default: undefined },
  },
  setup() {
    const { t } = useI18n()

    return { t }
  },
  data() {
    return {
      itemName: undefined,
    }
  },
  created() {
    this.fetchItemDetails()
  },
  methods: {
    async fetchItemDetails() {
      const { itemId } = this
      try {
        const itemDetails = await itemsApi2.getDetails(itemId)

        this.itemName = itemDetails.name
      } catch (error) {
        this.$notify(this.t('errors.load'), 'error')
      }
    },
  },
}
</script>
