import { type ActionContext } from 'vuex'

import {
  type BlockCore,
  type BuilderState,
  type ResourceCore,
} from '@/lib/builder'
import { type CoreModule } from '@/lib/store'

export const discardStagedCommands = async <
  TResource extends ResourceCore,
  TBlock extends BlockCore,
>({
  state,
  commit,
  dispatch,
}: ActionContext<
  BuilderState<TResource, TBlock>,
  CoreModule
>): Promise<void> => {
  const currentCommandQueue = state._builder_commandQueue

  const firstUncommittedCommand = currentCommandQueue.find(
    (command) => !command.committed
  )

  await dispatch(
    '_builder_stepTo',
    firstUncommittedCommand?.context.prevCommand
  )

  commit('_builder_DISCARD_STAGED_CHANGES')
}
