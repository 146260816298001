<i18n locale="en">
{
  "actions": {
    "order": "Order Online"
  }
}
</i18n>

<template>
  <PreviewSheet
    :style="{ backgroundColor }"
    class="website-builder-preview-navbar"
    :class="[
      `website-builder-preview-navbar--${logoSize}`,
      `website-builder-preview-navbar--${logoAlignment}`,
    ]"
    border="primary"
    radius="0"
  >
    <div class="website-builder-preview-navbar__logo flex-row align-center">
      <PreviewLogo :logo-size="logoSize" />
    </div>

    <div class="website-builder-preview-navbar__menu flex-row align-center">
      <NavbarMenuLinkPreview :active-page="activePage" :nav-links="navItems" />
    </div>

    <div
      class="website-builder-preview-navbar__details flex-row align-center justify-end"
    >
      <PreviewButtonLegacy size="xsmall">
        <PreviewTypographyLegacy
          :text="t('actions.order')"
          color="white"
          variant="buttonSmall"
        />
      </PreviewButtonLegacy>
    </div>
  </PreviewSheet>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { mapGetters } from 'vuex'

import {
  PreviewButtonLegacy,
  PreviewLogo,
  PreviewSheet,
  PreviewTypographyLegacy,
  useMergedTheme,
} from '@/lib/builder'

import NavbarMenuLinkPreview from './NavbarMenuLinkPreview'

export default {
  name: 'WebsiteBuilderPreviewNavbar',
  components: {
    NavbarMenuLinkPreview,
    PreviewButtonLegacy,
    PreviewLogo,
    PreviewSheet,
    PreviewTypographyLegacy,
  },
  mixins: [useMergedTheme],
  props: {
    /**
     * Active page to show
     */
    activePage: { type: String, default: undefined },

    /**
     * Show in spacer mode
     */
    spacer: { type: Boolean, default: false },
  },
  setup() {
    const { t } = useI18n()

    return { t }
  },
  computed: {
    ...mapGetters({
      activeBrandLocations: 'core/brand/active/locations',
      activeLocation: 'core/location/active/*',
      currentResource: 'websiteBuilder/currentResource',
    }),
    backgroundColor() {
      const { mergedTheme } = this

      const themeBg = mergedTheme?.colors?.background?.primary

      return `${themeBg}E6`
    },
    funnelNav() {
      const { currentResource } = this

      return currentResource?.funnel?.navigation
    },
    logoAlignment() {
      const { funnelNav } = this

      return funnelNav?.layout ?? 'logo-left'
    },
    logoSize() {
      const { funnelNav } = this
      return funnelNav?.logoSize
    },
    navItems() {
      const { currentResource } = this

      // locations is being removed on the server, and will be cleaned up (https://linear.app/owner/issue/GUE-440/remove-locations-nav-item-on-olympus-and-hermes)
      return (currentResource?.nav ?? []).filter(
        (item) => item.type !== 'action' || item.params.action !== 'locations'
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.website-builder-preview-navbar {
  padding: 16px 96px;
  display: grid;
  column-gap: 32px;
  grid-template-rows: minmax(0, 1fr);
  grid-template-columns: 1fr auto 1fr;

  &--logo-left {
    grid-template-columns: auto 1fr auto;
    grid-template-areas: 'logo menu details';
  }

  &--logo-center {
    grid-template-columns: 1fr auto 1fr;
    grid-template-areas: 'menu logo details';
  }

  &__logo {
    grid-area: logo;
  }

  &__menu {
    grid-area: menu;
  }

  &__details {
    grid-area: details;
  }
}
</style>
