<template>
  <OwnCard
    class="numeric-input"
    :class="[props.small && 'numeric-input--small']"
    control
  >
    <div class="h-full flex-row align-center">
      <button
        :class="[
          'numeric-input__action numeric-input__action--left',
          props.small && 'numeric-input__action--small',
          'flex-row gap-1 align-center justify-center',
        ]"
        :disabled="disabled || isDecrementDisabled"
        @click="decrement"
      >
        <PhMinus size="16" weight="bold" />
        <OwnType
          v-if="props.incrementBy > 1"
          :text="props.incrementBy"
          variant="subtitle"
          color="inherit"
        />
      </button>
      <div
        :class="[
          'numeric-input__display flex-1',
          disabled && 'numeric-input__display--disabled',
          'flex-col align-center justify-center',
        ]"
      >
        <OwnType
          :text="modelVal ?? 0"
          align="center"
          variant="subtitle"
          :color="disabled ? 'inherit' : 'primary'"
          el="span"
        />
      </div>
      <button
        :class="[
          'numeric-input__action numeric-input__action--right',
          props.small && 'numeric-input__action--small',
          'flex-row gap-1 align-center justify-center',
        ]"
        :disabled="disabled || isIncrementDisabled"
        @click="increment"
      >
        <PhPlus size="16" weight="bold" />
        <OwnType
          v-if="props.incrementBy > 1"
          :text="props.incrementBy"
          variant="subtitle"
          color="inherit"
        />
      </button>
    </div>
  </OwnCard>
</template>

<script lang="ts" setup>
import { PhMinus, PhPlus } from '@phosphor-icons/vue'
import { computed, onMounted } from 'vue'

import { OwnCard, OwnType } from '@/ui'

const props = withDefaults(
  defineProps<{
    accessibilityId?: string
    disabled?: boolean
    error?: boolean
    incrementBy?: number
    max?: number
    min?: number
    placeholder?: string
    small?: boolean
  }>(),
  {
    accessibilityId: undefined,
    disabled: false,
    error: false,
    incrementBy: 1,
    max: undefined,
    min: 0,
    placeholder: undefined,
    small: false,
  }
)

const modelVal = defineModel<number>({ default: 0 })

const isDecrementDisabled = computed(() => {
  return modelVal.value === props.min
})

const isIncrementDisabled = computed(() => {
  if (!props.max) return false

  return modelVal.value === props.max
})

const increment = () => {
  const nextVal = modelVal.value + props.incrementBy

  if (props.max && nextVal > props.max) {
    modelVal.value = props.max
  } else {
    modelVal.value = nextVal
  }
}

const decrement = () => {
  const nextVal = modelVal.value - props.incrementBy

  if (nextVal < props.min) {
    modelVal.value = props.min
  } else {
    modelVal.value = nextVal
  }
}

onMounted(() => {
  if (modelVal.value < props.min) {
    modelVal.value = props.min
  }

  if (props.max && modelVal.value > props.max) {
    modelVal.value = props.max
  }
})
</script>

<style lang="scss" scoped>
.numeric-input {
  height: 44px;

  &--small {
    height: 36px;
  }

  &__action {
    height: 100%;
    width: 49px;
    flex-shrink: 0;

    &--small {
      width: 36px;
    }

    color: $text-color-primary;

    &--left {
      border-bottom-left-radius: 8px;
      border-top-left-radius: 8px;
      border-right: 1px dashed $background-divider;

      &:disabled {
        background-color: $background-secondary;
        color: $background-divider;
        cursor: not-allowed;
      }
    }
    &--right {
      border-bottom-right-radius: 8px;
      border-top-right-radius: 8px;
      border-left: 1px dashed $background-divider;

      &:disabled {
        background-color: $background-secondary;
        color: $background-divider;
        cursor: not-allowed;
      }
    }
  }

  &__display {
    height: 100%;
    width: 36px;

    &--disabled {
      background-color: $background-secondary;
      color: $background-divider;
      cursor: not-allowed;
    }
  }
}
</style>
