<i18n locale="en">
{
  "actions": {
    "done": "Add Job"
  },
  "include-on-website": "Add to website",
  "error": "Failed to create job",
  "error-set-location": "Failed to create job, please select a location and try again",
  "location": {
    "label": "Location",
    "placeholder": "Select a location"
  },
  "options": "Options",
  "role": {
    "label":"Role",
    "placeholder": "Aa"
  },
  "success": "Successfully created job",
  "title": "Add New Role"
}
</i18n>
<template>
  <OwnDialog :model-value="modelValue" @cancel="onClose">
    <template #heading>
      <OwnDialogHeading :title="t('title')" @cancel="onClose" />
    </template>
    <template #default>
      <OwnDialogContent scroll>
        <div class="flex-col gap-8">
          <OwnInputContainer v-if="showLocation" :label="t('location.label')">
            <OwnDropdown
              v-model="location"
              :options="locationOptions"
              :placeholder="t('location.placeholder')"
            />
          </OwnInputContainer>
          <OwnInputContainer
            :label="t('role.label')"
            :errors="title.status.errors"
          >
            <OwnInput
              v-model="title.value"
              :placeholder="t('role.placeholder')"
            />
          </OwnInputContainer>

          <div class="flex-col gap-2">
            <OwnType :text="t('options')" color="primary" variant="subtitle" />
            <OwnToggle
              v-if="showIncludeOnWebsite"
              v-model="includeOnWebsite"
              :label="t('include-on-website')"
              control
            />
            <SalaryInput v-model="salary" />
          </div>
        </div>
      </OwnDialogContent>
    </template>
    <template #footer>
      <OwnDialogFooter>
        <OwnButton
          :text="t('actions.done')"
          primary
          :processing="isLoading"
          @click="onSubmit"
        >
          <template #icon-right>
            <PhPlusCircle size="24" />
          </template>
        </OwnButton>
      </OwnDialogFooter>
    </template>
  </OwnDialog>
</template>
<script>
import { PhPlusCircle } from '@phosphor-icons/vue'
import { useI18n } from 'vue-i18n'
import { mapGetters } from 'vuex'

import { ConfiguredClient } from '@/api'
import { FormBuilder, Validators } from '@/forms'
import { logError } from '@/logger'
import notify from '@/mixins/notify'
import {
  OwnButton,
  OwnDialog,
  OwnDialogContent,
  OwnDialogFooter,
  OwnDialogHeading,
  OwnDropdown,
  OwnInput,
  OwnInputContainer,
  OwnToggle,
  OwnType,
} from '@/ui'

import SalaryInput from '../components/SalaryInput.vue'
import { prepareSalaryForSubmission } from '../utils/salary.ts'

const DEFAULTS = {
  includeOnWebsite: false,
  salary: {
    max: '20',
    min: '0',
  },
}

export default {
  name: 'NewJobDialog',
  components: {
    OwnButton,
    OwnDialog,
    OwnDialogContent,
    OwnDialogFooter,
    OwnDialogHeading,
    OwnDropdown,
    OwnInput,
    OwnInputContainer,
    OwnToggle,
    OwnType,
    PhPlusCircle,
    SalaryInput,
  },
  mixins: [
    notify,
    FormBuilder({
      title: {
        debounce: 250,
        validators: [Validators.required],
      },
    }),
  ],
  emits: ['created', 'update:modelValue'],
  setup() {
    const { t } = useI18n()

    return { t }
  },
  props: {
    /**
     * Show dialog, default to `true` so the dialog can be used by a router without any props
     */
    modelValue: { type: Boolean, default: true },

    /**
     * When `true`, navigate to the jobs page on close. Otherwise, emit `update:modelValue`.
     */
    navigate: { type: Boolean, default: true },

    /**
     * Show "Include on website" field
     */
    showIncludeOnWebsite: { type: Boolean, default: true },

    /**
     * Show "Location" field
     * When the Jobs page is switched from location-level to brand-level, this prop can be removed and the Location
     * field can be displayed all the time.
     */
    showLocation: { type: Boolean, default: false },
  },
  data() {
    return {
      ...DEFAULTS,
      isLoading: false,
      location: null,
    }
  },
  computed: {
    ...mapGetters({
      activeBrandId: 'core/brand/active/id',
      activeLocationId: 'core/location/active/id',
      locations: 'core/locations/*',
    }),
    locationOptions() {
      return this.locations.map((location) => ({
        title: location.name,
        value: location.id,
      }))
    },
  },
  created() {
    this.location = this.activeLocationId
  },
  methods: {
    onClose() {
      this.resetForm()
      this.includeOnWebsite = DEFAULTS.includeOnWebsite
      this.salary = DEFAULTS.salary

      if (this.navigate) {
        this.$router.push({
          name: 'location.careers.jobs',
        })
      } else {
        this.$emit('update:modelValue', false)
      }
    },
    async onSubmit() {
      const {
        activeBrandId,
        activeLocationId,
        formValues,
        includeOnWebsite,
        location,
        salary,
        showIncludeOnWebsite,
      } = this

      this.isLoading = true

      const query = {}
      if (includeOnWebsite && showIncludeOnWebsite) {
        query.includeOnWebsite = true
      }

      try {
        const result = await ConfiguredClient.careers.v1.jobs.createJob({
          body: {
            brandId: activeBrandId,
            locationId: location ?? activeLocationId,
            ...formValues,
            salary: prepareSalaryForSubmission(salary),
          },
          query,
        })
        this.$notify(this.t('success'), 'success')
        this.onClose()
        this.$emit('created', result)
      } catch (err) {
        if (
          err?.code === 'ERR_BAD_REQUEST' &&
          !(activeLocationId ?? location)
        ) {
          this.$notify(this.t('error-set-location'), 'error')
        } else {
          logError(err)
          this.$notify(this.t('error'), 'error')
        }
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
