/**
 * Checks to see if the current user has scopes valid for an operation
 * @param {string[]} sessionScopes A list of scopes for the current user
 * @param {string|string[]} validScopes A list of scopes valid for an operation
 * @returns {boolean} true if user is valid for `validScopes`
 */
export const checkScopes = (sessionScopes = [], validScopes = []) => {
  if (!sessionScopes.length) return false
  validScopes = Array.isArray(validScopes) ? validScopes : [validScopes]
  for (const i in validScopes) {
    const scope = validScopes[i]
    if (Array.isArray(scope)) {
      let allMatched = true
      for (const groupedScope of scope) {
        if (!checkScopes(sessionScopes, groupedScope)) {
          allMatched = false
          break
        }
      }
      if (allMatched) return true
    } else if (scope.endsWith('*')) {
      if (sessionScopes.find((s) => s.startsWith(scope.replace(':*', ''))))
        return true
    } else if (sessionScopes.includes(scope)) return true
  }
  return false
}

/**
 * Checks the current user's session against a list of valid roles
 * @param {string} sessionRole The current role on the session
 * @param {string|string[]} validRoles A list of valid roles
 */
export const checkRole = (sessionRole, validRoles = []) => {
  if (!sessionRole) return false
  validRoles = Array.isArray(validRoles) ? validRoles : [validRoles]
  for (const i in validRoles) {
    const role = validRoles[i]
    if (role.endsWith(':*') && sessionRole.startsWith(role.replace(':*', '')))
      return true
    else if (role === sessionRole) return true
  }
  return false
}

/**
 * Checks to see if the user has access to particular organizations
 * @param {object} sessionACL The current user's ACL object
 * @param {object} aclMap A mapping of collections to document id[s]
 */
export const checkACL = (sessionACL, aclMap) => {
  const collectionMap = {
    Brand: 'brands',
    Location: 'locations',
  }

  for (const [collection, list] of Object.entries(aclMap)) {
    if (!list) break
    const userList = sessionACL[collectionMap[collection]]
    if (userList === null) break
    const requiredList = Array.isArray(list) ? list : [list]
    for (const id of requiredList) {
      if (!userList.includes(id)) return false
    }
  }
  return true
}
