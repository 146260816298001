<template>
  <PreviewHr class="preview-decoration-line" :color="color" thickness="thick" />
</template>

<script>
import PreviewHr from './PreviewHr'

export default {
  name: 'PreviewDecorationLine',
  components: {
    PreviewHr,
  },
  props: {
    /**
     * Color of line
     */
    color: {
      type: String,
      default: 'textPrimary',
      validator: (v) =>
        ['primary', 'divider', 'textPrimary', 'brandPrimary', 'white'].includes(
          v
        ),
    },
  },
}
</script>

<style lang="scss" scoped>
.preview-decoration-line {
  width: 80px;
}
</style>
