<template>
  <OwnScrollContainer>
    <div
      :class="[
        'max-width-layout flex-row justify-center h-full',
        shouldCenterAlign ? 'align-center' : 'align-start',
      ]"
    >
      <div class="flex-col max-width-layout__container h-full">
        <slot />
      </div>
    </div>
  </OwnScrollContainer>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { OwnScrollContainer } from '@/ui'
import { useBreakpoints } from '@/utils'

const props = withDefaults(
  defineProps<{
    maxWidth?: string
    verticallyCentered?: boolean
  }>(),
  {
    maxWidth: '500',
    verticallyCentered: false,
  }
)

const { isDesktop } = useBreakpoints()

const computedMaxWidth = computed(() => {
  return `${props.maxWidth}px`
})

const shouldCenterAlign = computed(() => {
  if (!isDesktop.value) return false

  return props.verticallyCentered
})
</script>

<style lang="scss" scoped>
.max-width-layout {
  min-height: 100%;
  padding: 32px 16px 120px 16px;

  @include respond-to('md-and-up') {
    padding: 80px 120px;
  }

  &__container {
    min-height: 100%;
    max-width: v-bind(computedMaxWidth);
    flex-grow: 1;
  }
}
</style>
