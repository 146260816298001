<template>
  <div class="preview-wrapper flex-row">
    <div class="preview-wrapper__lhs" :class="[!isDesktop && 'w-full']">
      <slot name="default" />
    </div>

    <div
      v-if="isDesktop"
      class="preview-wrapper__preview-container flex-col gap-10 justify-center align-center"
    >
      <slot v-if="$slots['preview-top']" name="preview-top" />

      <OwnCard
        class="preview-wrapper__card h-full w-full"
        background-color="background-secondary"
        hide-border
      >
        <slot name="preview" />
      </OwnCard>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { OwnCard } from '@/ui'
import { useBreakpoints } from '@/utils'

const { isDesktop } = useBreakpoints()
</script>

<style lang="scss" scoped>
.preview-wrapper {
  height: 100%;
  width: 100%;

  &__lhs {
    z-index: 10;
    box-shadow: 2px 0 6px rgba(0, 0, 0, 0.08);
  }

  &__preview-container {
    flex: 1;
    background-color: $background-secondary;
    padding: 40px 48px;
    z-index: 9;
  }

  &__card {
    max-width: 1440px;
  }
}
</style>
