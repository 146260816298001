import client from '../client'

/**
 * Returns Daily Customer Data
 * @param {object} query query object
 * @param {string} query.start start date
 * @param {string} query.end end date
 * @param {string} [query.locationId] Owner Location ID
 * @param {string} [query.brandId] Owner Brand ID
 * @returns {Promise} Resolves daily customer data
 */
export const getCustomerDailyStats = (query) =>
  client.get('/hermes/analytics/customer-stats', query)

/**
 * Returns Daily Gift Card Stats
 * @param {object} query query object
 * @param {string} query.start start date
 * @param {string} query.end end date
 * @param {string} [query.locationId] Owner Location ID
 * @param {string} [query.brandId] Owner Brand ID
 * @returns {Promise} Resolves daily gift card stats
 */
export const getGiftCardDailyStats = (query) =>
  client.get('/hermes/analytics/daily-gift-card-stats', query)

/**
 * Returns Daily Gift Card Stats
 * @param {object} query query object
 * @param {string} query.start start date
 * @param {string} query.end end date
 * @param {string} [query.locationId] Owner Location ID
 * @param {string} [query.brandId] Owner Brand ID
 * @returns {Promise} Resolves daily gift card stats
 */
export const getGiftCardChargeDailyStats = (query) =>
  client.get('/hermes/analytics/daily-gift-card-charge-stats', query)

/**
 * Returns Daily Website Session Stats
 * @param {object} query query object
 * @param {string} query.start start date
 * @param {string} query.end end date
 * @param {string} [query.locationId] Owner Location ID
 * @param {string} [query.brandId] Owner Brand ID
 * @returns {Promise} Resolves daily session stats
 */
export const getDailySessionStats = (query) =>
  client.get('/hermes/analytics/daily-session-stats', query)

/**
 * Returns Customer Attribution Source Stats over query time period
 * @param {object} query query object
 * @param {string} query.start start date
 * @param {string} query.end end date
 * @param {string} [query.locationId] Owner Location ID
 * @param {string} [query.brandId] Owner Brand ID
 * @returns {Promise} Resolves customer attribution stats
 */
export const getCustomerSourceStats = (query) =>
  client.get('/hermes/analytics/customer-source-stats', query)

/**
 * Returns daily marketing stats
 * @param {object} query query object
 * @param {string} query.start start date
 * @param {string} query.end end date
 * @param {string} [query.locationId] Owner Location ID
 * @param {string} [query.brandId] Owner Brand ID
 * @returns {Promise} Resolves daily marketing stats
 */
export const getDailyMarketingStats = (query) =>
  client.get('/hermes/analytics/daily-marketing-stats', query)

/**
 * Returns summary of orders for a range of dates
 * @param {object} query query object
 * @param {string} query.start start date
 * @param {string} query.end end date
 * @param {string} [query.locationId] Owner Location ID
 * @param {string} [query.brandId] Owner Brand ID
 * @returns {Promise} resolves summary of orders for the date range
 */
export const getOrdersSummary = (query) =>
  client.get('/hermes/analytics/order-summary', query)

/**
 * Returns order conversion status for a range of dates
 * @param {object} query query object
 * @param {string} query.start start date
 * @param {string} query.end end date
 * @param {string} [query.locationId] Owner Location ID
 * @param {string} [query.brandId] Owner Brand ID
 * @returns {Promise} resolves order conversion stats
 */
export const getOrderConversionStatus = (query) =>
  client.get('/hermes/analytics/order-conversion-stats', query)

/**
 * Returns number of new customers
 * @param {object} query query object
 * @param {string} query.start start date
 * @param {string} query.end end date
 * @param {string} [query.locationId] Owner Location ID
 * @param {string} [query.brandId] Owner Brand ID
 * @returns {Promise} resolves number of new customers
 */
export const getNewCustomers = (query) =>
  client.get('/hermes/analytics/new-customers', query)

/**
 * Returns number of returning customers
 * @param {object} query query object
 * @param {string} query.start start date
 * @param {string} query.end end date
 * @param {string} [query.locationId] Owner Location ID
 * @param {string} [query.brandId] Owner Brand ID
 * @returns {Promise} resolves number of new customers
 */
export const getReturningCustomers = (query) =>
  client.get('/hermes/analytics/returning-customers', query)

/**
 * Returns order stats by day for a range of dates
 * @param {object} query query object
 * @param {string} query.start start date
 * @param {string} query.end end date
 * @param {string} [query.locationId] Owner Location ID
 * @param {string} [query.brandId] Owner Brand ID
 * @returns {Promise} resolves order stats by day for the date range
 */
export const getOrdersDailyStats = (query) =>
  client.get('/hermes/analytics/daily-stats', query)

/**
 * Returns review stats by day for a range of dates
 * @param {object} query query object
 * @param {string} query.start start date
 * @param {string} query.end end date
 * @param {string} [query.locationId] Owner Location ID
 * @param {string} [query.brandId] Owner Brand ID
 * @returns {Promise} resolves review stats for the date range
 */
export const getReviewsSummary = (query) =>
  client.get('/hermes/analytics/review-summary', query)

/**
 * Returns stats for individual items and modifiers
 * @param {object} query query object
 * @param {string} query.end end date
 * @param {number} query.limit pagination page limit
 * @param {string} query.locationId Owner Location ID
 * @param {number} query.page page to retrieve (for pagination)
 * @param {string} query.query - search by item name
 * @param {string[]} query.sort an array of sort queries
 * @param {string} query.start start date
 * @returns {Promise} returns a list of items and their stats
 */
export const getItemStats = (query) =>
  client.get('/hermes/analytics/item-stats', query)

/**
 * Returns Order source attribution stats
 * @param {object} query query object
 * @param {string} query.start start date
 * @param {string} query.end end date
 * @param {string} [query.locationId] Owner Location ID
 * @param {string} [query.brandId] Owner Brand ID
 * @returns {Promise} resolves order attribution stats
 */
export const getOrderAttributionStats = (query) =>
  client.get('/hermes/analytics/source-stats', query)

/**
 * Returns daily coupon stats
 * @param {object} query query object
 * @param {string} query.start start date
 * @param {string} query.end end date
 * @param {string} query.locationId Owner Location ID
 */
export const getCouponStats = (query) =>
  client.get('/hermes/analytics/coupon-stats', query)
