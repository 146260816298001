<template>
  <div class="preview-text-field" :style="style">
    <PreviewTypographyLegacy :text="placeholder" color="placeholder" />
  </div>
</template>

<script>
import { PreviewTypographyLegacy } from '@/lib/builder'

import { useMergedTheme } from './mixins/useMergedTheme'

export default {
  name: 'PreviewTextField',
  components: { PreviewTypographyLegacy },
  mixins: [useMergedTheme],
  props: {
    placeholder: { type: String, default: '' },
  },
  computed: {
    style() {
      const { mergedTheme } = this

      return {
        backgroundColor: mergedTheme.colors.background.primary,
        borderColor: mergedTheme.colors.background.divider,
        borderRadius: `${mergedTheme.borderRadius.control}rem`,
        color: mergedTheme.colors.text.placeholder,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.preview-text-field {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  overflow: hidden;
  border-style: solid;
  border-width: 1px;
  margin-bottom: 8px;
}
</style>
