import { type RouteRecordRaw } from 'vue-router'

import ApplicationDetailsDialog from '../components/applications-table/ApplicationDetailsDialog.vue'
import ApplicationsTable from '../components/applications-table/ApplicationsTable.vue'
import JobsTable from '../components/jobs-table/JobsTable.vue'
import EditJobDialog from '../entry-components/EditJobDialog.vue'
import NewJobDialog from '../entry-components/NewJobDialog.vue'
import CareersView from '../views/CareersView.vue'

export const careersRouter: RouteRecordRaw[] = [
  {
    children: [
      {
        children: [
          {
            component: ApplicationDetailsDialog,
            name: 'location.careers.applications.detail',
            path: ':applicationId',
            props: (route) => ({
              applicationId: route.params.applicationId,
            }),
          },
        ],
        component: ApplicationsTable,
        meta: {
          brandRedirect: 'brand.careers',
          isLocationPage: true,
        },
        name: 'location.careers.applications',
        path: 'applications',
      },
      {
        children: [
          {
            component: NewJobDialog,
            name: 'location.careers.jobs.new',
            path: 'new',
          },
          {
            component: EditJobDialog,
            name: 'location.careers.jobs.edit',
            path: ':jobId',
            props: (route) => ({
              jobId: route.params.jobId,
            }),
          },
        ],
        component: JobsTable,
        meta: {
          brandRedirect: 'brand.careers',
          isLocationPage: true,
        },
        name: 'location.careers.jobs',
        path: 'jobs',
      },
    ],
    component: CareersView,
    meta: {
      isLocationPage: true,
      title: 'restaurant.careers',
    },
    name: 'location.careers',
    path: 'careers',
    props: ({ name }) => ({
      selectedView:
        name && typeof name === 'string' && name.includes('application')
          ? 'applications'
          : 'jobs',
    }),
    redirect: { name: 'location.careers.applications' },
  },
]
