/**
 * Focuses an input
 * @param {object} vueElementRef element ref for element to focus
 */
export function focusInput(
  // Using a minimal version of `ComponentPublicInstance` to avoid "Type instantiation is excessively deep and possibly infinite"
  vueElementRef: {
    $el: { children: HTMLCollection }
  } | null
): void {
  if (vueElementRef) {
    const nativeInputHtml = vueElementRef.$el.children.namedItem(
      'native-input'
    ) as HTMLInputElement | null

    if (nativeInputHtml) {
      nativeInputHtml.focus()
    }
  }
}
