<i18n locale="en">
{
  "defaultText": "Submit"
}
</i18n>

<template>
  <PreviewSheet class="form-footer-preview" border="primary" radius="0">
    <PreviewButtonLegacy class="form-footer-preview__button" size="small">
      <PreviewTypographyLegacy
        :text="buttonText"
        color="white"
        variant="buttonRegular"
      />
    </PreviewButtonLegacy>
  </PreviewSheet>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { mapGetters } from 'vuex'

import {
  PreviewTypographyLegacy,
  PreviewButtonLegacy,
  PreviewSheet,
} from '@/lib/builder'

export default {
  name: 'FormFooterPreview',
  components: {
    PreviewButtonLegacy,
    PreviewSheet,
    PreviewTypographyLegacy,
  },
  props: {
    /** Override data for out of module rendering contexts */
    populatedData: { type: Object, default: undefined },
  },
  setup() {
    const { t } = useI18n()

    return { t }
  },
  computed: {
    ...mapGetters({
      currentResource: 'formBuilder/currentResource',
    }),
    activeResource() {
      const { populatedData, currentResource } = this

      return populatedData ?? currentResource
    },
    buttonText() {
      const { activeResource } = this

      return activeResource?.submit ?? this.t('defaultText')
    },
  },
}
</script>

<style lang="scss" scoped>
.form-footer-preview {
  padding: 24px 40px 0;

  &__button {
    width: 100%;
  }
}
</style>
