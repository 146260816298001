<template>
  <component :is="layout" :id="content.id" />
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import type { LandingHeroSection } from '../../../WebsiteBuilder.types'

import LandingHeroLayoutCard from './LandingHeroLayoutCard.vue'
import LandingHeroLayoutCover from './LandingHeroLayoutCover.vue'
import LandingHeroLayoutCoverCenter from './LandingHeroLayoutCoverCenter.vue'

const LAYOUT_MAP = {
  card: LandingHeroLayoutCard,
  cover: LandingHeroLayoutCover,
  'cover-center': LandingHeroLayoutCoverCenter,
}

const props = defineProps<{
  content: LandingHeroSection
}>()

const currentBlockParams = computed(() => props.content?.params)

const layout = computed(() => {
  return LAYOUT_MAP[currentBlockParams.value.layout]
})
</script>
