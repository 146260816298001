import { computeMergedTheme, type MergedTheme } from '@owner/theme'

import type { CoreState } from '../../types'

export const getActiveMergedTheme = (
  state: CoreState
): MergedTheme | undefined => {
  if (state.activeBrand && state.theme?.brandId === state.activeBrand) {
    return computeMergedTheme(state.theme)
  }

  return undefined
}
