import {
  type BlockCore,
  type BuilderFetchResult,
  type BuilderState,
  type ResourceCore,
} from '@/lib/builder'
import { removeObserver } from '@/utils/helpers'

export const initializeDataMutation = <
  TResource extends ResourceCore,
  TBlock extends BlockCore,
>(
  state: BuilderState<TResource, TBlock>,
  payload: BuilderFetchResult<TResource, TBlock>
): void => {
  state._builder_originalState = removeObserver(payload)
  state._builder_currentState = removeObserver(payload)
}
