<i18n locale="en">
{
  "placeholder": "Aa"
}
</i18n>

<template>
  <PreviewSheet class="text-input-preview" border="primary" radius="0">
    <div class="flex-col gap-2">
      <div class="flex-row">
        <PreviewTypographyLegacy :text="title" variant="titleSmall" />
        <PreviewTypographyLegacy
          v-if="required"
          text="*"
          variant="titleSmall"
          color="danger"
        />
      </div>
      <PreviewTextField
        :class="[multiline && 'text-input-preview__textarea']"
        :placeholder="placeholder"
      />
    </div>
  </PreviewSheet>
</template>

<script>
import { useI18n } from 'vue-i18n'

import {
  PreviewSheet,
  PreviewTypographyLegacy,
  PreviewTextField,
} from '@/lib/builder'

export default {
  name: 'TextInputPreview',
  components: {
    PreviewSheet,
    PreviewTextField,
    PreviewTypographyLegacy,
  },
  props: {
    content: { type: Object, default: undefined },

    /**
     * Override data for out of module rendering contexts
     */
    populatedData: { type: Object, default: undefined },
  },
  setup() {
    const { t } = useI18n()

    return { t }
  },
  computed: {
    currentBlock() {
      const { content, populatedData } = this

      return populatedData ?? content
    },
    multiline() {
      const { currentBlock } = this

      return currentBlock?.params?.variant === 'multiline'
    },
    placeholder() {
      const { currentBlock } = this

      return currentBlock?.params?.placeholder || this.t('placeholder')
    },
    required() {
      const { currentBlock } = this

      return currentBlock?.params?.required
    },
    title() {
      const { currentBlock } = this

      return currentBlock?.params?.label ?? ''
    },
  },
}
</script>

<style lang="scss" scoped>
.text-input-preview {
  padding: 12px 40px;

  &__textarea {
    padding-bottom: 64px;
  }
}
</style>
