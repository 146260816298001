<i18n locale="en">
{
  "labels": {
    "description": "This will make this page available for customers to visit on your website. You need to set the URL where people will find your page."
  },
  "errors": {
    "page-url-exists": "Page URL already exists"
  },
  "title": {
    "label": "Page Title"
  },
  "slug": {
    "label": "Page URL",
    "input-prefix": "https://{domain}/page/"
  }
}
</i18n>

<template>
  <div class="flex-col gap-6">
    <OwnType :text="t('labels.description')" color="primary" />

    <OwnInputContainer :label="t('title.label')" :errors="title.status.errors">
      <OwnInput v-model="title.value" />
    </OwnInputContainer>

    <OwnInputContainer :label="t('slug.label')" :errors="slug.status.errors">
      <OwnInput
        v-model="slug.value"
        :start-text="urlPrefix"
        @update:model-value="hasManuallyEditedSlug = true"
      />
    </OwnInputContainer>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import { useI18n } from 'vue-i18n'
import { mapGetters } from 'vuex'

import { ConfiguredClient } from '@/api'
import { UrlSafeSlugFormatter } from '@/format/lib/UrlSafeSlugFormatter'
import { FormBuilderModelSync, Validators } from '@/forms'
import { OwnInput, OwnInputContainer, OwnType } from '@/ui'

export default {
  name: 'CreateTemplateForm',
  components: { OwnInput, OwnInputContainer, OwnType },
  mixins: [
    FormBuilderModelSync({
      slug: {
        validators: [Validators.required, Validators.urlSafeSlug],
      },
      title: {
        validators: [Validators.required],
      },
    }),
  ],
  emits: ['slugExists'],
  setup() {
    const { t } = useI18n()

    return { t }
  },
  data() {
    return {
      hasManuallyEditedSlug: false,
      isFetchingPageDetails: false,
    }
  },
  computed: {
    ...mapGetters({
      activeBrandDomain: 'core/brand/active/domain',
      activeBrandId: 'core/brand/active/id',
    }),
    urlPrefix() {
      const { activeBrandDomain } = this

      if (!activeBrandDomain) {
        return this.t('slug.input-prefix', {
          domain: `ordersave.com/...`,
        })
      } else {
        return this.t('slug.input-prefix', {
          domain: activeBrandDomain.length > 25 ? '...' : activeBrandDomain,
        })
      }
    },
  },
  watch: {
    'title.value'(newValue) {
      const { hasManuallyEditedSlug } = this

      if (!newValue) {
        this.hasManuallyEditedSlug = false
        this.slug.value = newValue
        return
      }

      // Don't change anything if the user manually edited the slug themselves
      if (hasManuallyEditedSlug) return

      this.slug.value = UrlSafeSlugFormatter(newValue)
    },
  },
  methods: {
    async checkIsSlugFree(slug) {
      const { activeBrandId } = this

      try {
        this.isFetchingPageDetails = true

        const result =
          await ConfiguredClient.funnel.v1.brands.brand.getPageByPath({
            params: {
              brandId: activeBrandId,
            },
            query: {
              page: slug,
              type: 'custom',
            },
          })

        if (result) {
          this.slug.status = {
            errors: [this.t('errors.page-url-exists')],
            valid: false,
          }
          this.$emit('slugExists', true)
        }
      } catch (error) {
        this.$emit('slugExists', false)
        // Page not found.  Valid state
      } finally {
        this.isFetchingPageDetails = false
      }
    },
    isSlugFree: debounce(function (slug) {
      this.checkIsSlugFree(slug)
    }, 500),
    onFormControlUpdate(controlName, newValue, status) {
      if (controlName === 'slug' && status.valid) this.isSlugFree(newValue)
    },
  },
}
</script>
