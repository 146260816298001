<template>
  <OwnDropdown
    v-model="selectedState"
    :filter-keys="['label']"
    :options="stateOptions"
  />
</template>

<script lang="ts" setup>
import { OwnDropdown } from '@/ui'

import { US_STATES } from './US_STATES'

export type UsState = (typeof US_STATES)[number]

const selectedState = defineModel<UsState>()

const stateOptions = US_STATES.map((value) => ({ label: value, value }))
</script>
