import linkifyString from 'linkify-string'
import { type Opts } from 'linkifyjs'

/**
 *  Convert URLs to anchor tags in a string. Escapes any HTML entities to avoid risk of security vulnerabilities from
 *  user input.
 * @param value Value to process
 * @param options Options to pass through to `linkifyjs`. Can be used to override default options.
 */
export function linkify(value: string, options: Opts = {}): string {
  return linkifyString(value, {
    // For links without a protocol, use `https` rather than the default `http`
    defaultProtocol: 'https',
    // Open links in a new tab
    target: '_blank',
    ...options,
  })
}
