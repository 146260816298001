import { PbError } from '@owner/errors'

class InternalServerError extends PbError {
  constructor(args) {
    super(args.message, args)
    this.name = 'InternalServerError'
    this.code = args.code
    this.message = args.message
    this.status = args.status
  }
}

export { InternalServerError }
