import { createClient } from '@owner/olympus-client'

import globalStore from '@/store'

const VITE_OLYMPUS_URL = import.meta.env.VITE_OLYMPUS_URL

export const ConfiguredClient = createClient(VITE_OLYMPUS_URL, async () => ({
  audience: 'hermes',
  client: 'web',
  csrfToken: globalStore.getters['session/csrfToken'],
}))

export interface PaginatedResult<TResource> {
  limit: number
  page: number
  pageCount: number
  results: TResource[]
  total: number
}
