<template>
  <Responsive v-slot="{ width }">
    <!--  Loading  -->
    <OwnOption v-if="$slots['loading']" el="div">
      <slot name="loading" />
    </OwnOption>

    <!--  No Data  -->
    <OwnOption v-else-if="hasNoItems && !isSearching" el="div">
      <slot name="no-data" />
    </OwnOption>

    <!--  Render as table  -->
    <OwnTableTableRenderer
      v-else-if="width > props.cardBreakpoint"
      :border="border"
      :items="props.items ?? modelVal"
      :item-key="itemKey"
      :has-click="hasClickListener"
      :reorderable="reorderable"
      :columns="props.columns"
      :variant="props.variant"
      @click="onClick"
      @reorder="onReorder"
    >
      <template
        v-for="column of columns"
        :key="`heading.${column.key}`"
        #[`heading.${column.key}`]
      >
        <slot :name="`heading.${column.key}`" />
      </template>

      <template
        v-for="column of columns"
        #[`item.${column.key}`]="{ item, rowIdx, isFirst }"
      >
        <slot
          :name="`item.${column.key}`"
          :item="item"
          :row-idx="rowIdx"
          :is-first="isFirst"
        />
      </template>

      <template #item="{ item, columnKey, isFirst }">
        <slot
          name="item"
          :item="item"
          :column-key="columnKey"
          :is-first="isFirst"
        />
      </template>

      <template #no-data>
        <slot name="no-data" />
      </template>

      <template #no-query-results>
        <slot name="no-query-results" />
      </template>
    </OwnTableTableRenderer>

    <!--  Render as card set  -->
    <OwnTableCardRenderer
      v-else
      :has-click="hasClickListener"
      :reorderable="reorderable"
      :items="props.items ?? modelVal"
      :item-key="itemKey"
      @click="onClick"
    >
      <template #card="{ item, rowIdx }">
        <slot name="card" :item="item" :row-idx="rowIdx" />
      </template>
    </OwnTableCardRenderer>
  </Responsive>
</template>

<script lang="ts" setup generic="T extends Record<string, unknown>">
import { isDefined } from '@vueuse/core'
import cloneDeep from 'lodash/cloneDeep'
import { computed } from 'vue'

import { reorder, ReorderEvent } from '@/utils/helpers/reorder'

import { OwnOption } from '../../OwnOption'
import Responsive from '../../Responsive.vue'
import { OwnTableColumnConfig, OwnTableVariants } from '../types'

import OwnTableCardRenderer from './OwnTableCardRenderer.vue'
import OwnTableTableRenderer from './OwnTableTableRenderer.vue'

const props = withDefaults(
  defineProps<{
    border?: boolean
    cardBreakpoint?: number
    columns: OwnTableColumnConfig<T>[]
    items?: T[]
    itemKey: string
    onClick?: ((item: T) => void) | ((item: T) => Promise<void>) | undefined
    query?: string
    reorderable?: boolean
    variant?: OwnTableVariants
  }>(),
  {
    border: true,
    cardBreakpoint: 600,
    items: undefined,
    onClick: undefined,
    query: undefined,
    reorderable: false,
    variant: 'default',
  }
)

const emit = defineEmits<{
  (event: 'after-reorder', values: { before: T[]; after: T[] }): void
  (event: 'click', value: T): void
}>()

const modelVal = defineModel<T[]>({ default: [] })

const isSearching = computed(() => {
  return isDefined(props.query) && props?.query !== ''
})

const hasClickListener = computed(() => {
  return isDefined(props.onClick)
})

const hasNoItems = computed(() => {
  return !props.items?.length && !modelVal.value?.length
})

const onReorder = ({ oldIndex, newIndex }: ReorderEvent) => {
  const valueBefore = cloneDeep(modelVal.value)

  modelVal.value = reorder(modelVal.value, oldIndex, newIndex)
  emit('after-reorder', {
    after: modelVal.value,
    before: valueBefore,
  })
}
</script>
