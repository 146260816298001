import { type PlaceAddress, type ProcessedPlace, type RawPlace } from './types'

const processPlacesAddress = (
  customerAddress: RawPlace
): ProcessedPlace['address'] | null => {
  if (!customerAddress.address_components) return null

  const city = customerAddress.address_components.find(
    (component) =>
      component.types.includes('locality') &&
      component.types.includes('political')
  )

  const administrativeAreaCity = customerAddress.address_components.find(
    (component) =>
      component.types.includes('administrative_area_level_3') &&
      component.types.includes('political')
  )

  const sublocality = customerAddress.address_components.find(
    (component) =>
      component.types.includes('sublocality') &&
      component.types.includes('political')
  )

  const neighborhood = customerAddress.address_components.find(
    (component) =>
      component.types.includes('neighborhood') &&
      component.types.includes('political')
  )

  // Only political is set, can't do anything with that.
  if (city && sublocality && administrativeAreaCity && neighborhood) {
    return null
  }

  let cityComponent: PlaceAddress | undefined

  if (city) {
    cityComponent = city
  } else if (administrativeAreaCity) {
    cityComponent = administrativeAreaCity
  } else if (sublocality) {
    cityComponent = sublocality
  } else {
    cityComponent = neighborhood
  }

  const zipComponent = customerAddress.address_components.find((component) =>
    component.types.includes('postal_code')
  )

  const stateComponent = customerAddress.address_components.find(
    (component) =>
      component.types.includes('administrative_area_level_1') &&
      component.types.includes('political')
  )

  const streetNameComponent = customerAddress.address_components.find(
    (component) => component.types.includes('route')
  )

  const streetNumberComponent = customerAddress.address_components.find(
    (component) => component.types.includes('street_number')
  )

  const unitNumberComponent = customerAddress.address_components.find(
    (component) => component.types.includes('subpremise')
  )

  if (
    !cityComponent ||
    !zipComponent ||
    !stateComponent ||
    !streetNameComponent ||
    !streetNumberComponent ||
    !customerAddress.formatted_address
  ) {
    return null
  }

  return {
    city: cityComponent.long_name,
    full: customerAddress.formatted_address,
    state: stateComponent.long_name,
    street: `${streetNumberComponent.long_name} ${streetNameComponent.long_name}`,
    unit: unitNumberComponent?.long_name ?? null,
    zip: zipComponent.long_name,
  }
}

export const normalizeGooglePlace = (
  place: RawPlace
): ProcessedPlace | null => {
  const placeId = place.place_id
  const addressComponents = processPlacesAddress(place)
  const placeTitle = place.name

  if (!addressComponents) return null

  return {
    address: addressComponents,
    id: placeId,
    title: placeTitle,
  }
}
