import { type RouteRecordRaw } from 'vue-router'

import { applyMetaToChildren } from '@/utils/router'

export const toolsRouter: RouteRecordRaw[] = [
  {
    children: applyMetaToChildren(
      [
        {
          component: async () => import('../views/SmsSubscriberToolView.vue'),
          meta: {
            title: 'tools.sms-subscriber-import',
          },
          name: 'brand.tools.sms-subscriber-import',
          path: 'sms-subscriber-import',
        },
        {
          component: async () => import('../views/DomainToolView.vue'),
          meta: {
            title: 'tools.dns',
          },
          name: 'brand.tools.dns',
          path: 'dns',
        },
        {
          component: async () => import('../views/CloneMenuView.vue'),
          meta: {
            title: 'tools.clone-menu',
          },
          name: 'brand.tools.clone-menu',
          path: 'clone-menu',
        },
        {
          component: async () => import('../views/CreateHotLaunchToolView.vue'),
          meta: {
            title: 'tools.hot-launch',
          },
          name: 'brand.tools.hot-launch',
          path: 'hot-launch',
        },
        {
          component: async () => import('../views/OnboardingToolView.vue'),
          meta: {
            title: 'tools.onboarding',
          },
          name: 'brand.tools.onboarding',
          path: 'onboarding',
        },
      ],
      {
        roles: ['pb:*'],
      }
    ),
    component: {
      name: 'OnboardingToolView',
      // eslint-disable-next-line xss/no-mixed-html -- Avoids a pointless wrapper component
      template: '<router-view />',
    },
    path: 'tools',
    redirect: { name: 'brand.tools.sms-suscriber-import' },
  },
]
