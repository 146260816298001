import type { CoreState } from '../../types'

export const getLocationsUnderCollections = (
  state: CoreState
): Array<{
  id: string
  name: string | undefined
}> => {
  return state.locations
    .filter((location) => location.paymentPastDue)
    .map((location) => ({
      id: location.id,
      name: location.name,
    }))
}
