import { type BuilderModuleConfig, useBuilderComponents } from '@/lib/builder'
import i18nPlugin from '@/plugins/i18n'
import { locationAnalytics } from '@/utils'

import CampaignStatsRightAction from '../../../components/otc/CampaignStatsRightAction.vue'
import OTCBuilderRightActions from '../../../components/otc/OTCBuilderRightActions.vue'
import { EmailBuilderBlocks } from '../blocks'
import AIEmailPlaceholder from '../components/AIEmailPlaceholder.vue'
import CreateEmailDialog from '../components/create-email-dialog/CreateEmailDialog.vue'
import NewCampaignRightActions from '../components/NewCampaignRightActions.vue'
import EmailBuilderPreviewWrapper from '../components/preview/EmailBuilderPreviewWrapper.vue'
import { EmailFooterStaticModule } from '../components/preview/footer'
import { EmailHeadingStaticModule } from '../components/preview/heading'
import PublishDialog from '../components/PublishDialog.vue'
import TemplateSidebar from '../components/template/TemplateSidebar.vue'
import ViewCampaignSidebar from '../components/ViewCampaignSidebar.vue'
import { methods } from '../methods/index'
import emailBuilderTemplates from '../templates'
import {
  type CampaignResource,
  type EmailSection,
  type CampaignResourceCreate,
  type CampaignResourceUpdate,
  type EmailSectionCreate,
  type EmailSectionUpdate,
} from '../types'
const i18n = i18nPlugin.global

export const emailBuilderConfig: BuilderModuleConfig<
  CampaignResource,
  CampaignResourceCreate,
  CampaignResourceUpdate,
  EmailSection,
  EmailSectionCreate,
  EmailSectionUpdate
> = {
  blocks: EmailBuilderBlocks,
  components: {
    preview: {
      footer: EmailFooterStaticModule,
      heading: EmailHeadingStaticModule,
      wrapper: EmailBuilderPreviewWrapper,
    },
    publishDialog: PublishDialog,
    rightActions: OTCBuilderRightActions,
  },
  customPages: [
    {
      analyticsEvents: [],
      components: {
        rightActions: CampaignStatsRightAction,
        sidebar: ViewCampaignSidebar,
      },
      perResource: true,
      slug: 'view',
    },
  ],
  methods,
  options: {
    analyticsEvents: [
      {
        handler: (_resource, brand) => ({
          ...locationAnalytics(brand),
        }),
        label: 'Email Edit Started',
        type: 'start',
      },
      {
        handler: (resource, brand) => ({
          ...locationAnalytics(brand),
          id: resource.id,
        }),
        label: 'Email Edit Successful',
        type: 'update',
      },
      {
        handler: (resource, brand) => ({
          ...locationAnalytics(brand),
          id: resource.id,
        }),
        label: 'Email Edit Failed',
        type: 'failed',
      },
      {
        handler: (resource, brand) => {
          return {
            ...locationAnalytics(brand),
            id: resource.id,
          }
        },
        label: 'New Email Successful',
        type: 'create',
      },
    ],
    createAnalyticsEvents: [
      {
        handler: (brand) => ({
          ...locationAnalytics(brand),
        }),
        label: 'Email Creation Started',
        type: 'start',
      },
      {
        handler: (brand, resource, type) => {
          return {
            ...locationAnalytics(brand),
            id: resource?.id,
            type: type ? 'manual' : 'generated',
            ...(type
              ? {
                  manualTemplate: type,
                }
              : {}),
          }
        },
        label: 'Email Template Selected',
        type: 'template-select',
      },
      {
        handler: (brand) => ({
          ...locationAnalytics(brand),
        }),
        label: 'Email Creation Failed',
        type: 'failed',
      },
    ],
    home: {
      name: 'brand.marketing.otc.overview',
    },
    previewBackgroundColor: 'primary',
    resourceName: 'emails',
    resourceTitle: {
      key: 'name',
      placeholder: i18n.t('email-builder.resource-title.placeholder'),
    },
    root: 'brand.marketing',
    scaling: {
      initialScale: 1,
      previewBuildSize: 600,
      scaleMap: [
        [600, 1],
        [300, 0.5],
      ],
    },
    storeModule: 'emailBuilder',
    templates: {
      ...emailBuilderTemplates,
      components: {
        dialog: CreateEmailDialog,
        emptyPreview: AIEmailPlaceholder,
        rightActions: NewCampaignRightActions,
        sidebar: TemplateSidebar,
      },
    },
  },
}

export const emailBuilderComponents = useBuilderComponents(emailBuilderConfig)
