import { formatMinorAmount } from '@owner/currency'

const formatNumberValue = (
  value: number,
  format: 'percentage' | 'currency' | unknown
): string => {
  switch (format) {
    case 'percentage':
      return `${value}%`
    case 'currency':
      return formatMinorAmount(value)
    default:
      return value.toLocaleString()
  }
}

export default formatNumberValue
