import { createRouter, createWebHistory } from 'vue-router'

import guards from './guards'
import routes from './routes'

export const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: 'is-active',
  routes,
})

router.beforeEach(guards)
