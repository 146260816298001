import { type OwnTableVariants, type TableOptions } from '../types'

export const getTableOptions = (variant: OwnTableVariants): TableOptions => {
  if (variant === 'default') {
    return {
      body: {
        dividers: true,
        spacing: 4,
      },
      row: {
        spacing: 8,
      },
    }
  } else {
    return {
      body: {
        dividers: false,
        padding: 8,
      },
      row: {
        padding: 8,
        radius: 8,
        spacing: 4,
      },
    }
  }
}
