<template>
  <PreviewSheet
    class="landing-hero-background__image"
    :style="style"
    radius="medium"
  ></PreviewSheet>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { PreviewSheet } from '@/lib/builder'

const props = withDefaults(
  defineProps<{
    carouselAssets?: { image: string }[]
    /**
     * Show Overlay
     */
    overlay?: boolean
  }>(),
  { carouselAssets: () => [], overlay: false }
)

const imageUrl = computed(() => props.carouselAssets[0]?.image)

const backgroundImage = computed(() => {
  if (imageUrl.value) {
    return props.overlay
      ? `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${imageUrl.value})`
      : `url(${imageUrl.value})`
  } else {
    return undefined
  }
})

const style = computed(() => ({
  backgroundImage: backgroundImage.value,
}))
</script>

<style lang="scss" scoped>
.landing-hero-background {
  &__image {
    height: 100%;
    width: 100%;

    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
</style>
