<i18n locale="en">
{
  "labels": {
    "order": "Order Now"
  }
}
</i18n>

<template>
  <PreviewSheet background="primary" radius="0" class="py-3 pl-6">
    <div class="w-full px-3 py-4">
      <PreviewButton color="primary" size="small" :title="t('labels.order')" />
    </div>
  </PreviewSheet>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'

import { PreviewSheet, PreviewButton } from '@/lib/builder'

const { t } = useI18n()
</script>
