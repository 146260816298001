/**
 * Trigger a download of a file in the user's browser.
 * @param url URL to download
 * @param filename Name to use for downloaded file
 */
export function download(url: string, filename?: string): void {
  const link = document.createElement('a')
  link.setAttribute('href', url)
  link.setAttribute('download', filename ?? '')
  link.setAttribute('target', '_blank')
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
