import type { CoreGetters } from '../../types'

/**
 * Determine if a brand is a single-location brand or not.
 * @param _state Core state
 * @param getters Core getters
 * @returns `true` when single-location, `false` when multi-location, `undefined` when active brand is not set or brand
 * has no locations
 */
export function isSingleLocation(
  _state: unknown,
  getters: CoreGetters
): boolean | undefined {
  const locations = getters['brand/active/locations']

  return locations?.length ? locations.length === 1 : undefined
}
