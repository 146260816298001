import defaultState from './state'

export default {
  ADD_SETTINGS(state, { settings }) {
    state.settings = {
      ...state.settings,
      ...settings,
    }
  },
  SANITIZE_SETTINGS(state) {
    state.settings = defaultState.settings
  },
  UPDATE_SETTINGS(state, { updatedSettings, locationId }) {
    const thisLocationSettings = state.settings[locationId]

    if (thisLocationSettings) {
      const newSettings = {
        ...thisLocationSettings,
        ...updatedSettings,
      }

      state.settings.locationId = newSettings
    }
  },
}
