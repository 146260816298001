import { computeMergedTheme } from '@owner/theme'
import get from 'lodash/get'
import { mapGetters } from 'vuex'

import globalStore from '@/store'

export const useMergedTheme = {
  computed: {
    ...mapGetters({
      coreMergedTheme: 'core/brand/active/mergedTheme',
    }),
    mergedTheme() {
      const { brandThemePath, coreMergedTheme, storeModule } = this

      if (brandThemePath) {
        const localTheme = get(
          globalStore.getters[`${storeModule}/currentResource`],
          brandThemePath
        )

        if (localTheme) {
          return computeMergedTheme(localTheme)
        }

        return coreMergedTheme
      } else {
        return coreMergedTheme
      }
    },
  },
  inject: ['brandThemePath', 'storeModule'],
}
