<template>
  <component :is="previewComponent" :id="id" :populated-data="section" />
</template>
<script>
import { FormBuilderBlocks } from '../../../builder/blocks'

const previewLookup = Object.fromEntries(
  FormBuilderBlocks.flatMap((module) =>
    module.meta.map((moduleMeta) => [moduleMeta.key, module.preview])
  )
)

export default {
  name: 'SectionPreview',
  props: {
    /** Form section */
    section: { type: Object, required: true },
  },
  computed: {
    id() {
      const {
        section: { id },
      } = this

      return id
    },
    previewComponent() {
      const {
        section: { type },
      } = this

      return previewLookup[type]
    },
  },
}
</script>
