import type { ActionContext } from 'vuex'

import { saveToRecentBrands } from '@/core'
import type { CoreState } from '@/core/store/types'

export const setActiveBrand = async (
  { commit, getters }: ActionContext<CoreState, unknown>,
  brandId: string
): Promise<void> => {
  if (brandId) {
    const brand = getters['brand/byId'](brandId)

    if (brand) saveToRecentBrands(brand)
  }

  commit('SET_ACTIVE_BRAND', brandId)
}
