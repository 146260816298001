<template>
  <OwnInput
    :model-value="displayPhoneNumber"
    :accessibility-id="accessibilityId"
    :error="error"
    :placeholder="placeholder"
    @update:model-value="setDisplayPhoneNumber"
  />
</template>
<script lang="ts" setup>
import { AsYouType, parsePhoneNumber } from 'libphonenumber-js'
import { onMounted, ref, watch } from 'vue'

import { OwnInput } from '@/ui'

withDefaults(
  defineProps<{
    accessibilityId?: string
    error?: boolean
    placeholder?: string
  }>(),
  {
    accessibilityId: undefined,
    error: false,
    placeholder: undefined,
  }
)

const displayPhoneNumber = ref('')

const modelPhoneNumber = defineModel<string>({ required: true })

const setModelPhone = (newPhoneNumber: string) => {
  if (newPhoneNumber !== '') {
    // parsePhoneNumber throws a TOO_SHORT error if there is only one
    // character.  This allows us to handle that gracefully
    try {
      const phone = parsePhoneNumber(newPhoneNumber, 'US')
      modelPhoneNumber.value = phone.number
    } catch (err) {
      modelPhoneNumber.value = `+1${newPhoneNumber}`
    }
  } else {
    modelPhoneNumber.value = ''
  }
}

const setDisplayPhoneNumber = (newPhoneNumber: string) => {
  if (newPhoneNumber) {
    let phoneToFormat = newPhoneNumber

    if (newPhoneNumber.startsWith('+1')) {
      phoneToFormat = newPhoneNumber.slice(2)
    }

    displayPhoneNumber.value = new AsYouType('US').input(phoneToFormat)
  } else {
    displayPhoneNumber.value = ''
  }
}

watch(displayPhoneNumber, (newPhone) => {
  setModelPhone(newPhone)
})

watch(modelPhoneNumber, () => {
  setDisplayPhoneNumber(modelPhoneNumber.value)
})

onMounted(() => {
  setDisplayPhoneNumber(modelPhoneNumber.value)
})
</script>
