<i18n locale="en">
{
  "title": "Upcoming events near you",
  "actions": {
    "see-more": "See More Items"
  },
  "empty": {
    "header": "There are no events right now",
    "sub": "Check back later to see if we’ve added any"
  }
}
</i18n>

<template>
  <PreviewSheet border="primary" radius="0">
    <div class="event-calendar-preview flex-col">
      <div
        v-if="currentResource?.path?.params?.page !== 'events'"
        class="flex-row justify-between align-end"
      >
        <div class="flex-col gap-8">
          <PreviewDecorationLine />
          <PreviewTypographyLegacy :text="t('title')" variant="heading2" />
        </div>
      </div>
      <div
        v-if="events.length > 0"
        class="event-calendar-preview__items flex-row gap-6"
      >
        <EventCardPreview
          v-for="event in events"
          :key="event.id"
          :event="event"
        />
      </div>
      <PreviewSheet
        v-else
        background="secondary"
        class="event-calendar-preview__empty"
      >
        <div class="h-full flex-col gap-2 align-center justify-center">
          <PreviewTypographyLegacy
            :text="t('empty.header')"
            variant="heading4"
          />
          <PreviewTypographyLegacy
            :text="t('empty.sub')"
            variant="paragraphRegular"
          />
        </div>
      </PreviewSheet>
    </div>
  </PreviewSheet>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { mapGetters } from 'vuex'

import { ConfiguredClient } from '@/api'
import {
  PreviewDecorationLine,
  PreviewSheet,
  PreviewTypographyLegacy,
} from '@/lib/builder'
import { logError } from '@/logger'

import EventCardPreview from './EventCardPreview.vue'

export default {
  name: 'EventCalendarPreviewLegacy',
  components: {
    EventCardPreview,
    PreviewDecorationLine,
    PreviewSheet,
    PreviewTypographyLegacy,
  },
  props: {
    /** Section */
    id: { type: String, required: true },
  },
  setup() {
    const { t } = useI18n()

    return { t }
  },
  data() {
    return {
      events: [],
    }
  },
  computed: {
    ...mapGetters({
      activeBrandId: 'core/brand/active/id',
      currentResource: 'websiteBuilder/currentResource',
      locations: 'core/brand/active/locations',
    }),
  },
  created() {
    this.loadEvents()
  },
  methods: {
    async loadEvents() {
      const { activeBrandId } = this
      try {
        const response = await ConfiguredClient.events.v1.getEvents({
          query: {
            brandId: activeBrandId,
            limit: 3,
            page: 1,
          },
        })

        this.events = response.results
      } catch (error) {
        logError(error)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.event-calendar-preview {
  padding: 32px 80px;
  gap: 40px;

  &__items {
    overflow: hidden;
  }

  &__empty {
    height: 384px;
  }
}
</style>
