export default {
  /**
   * Returns the current locale string
   * @param {object} state Vuex state
   * @returns {string} Current locale
   */
  locale: (state) => state.locale,

  /**
   * Returns the current scope of the application
   * @param {object} state Vuex state
   * @param {object} getters Vuex getters
   * @param {object} rootState Vuex rootState
   * @param {object} rootGetters Vuex rootGetters
   * @returns {string} 'location' or 'brand'
   */
  scope: (state, getters, rootState, rootGetters) =>
    rootGetters['core/location/active/id']
      ? 'location'
      : rootGetters['core/brand/active/id']
        ? 'brand'
        : 'super',
}
