import { type BrandTheme } from '@owner/theme'

import type { CoreState } from '../../types'

export const getActiveBrandTheme = (
  state: CoreState
): BrandTheme | undefined => {
  if (state.activeBrand && state.theme?.brandId === state.activeBrand) {
    return state.theme
  }

  return undefined
}
