import { formatPhoneNumber } from '@/utils/format/phone'

import { type FormatFn } from './Format.types'

export const PhoneFormatter: FormatFn<string> = (value) => {
  // Case for when we are trying to format phone numbers that already have the +1
  if (value.startsWith('+1')) {
    return formatPhoneNumber(value.slice(2))
  }
  return formatPhoneNumber(value)
}
