import { type ActionContext } from 'vuex'

import { type CoreState } from '@/core/store/types'

export const clearAllBrands = async ({
  commit,
  dispatch,
}: ActionContext<CoreState, unknown>): Promise<void> => {
  await dispatch('location/setActive', undefined)
  await dispatch('locations/setAll', [])

  commit('SET_BRANDS', [])
  commit('SET_THEME', undefined)
}
