import { ConfiguredClient } from '@/api'

import { type EmailSection, type EmailSectionUpdate } from '../../types'

export const updateBlockHandler = async <
  TSection extends EmailSection,
  TSectionUpdate extends EmailSectionUpdate,
>(
  id: string,
  update: TSectionUpdate
): Promise<TSection> => {
  return (await ConfiguredClient.notifications.v1.templates.emails.sections.section.updateEmailSection(
    {
      body: update,
      params: { sectionId: id },
    }
  )) as TSection
}
