<i18n locale="en">
{
  "titles" : {
    "add": "Add Action",
    "update": "Update Action"
  },
  "actions": {
    "update": "Update",
    "add": "Add"
  },
  "options": {
    "locations": "Locations",
    "profile": "Profile",
    "loyalty": "Loyalty",
    "orders": "Orders"
  }
}
</i18n>

<template>
  <OwnForm :submit-handler="onUpdate">
    <OwnDialog
      :model-value="true"
      class="add-link-dialog"
      @cancel="closeAndReset"
    >
      <template #heading>
        <OwnDialogHeading
          :title="editItem ? t('titles.update') : t('titles.add')"
          @cancel="closeAndReset"
        />
      </template>

      <OwnDialogContent scroll>
        <OwnTouchRadioGroup
          v-model="selectedOption"
          :options="options"
          :columns="2"
        />
      </OwnDialogContent>

      <template #footer>
        <OwnDialogFooter>
          <OwnButton
            :text="editItem ? t('actions.update') : t('actions.add')"
            primary
            type="submit"
          >
            <template #icon-right>
              <PhPlusCircle v-if="!editItem" size="24" />
            </template>
          </OwnButton>
        </OwnDialogFooter>
      </template>
    </OwnDialog>
  </OwnForm>
</template>

<script>
import { PhPlusCircle } from '@phosphor-icons/vue'
import { useI18n } from 'vue-i18n'

import {
  OwnButton,
  OwnDialog,
  OwnDialogContent,
  OwnDialogFooter,
  OwnDialogHeading,
  OwnForm,
  OwnTouchRadioGroup,
} from '@/ui'

const options = ['profile', 'loyalty', 'orders']

export default {
  name: 'AddActionDialog',
  components: {
    OwnButton,
    OwnDialog,
    OwnDialogContent,
    OwnDialogFooter,
    OwnDialogHeading,
    OwnForm,
    OwnTouchRadioGroup,
    PhPlusCircle,
  },
  props: {
    editItem: { type: Object, default: undefined },
  },
  emits: ['close', 'update'],
  setup() {
    const { t } = useI18n()

    return { t }
  },
  data() {
    return {
      options: options.map((value) => ({
        label: this.t(`options.${value}`),
        value,
      })),
      selectedOption: undefined,
    }
  },
  created() {
    const { editItem } = this

    if (editItem) {
      this.selectedOption = editItem.params.action
    } else {
      this.selectedOption = options[0]
    }
  },
  methods: {
    closeAndReset() {
      this.$emit('close')
    },
    onUpdate() {
      const { selectedOption } = this

      this.$emit('update', {
        params: {
          action: selectedOption,
          label: this.t(`options.${selectedOption}`),
        },
        type: 'action',
      })

      this.closeAndReset()
    },
  },
}
</script>
