<template>
  <div class="pill-selector flex-row gap-2">
    <div
      v-for="(location, index) in activeLocations"
      :key="location.id"
      class="pill-selector__button"
    >
      <PreviewSheet
        class="pill-selector__button-sheet"
        :background="index === 0 ? 'transparent' : 'secondary'"
        :border="index === 0 ? 'secondary' : undefined"
      >
        <PreviewTypography
          class="pill-selector__button-text"
          variant="paragraphRegular"
          :color="index === 0 ? 'secondary' : 'placeholder'"
          :text="location.name"
        />
      </PreviewSheet>
    </div>
    <div class="pill-selector__gradient" :style="gradientStyle"></div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { Location } from '@/core'
import {
  PreviewSheet,
  PreviewTypography,
  useMergedThemeComp,
} from '@/lib/builder'
import { useMappedGetter } from '@/store'

const activeLocations = useMappedGetter<Location[]>(
  'core/brand/active/locations'
)

const theme = useMergedThemeComp()

const gradientStyle = computed(() => ({
  background: `linear-gradient(270deg, ${theme.value?.colors.background.primary} 0%, rgba(255, 255, 255, 0) 100%)`,
}))
</script>

<style lang="scss" scoped>
.pill-selector {
  overflow: hidden;
  position: relative;

  &__button-sheet {
    padding: 12px 16px;
    width: 100%;
    height: 100%;
    border-radius: 23px !important;
  }

  &__button-text {
    white-space: nowrap;
  }

  &__gradient {
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 200px;
    background: linear-gradient(270deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
  }
}
</style>
