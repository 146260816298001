<template>
  <div class="own-tab-controls flex-row">
    <button
      v-for="option of options"
      :key="option.value"
      class="own-tab-controls__item text-button"
      :class="[selected === option.value ? 'bg-white slate-600' : 'slate-500']"
      @click="selectNewTab(option.value)"
      v-text="option.label"
    ></button>
  </div>
</template>
<script>
/**
 * @deprecated use OwnSegmentedControl
 */
export default {
  name: 'OwnTabControls',
  props: {
    /**
     * @model
     */
    modelValue: {
      type: String,
      default: undefined,
    },

    /**
     * Button Options
     */
    options: {
      type: Array,
      required: true,
    },
  },
  emits: ['update:modelValue', 'select'],
  computed: {
    selected: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      },
    },
  },
  methods: {
    selectNewTab(tabValue) {
      this.selected = tabValue
      this.$emit('select', tabValue)
    },
  },
}
</script>
<style lang="scss">
.own-tab-controls {
  border-radius: 4px;
  padding: 2px;
  background-color: $slate-100;
  border: 1px solid $slate-200;

  &__item {
    margin-right: 2px;
    flex: 1 1 0px;
    border-radius: 2px;
    padding: 2px 8px;

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
