<template>
  <OwnInput v-model="selectedDate" type="date" :min="min" />
</template>

<script lang="ts" setup>
import { formatISO } from 'date-fns'
import { computed } from 'vue'

import { OwnInput } from '@/ui'

const props = withDefaults(
  defineProps<{
    /**
     * Disable past dates in the calendar picker. Does not prevent past dates from being entered manually.
     * @default false
     */
    future?: boolean
  }>(),
  { future: false }
)

const selectedDate = defineModel<string>()

const min = computed<string | undefined>(() => {
  if (props.future) {
    return formatISO(new Date(), { representation: 'date' })
  }

  return undefined
})
</script>
