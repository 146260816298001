<template>
  <div class="flex-row gap-2">
    <DatePicker v-model="date" />
    <TimePicker v-model="time" />
  </div>
</template>
<script>
import {
  format,
  getDate,
  getHours,
  getMinutes,
  getMonth,
  getYear,
  parse,
  set,
} from 'date-fns'

import { mapModel } from '@/utils/computed'

import DatePicker from './DatePicker'
import TimePicker from './TimePicker'

export default {
  name: 'DateTimePicker',
  components: {
    DatePicker,
    TimePicker,
  },
  props: {
    modelValue: { type: Date, required: true },
  },
  emits: ['update:modelValue'],
  computed: {
    ...mapModel('datetime'),
    date: {
      get() {
        const { datetime } = this

        return format(datetime, 'yyyy-MM-dd')
      },
      set(newDateStr) {
        const { datetime } = this

        const newDate = parse(newDateStr, 'yyyy-MM-dd', datetime)

        this.datetime = set(datetime, {
          date: getDate(newDate),
          month: getMonth(newDate),
          year: getYear(newDate),
        })
      },
    },
    time: {
      get() {
        const { datetime } = this

        return format(datetime, 'h:mm a')
      },
      set(newTimeStr) {
        const { datetime } = this

        const newTime = parse(newTimeStr, 'h:mm a', datetime)

        this.datetime = set(datetime, {
          hours: getHours(newTime),
          minutes: getMinutes(newTime),
        })
      },
    },
  },
}
</script>
