import client from '../client'

/**
 * Get special hours on a location
 * @param {string} locationId Owner location id
 * @returns {object} Special hours for location
 */
export const getSpecialHours = (locationId) =>
  client.get(`/locations/${locationId}/special-hours`, { limit: 100 })

/**
 * Create a special hours entry
 * @param {object} specialHours A single special hours entry
 */
export const createSpecialHours = (specialHours) =>
  client.post(
    `/locations/${specialHours.locationId}/special-hours`,
    specialHours
  )

/**
 * Update an existing special hours entry
 * @param {string} specialHoursId Owner special hours id
 * @param {object} update Update to be applies
 */
export const updateSpecialHours = (specialHoursId, update) =>
  client.patch(
    `/locations/${update.locationId}/special-hours/${specialHoursId}`,
    update
  )

/**
 * Delete a special hours entry
 * @param {string} locationId Owner location id
 * @param {string} specialHoursId Owner special hours entry id
 */
export const deleteSpecialHours = (locationId, specialHoursId) =>
  client.delete(`/locations/${locationId}/special-hours/${specialHoursId}`)
