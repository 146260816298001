<i18n locale="en">
{
  "now": "Now"
}
</i18n>

<template>
  <OwnDropdown
    v-model="time"
    :options="timePickerOptions"
    :visible-options="5"
    :filter-keys="['filterKey', 'label', 'short']"
  />
</template>

<script>
import { format } from 'date-fns'
import { useI18n } from 'vue-i18n'

import { OwnDropdown } from '@/ui'
import { mapModel } from '@/utils/computed'
import { getTimePickerOptions } from '@/utils/time'

export default {
  name: 'TimePicker',
  components: {
    OwnDropdown,
  },
  setup() {
    const { t } = useI18n()

    return { t }
  },
  props: {
    /** The time value */
    modelValue: { type: String, default: null },

    /** Limit options to times available on the current day; include a "Now" option */
    sameDay: { type: Boolean, default: false },
  },
  emits: ['update:modelValue'],
  computed: {
    ...mapModel('time'),
    timePickerOptions() {
      const { sameDay } = this

      return getTimePickerOptions({
        nowTitle: sameDay ? this.t('now') : undefined,
        sameDay,
      })
    },
  },
  mounted() {
    if (this.sameDay) this.time = format(new Date(), 'h:mm a')
  },
}
</script>
