/**
 * Omit a value from an object
 * @param {string} key Key to omit
 * @param {object} object Object to omit from
 * @returns {object} Object omitting key
 */
export const omit = (key, object) => {
  const { [key]: omission, ...rest } = object

  return rest
}
