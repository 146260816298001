import { ConfiguredClient } from '@/api'

import textBuilderModule from '../../store'
import {
  type CampaignResource,
  type SmsSection,
  type SmsSectionCreate,
} from '../../types'

export const createBlockHandler = async <
  TSection extends SmsSection,
  TSectionCreate extends SmsSectionCreate,
>(
  createData: TSectionCreate
): Promise<TSection> => {
  const { brandId } = textBuilderModule?.getters
    ?.currentResource as unknown as CampaignResource

  return (await ConfiguredClient.notifications.v1.templates.sms.sections.createSMSSection(
    {
      body: {
        brandId,
        locationId: null,
        ...createData,
      },
    }
  )) as TSection
}
