<template>
  <PreviewSheet class="video-preview">
    <div class="flex-col gap-14">
      <div class="flex-col gap-8 align-center">
        <div class="video-preview__text flex-col gap-4 align-center">
          <PreviewTypography
            :text="currentBlockParams.header || ''"
            variant="heading2"
          />
          <PreviewTypography
            :text="currentBlockParams.body || ''"
            variant="paragraphRegular"
          />
        </div>
      </div>
      <div class="video-preview__video-wrapper">
        <iframe
          class="video-preview__video"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          :src="`https://www.youtube.com/embed/${currentBlockParams.video.params.id}`"
        ></iframe>
      </div>
    </div>
  </PreviewSheet>
</template>
<script lang="ts" setup>
import { computed } from 'vue'

import { PreviewSheet, PreviewTypography } from '@/lib/builder'

import type { VideoSection } from '../../../WebsiteBuilder.types'

const props = defineProps<{
  content: VideoSection
}>()

const currentBlockParams = computed(() => props.content?.params)
</script>
<style lang="scss" scoped>
.video-preview {
  padding: 120px;

  &__text {
    max-width: 672px;
    padding: 0 16px;
    text-align: center;
  }

  &__video-wrapper {
    overflow: hidden;
    position: relative;
    aspect-ratio: 16 / 9;
    border-radius: 32px;
  }

  &__video {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    border: 0;
  }
}
</style>
