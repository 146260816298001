<i18n locale="en">
{
  "labels": {
    "unknown": "Unknown Page"
  },
  "actions": {
    "add-page": "Add Page"
  }
}
</i18n>

<template>
  <div>
    <OwnOption
      v-if="actionConfig?.params?.pageId"
      @click="showAddPageDialog = true"
    >
      <span class="flex-row gap-4 justify-between align-center">
        <OwnType
          v-if="pageName"
          :text="pageName"
          variant="subtitle"
          color="primary"
        />
        <OwnSkeletonLoader v-else class="w-full" height="24" />
        <PhPencilSimple class="bluetiful-500" size="24" />
      </span>
    </OwnOption>

    <OwnButton
      v-else
      class="w-full"
      :text="t('actions.add-page')"
      @click="showAddPageDialog = true"
    />

    <AddPageDialog
      v-if="showAddPageDialog"
      :edit-item="actionConfig"
      @update="onPageUpdate"
      @close="showAddPageDialog = false"
    />
  </div>
</template>

<script>
import { PhPencilSimple } from '@phosphor-icons/vue'
import { useI18n } from 'vue-i18n'

import { ConfiguredClient } from '@/api'
import { logError } from '@/logger'
import { OwnButton, OwnOption, OwnType, OwnSkeletonLoader } from '@/ui'

import AddPageDialog from '../../../../common/resource-dialogs/add-page-dialog/AddPageDialog.vue'

export default {
  name: 'PageForm',
  components: {
    AddPageDialog,
    OwnButton,
    OwnOption,
    OwnSkeletonLoader,
    OwnType,
    PhPencilSimple,
  },
  props: {
    actionConfig: { type: Object, default: null },
  },
  emits: ['update'],
  data() {
    return {
      isLoading: false,
      pageName: undefined,
      showAddPageDialog: false,
    }
  },
  created() {
    this.fetchPageName()
  },
  setup() {
    const { t } = useI18n()

    return { t }
  },
  methods: {
    async fetchPageName() {
      const { actionConfig } = this

      if (actionConfig.type !== 'page') return

      try {
        this.isLoading = true

        const result = await ConfiguredClient.funnel.v1.pages.page.getPage({
          params: {
            pageId: actionConfig.params.pageId,
          },
        })

        this.pageName = result.title
      } catch (error) {
        logError(error)
        this.pageName = this.t('unknownPage')
      } finally {
        this.isLoading = false
      }
    },
    onPageUpdate(selectedPage) {
      this.pageName = selectedPage.params.label

      this.$emit('update', selectedPage)
    },
  },
}
</script>
