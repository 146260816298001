import { type FormatFn } from './Format.types'

const urlRegex = /[^a-zA-Z0-9_-]+/

export const UrlSafeSlugFormatter: FormatFn<string> = (value) => {
  return (
    value
      // Replace spaces to - first
      .replace(/ /g, '-')
      // Replace all other chars after
      .replace(urlRegex, '')
      .toLowerCase()
  )
}
