import { type CTAButtonSectionCreate } from '../../../types'

export const createCTAButtonData = (): CTAButtonSectionCreate => {
  return {
    data: {
      label: 'Order now',
      link: 'order',
    },
    type: 'cta',
  }
}
