<i18n locale="en">
{
  "empty": "No Data"
}
</i18n>
<template>
  <OwnCard class="own-data-table" border-color="background-divider">
    <template v-if="skeletonLoader || !loading">
      <template v-if="items.length > 0">
        <table class="own-data-table__table" :aria-label="description">
          <thead
            class="own-data-table__table-row own-data-table__table-row--heading"
          >
            <tr>
              <th
                v-for="heading of headings"
                :key="heading.value"
                class="own-data-table__table-cell own-data-table__table-cell--heading"
                :style="{
                  width: heading.width ? `${heading.width}px` : undefined,
                  maxWidth: heading.width ? `${heading.width}px` : undefined,
                  minWidth: heading.minWidth
                    ? `${heading.minWidth}px`
                    : undefined,
                }"
              >
                <slot :name="`heading.${camelToKebab(heading.value)}`">
                  <OwnDataHeadingCell :heading="heading" />
                </slot>
              </th>
            </tr>
          </thead>
          <tbody class="own-data-table__table-body">
            <tr
              v-for="(item, idx) of items"
              :key="item[itemKey]"
              class="own-data-table__table-row own-data-table__table-row--body"
              :class="[
                item[itemKey] === active && 'own-data-table__table-row--active',
              ]"
              :style="{ zIndex: items.length + 1 - idx }"
              tabindex="0"
              @keyup.enter="rowClick(item)"
              @click="rowClick(item)"
            >
              <td
                v-for="heading of headings"
                :key="`${heading.value}-${item[itemKey]}`"
                class="own-data-table__table-cell"
                :class="[
                  !heading.removePadding && 'own-data-table__table-cell--body',
                ]"
                :style="{
                  width: heading.width ? `${heading.width}px` : undefined,
                  maxWidth: heading.width ? `${heading.width}px` : undefined,
                  minWidth: heading.minWidth
                    ? `${heading.minWidth}px`
                    : undefined,
                }"
                v-on="heading.stopPropagation ? { click: stopPropagation } : {}"
              >
                <slot
                  :name="`item.${camelToKebab(heading.value)}`"
                  :item="item"
                >
                  <OwnDataCell
                    :heading="heading"
                    :item="item"
                    :loading="skeletonLoader ? loading : false"
                  />
                </slot>
              </td>
            </tr>
          </tbody>
        </table>
      </template>
      <template v-else>
        <slot name="no-items">
          <div class="own-data-table__empty-container">
            <p class="text-paragraph slate-500" v-text="t('empty')"></p>
          </div>
        </slot>
      </template>
    </template>
    <template v-else>
      <slot name="loading">
        <div class="own-data-table__loading-container">
          <OwnProgressSpinner :size="24" />
        </div>
      </slot>
    </template>
  </OwnCard>
</template>
<script>
import { camelToKebab } from '@owner/casing'
import { useI18n } from 'vue-i18n'

import { OwnCard } from '../OwnCard'
import { OwnProgressSpinner } from '../OwnProgressSpinner'

import OwnDataCell from './OwnDataCell'
import OwnDataHeadingCell from './OwnDataHeadingCell'

/**
 * @deprecated Use OwnTable
 */
export default {
  name: 'OwnDataTable',
  components: {
    OwnCard,
    OwnDataCell,
    OwnDataHeadingCell,
    OwnProgressSpinner,
  },
  emits: ['click:row'],
  setup() {
    const { t } = useI18n()

    return { t }
  },
  props: {
    /**
     * Id of active row
     */
    active: { type: String, default: undefined },

    /**
     * Table Description for accessibility
     */
    description: { type: String, default: undefined },

    /**
     * Headings
     */
    headings: { type: Array, default: () => [] },

    /**
     * Item key for change detection
     */
    itemKey: { type: String, default: 'id' },

    /**
     * Items
     */
    items: { type: Array, default: () => [] },

    /**
     * Is loading Data
     */
    loading: { type: Boolean, default: false },

    /**
     * Force Use of inline skeleton loaders
     */
    skeletonLoader: { type: Boolean, default: false },
  },
  methods: {
    camelToKebab,
    getAlignment(alignment) {
      switch (alignment) {
        case 'top':
          return 'flex-start'

        case 'center':
          return 'center'

        case 'bottom':
          return 'flex-end'

        default:
          return 'center'
      }
    },
    getJustification(justification) {
      switch (justification) {
        case 'left':
          return 'flex-start'

        case 'center':
          return 'center'

        case 'right':
          return 'flex-end'

        default:
          return 'flex-start'
      }
    },
    rowClick(item) {
      const { loading } = this

      if (!loading) {
        this.$emit('click:row', item)
      }
    },
    stopPropagation(event) {
      event.stopPropagation()
    },
  },
}
</script>
<style lang="scss">
.own-data-table {
  overflow-x: auto;

  /* height */
  &::-webkit-scrollbar {
    height: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background-color: #fafafa;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border: 2px solid #fafafa;
    border-radius: 4px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background-color: #c1c1c1;
  }

  &__table {
    border-spacing: 0;
  }

  &__table-body {
    display: flex;
    flex-direction: column;
  }

  &__table-row {
    width: 100%;
    display: flex;
    flex-direction: row;

    border-bottom: 0.5px solid $background-divider;

    &--active {
      background-color: $bluetiful-100;
    }

    &--body {
      &:last-child {
        border: none;
      }

      &:hover {
        background-color: $slate-100;
        cursor: pointer;
      }
    }
  }

  &__table-cell {
    flex: 1 0 32px;
    min-width: 32px;

    &--body {
      padding: 12px 8px;

      display: flex;
      flex-direction: row;
    }
  }

  &__empty-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px;
  }

  &__loading-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px;
  }
}
</style>
