<template>
  <hr class="preview-hr" :class="hrClasses" :style="hrStyles" />
</template>

<script>
import get from 'lodash/get'

import { useMergedTheme } from './mixins/useMergedTheme'

const BACKGROUND_COLORS = {
  brandPrimary: 'brand.primary',
  divider: 'background.divider',
  primary: 'background.primary',
  textPrimary: 'text.primary',
}

export default {
  name: 'PreviewHr',
  mixins: [useMergedTheme],
  props: {
    color: {
      type: String,
      default: 'divider',
      validator: (v) =>
        ['primary', 'divider', 'textPrimary', 'brandPrimary', 'white'].includes(
          v
        ),
    },
    thickness: {
      type: String,
      default: 'thin',
      validator: (v) => ['thin', 'medium', 'thick'].includes(v),
    },
  },
  computed: {
    computedColor() {
      const { color, mergedTheme } = this

      if (color === 'transparent') return 'transparent'

      if (color === 'white') return '#ffffff'

      return get(mergedTheme.colors, BACKGROUND_COLORS[color])
    },
    hrClasses() {
      const { thickness } = this

      return [`preview-hr--${thickness}`]
    },
    hrStyles() {
      const { computedColor } = this

      return {
        backgroundColor: computedColor,
        color: computedColor,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.preview-hr {
  border: none;

  &--thin {
    height: 0.5px;
  }

  &--medium {
    height: 2px;
  }

  &--thick {
    height: 3px;
  }
}
</style>
