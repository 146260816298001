<i18n locale="en">
{
  "close": "Okay",
"content": "This can take a little while. Keep an eye on your inbox, we will email you when your approval status changes.",
  "heading": "Setting Up Text Message Marketing"
}
</i18n>

<template>
  <OwnDialog v-model="isVisible">
    <template #heading="{ close }">
      <OwnDialogHeading :title="t('heading')" @cancel="close">
        <template #icon-left>
          <PhHourglassHigh size="24" />
        </template>
      </OwnDialogHeading>
    </template>

    <OwnDialogContent scroll>
      <OwnType color="primary" variant="paragraph" :text="t('content')" />
    </OwnDialogContent>

    <template #footer="{ close }">
      <OwnDialogFooter>
        <OwnButton :text="t('close')" primary @click="close" />
      </OwnDialogFooter>
    </template>
  </OwnDialog>
</template>

<script lang="ts" setup>
import { PhHourglassHigh } from '@phosphor-icons/vue'
import { useI18n } from 'vue-i18n'

import {
  OwnButton,
  OwnDialog,
  OwnDialogContent,
  OwnDialogFooter,
  OwnDialogHeading,
  OwnType,
} from '@/ui'

const { t } = useI18n()

const isVisible = defineModel<boolean>({ required: true })
</script>
