<template>
  <OwnCard class="toggle-card" control>
    <OwnToggle
      v-model="modelVal"
      class="toggle-card__toggle"
      :class="[modelVal && 'toggle-card__toggle--enabled']"
      :label="props.label"
      :description="description"
    />

    <div
      v-if="modelVal"
      :class="[
        'toggle-card__content',
        modelVal && 'toggle-card__content--enabled',
        'flex-col gap-4',
      ]"
    >
      <OwnRule type="dashed" />

      <slot></slot>
    </div>
  </OwnCard>
</template>

<script lang="ts" setup>
import { OwnCard, OwnRule, OwnToggle } from '@/ui'

const props = withDefaults(
  defineProps<{
    description?: string
    label: string
  }>(),
  {
    description: undefined,
  }
)

const modelVal = defineModel<boolean>({ default: false })
</script>

<style lang="scss" scoped>
$closed-padding: 12px 16px;
$open-padding: 24px;
$transition: padding 150ms ease-in;

.toggle-card {
  &__toggle {
    transition: $transition;
    padding: $closed-padding;

    &--enabled {
      padding: $open-padding;
    }
  }

  &__content {
    transition: $transition;
    padding: 0 $closed-padding $closed-padding $closed-padding;

    &--enabled {
      padding: 0 $open-padding $open-padding $open-padding;
    }
  }
}
</style>
