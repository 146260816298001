<i18n locale="en">
{
  "occasion": {
    "label": "Occasion",
    "description": "E.g. Father’s Day, Trivia Night, different opening hours, special offer, etc.",
    "placeholder": "Aa"
  },
  "tone": {
    "options": {
      "elegant": "🥂 Elegant",
      "friendly": "😊 Friendly",
      "playful": "🎉 Playful",
      "informative": "📚 Informative",
      "persuasive": "💥 Persuasive",
      "casual": "🍔 Casual",
      "yoda": "✨ Yoda",
      "null": "👀 Select"
    },
    "label": "Tone of Voice"
  },
  "item": {
    "label": "Menu Item",
    "add": "Select Menu Item"
  },
  "coupon": {
    "label": "Coupon",
    "add": "Select Coupon"
  },
  "generate": "Generate",
  "regenerate": "Re-Generate",
  "generate-error": "Could not generate a template."
}
</i18n>

<template>
  <div class="flex-col gap-8">
    <OwnInputContainer
      :label="t('occasion.label')"
      :description="t('occasion.description')"
    >
      <OwnInput
        v-model="occasion.value"
        :placeholder="t('occasion.placeholder')"
      />
    </OwnInputContainer>
    <OwnInputContainer :label="t('tone.label')">
      <OwnDropdown v-model="tone.value" :options="toneOptions" />
    </OwnInputContainer>
    <template v-if="locationId">
      <ItemSelectorSingle
        v-if="addingItem"
        v-model="itemId.value"
        :brand-id="activeBrandId"
        :location-id="locationId"
        limit="3"
        allow-deselect
      />
      <OwnInputContainer v-else :label="t('item.label')">
        <OwnButton :text="t('item.add')" secondary @click="addingItem = true" />
      </OwnInputContainer>
      <CouponSelectorSingle
        v-if="addingCoupon"
        v-model="couponId.value"
        :location-id="locationId"
        :brand-id="activeBrandId"
        limit="3"
        allow-deselect
      />
      <OwnInputContainer v-else :label="t('coupon.label')">
        <OwnButton
          :text="t('coupon.add')"
          secondary
          @click="addingCoupon = true"
        />
      </OwnInputContainer>
    </template>
    <OwnButton
      :text="t(hasGenerated ? 'regenerate' : 'generate')"
      :processing="isLoading"
      primary
      @click="onGenerate"
    />
  </div>
</template>

<script>
import { OlympusConstants } from '@owner/olympus-client'
import { useI18n } from 'vue-i18n'
import { mapGetters } from 'vuex'

import { ConfiguredClient } from '@/api'
import { ItemSelectorSingle, CouponSelectorSingle } from '@/features/menu'
import { FormBuilder } from '@/forms'
import { logError } from '@/logger'
import notify from '@/mixins/notify'
import { OwnInputContainer, OwnInput, OwnDropdown, OwnButton } from '@/ui'

export default {
  name: 'AiTemplateEditor',
  components: {
    CouponSelectorSingle,
    ItemSelectorSingle,
    OwnButton,
    OwnDropdown,
    OwnInput,
    OwnInputContainer,
  },
  mixins: [
    notify,
    FormBuilder({
      couponId: {
        initialValue: null,
        validateOnInit: true,
        validators: [],
      },
      itemId: {
        initialValue: null,
        validateOnInit: true,
        validators: [],
      },
      occasion: {
        validateOnInit: true,
        validators: [],
      },
      tone: {
        initialValue: null,
        validateOnInit: true,
        validators: [],
      },
    }),
  ],
  props: {
    /**
     * Location id
     */
    locationId: { type: String, default: null },
  },
  emits: ['custom'],
  setup() {
    const { t } = useI18n()

    return { t }
  },
  data() {
    return {
      addingCoupon: false,
      addingItem: false,
      hasGenerated: false,
      isLoading: false,
      toneOptions: [null, ...OlympusConstants.notifications.VOICE_TONES].map(
        (value) => ({
          label: this.t(`tone.options.${value}`),
          value,
        })
      ),
    }
  },
  computed: {
    ...mapGetters({
      activeBrandId: 'core/brand/active/id',
    }),
  },
  methods: {
    async onGenerate() {
      const { formValues, activeBrandId, locationId } = this

      const { couponId, itemId, occasion, tone } = formValues

      try {
        this.isLoading = true

        const activeCouponId = locationId ? couponId ?? undefined : undefined
        const activeItemId = locationId ? itemId ?? undefined : undefined

        this.$analytics.track('Email Template Generated', {
          brand: activeBrandId,
          couponId,
          itemId,
          location: locationId,
          occasion,
          tone,
        })

        const template =
          await ConfiguredClient.notifications.v1.templates.emails.generateEmailTemplate(
            {
              body: {
                brandId: activeBrandId,
                couponId: activeCouponId,
                itemId: activeItemId,
                locationId,
                occasion,
                tone: tone ?? undefined,
              },
            }
          )

        const blocks = template.sections.map((block, index) => ({
          ...block,
          id: `${index}`,
        }))

        const customData = {
          blocks,
          resource: {
            ...template,
            brandId: activeBrandId,
            locationId,
            sections: blocks.map(({ id }) => id),
          },
        }

        this.$emit('custom', customData)
        this.hasGenerated = true
      } catch (error) {
        this.$notify(this.t('generate-error'), 'error')
        logError(error)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
