import { getDateRange } from '@/utils/date/getDateRange'

import { defaultQuery } from './utils/defaultQuery'

export default {
  action: null,
  filters: {
    dayRange: {
      data: getDateRange('last-30-days'),
      enabled: false,
    },
  },
  query: defaultQuery,
  view: 'all',
}
