import {
  type BlockCore,
  type BuilderState,
  type ResourceCore,
} from '@/lib/builder'
import { removeObserver } from '@/utils/helpers'

export const setBlockMutation = <
  TResource extends ResourceCore,
  TBlock extends BlockCore,
>(
  state: BuilderState<TResource, TBlock>,
  {
    data,
    target = ['current'],
  }: { data: TBlock; target: ['current' | 'original'] }
): void => {
  if (target.includes('current')) {
    const targetIndex = state._builder_currentState.blocks.findIndex(
      (block) => block.id === data.id
    )

    if (targetIndex !== -1) {
      state._builder_currentState.blocks.splice(
        targetIndex,
        1,
        removeObserver(data)
      )
    } else {
      state._builder_currentState.blocks.splice(
        state._builder_currentState.blocks.length,
        1,
        removeObserver(data)
      )
    }
  }

  if (target.includes('original')) {
    const targetIndex = state._builder_originalState.blocks.findIndex(
      (block) => block.id === data.id
    )

    if (targetIndex !== -1) {
      state._builder_originalState.blocks.splice(
        targetIndex,
        1,
        removeObserver(data)
      )
    } else {
      state._builder_originalState.blocks.splice(
        state._builder_originalState.blocks.length,
        1,
        removeObserver(data)
      )
    }
  }
}
