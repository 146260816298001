import { type Brand } from '@/core'

export const locationAnalytics = (
  brand: Brand
): Record<string, string | undefined> => {
  return {
    brandId: brand.id,
    name: brand.name,
  }
}
