import { type ActionContext } from 'vuex'

import {
  type BlockCore,
  type BuilderState,
  type Command,
  type ResourceCore,
} from '@/lib/builder'
import { type CoreModule } from '@/lib/store'
import { logError } from '@/logger'
import { removeObserver } from '@/utils/helpers'

export const enqueueCommand = async <
  TResource extends ResourceCore,
  TBlock extends BlockCore,
>(
  { commit, state }: ActionContext<BuilderState<TResource, TBlock>, CoreModule>,
  command: Command<TBlock>
): Promise<void> => {
  const currentCommandQueue = state._builder_commandQueue

  let prevCommand

  if (currentCommandQueue.length) {
    prevCommand = currentCommandQueue[currentCommandQueue.length - 1]?.id
  }

  let revertData

  switch (command.context.scope) {
    case 'block':
      revertData = removeObserver(state._builder_currentState.blocks).find(
        (block) => block.id === command.context.targetId
      )
      break

    case 'resource':
      revertData = removeObserver(state._builder_currentState.resource)
      break

    default:
      logError(`Unknown command scope`)
      break
  }

  commit('_builder_PUSH_COMMAND', {
    ...command,
    committed: false,
    context: {
      ...command.context,
      prevCommand,
      revertData,
    },
  })
}
