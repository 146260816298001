<i18n locale="en">
{
  "action": "Save"
}
</i18n>

<template>
  <OwnButton
    :disabled="disabled"
    :text="t('action')"
    :processing="saving"
    primary
    @click="update"
  />
</template>

<script>
import { useI18n } from 'vue-i18n'

import { OwnButton } from '@/ui'

export default {
  name: 'UpdateOTCFooter',
  components: { OwnButton },
  props: {
    /** Disables scheduling */
    disabled: { type: Boolean, required: true },

    /** Current loading state */
    saving: { type: Boolean, default: false },
  },
  emits: ['update'],
  setup() {
    const { t } = useI18n()

    return { t }
  },
  methods: {
    async update() {
      this.$emit('update')
    },
  },
}
</script>
