<i18n locale="en">
{
  "descriptions": {
    "non-actionable": "We tried our best, but something went wrong. We couldn’t publish all of your changes.",
    "actionable": "We tried our best, but something went wrong. We found a few issues. Here’s what you need to check:"
  }
}
</i18n>

<template>
  <div class="website-publish-error-content flex-col gap-6">
    <OwnType :text="description" />

    <div class="flex-col gap-3">
      <div
        v-for="error of displayErrors"
        :key="error.id"
        class="flex-row gap-2"
      >
        <PhWarning class="status-warning" size="24" />
        <OwnType :text="error.label" />
      </div>
    </div>
  </div>
</template>

<script>
import { PhWarning } from '@phosphor-icons/vue'
import { isDefined } from '@vueuse/core'
import { useI18n } from 'vue-i18n'

import { OwnType } from '@/ui'

export default {
  name: 'PublishErrorContent',
  components: {
    OwnType,
    PhWarning,
  },
  props: {
    blockModules: { type: Array, default: () => [] },
    currentBlocks: { type: Array, default: () => [] },
    errors: { type: Array, default: () => [] },
  },
  setup() {
    const { t } = useI18n()

    return { t }
  },
  computed: {
    description() {
      const { displayErrors } = this

      return displayErrors.length > 0
        ? this.t('descriptions.actionable')
        : this.t('descriptions.non-actionable')
    },
    displayErrors() {
      const { flatBlockModules, errors, currentBlocks } = this

      return errors
        .map((error) => {
          const block = currentBlocks.find((block) => block.id === error)

          const blockModule = flatBlockModules.find(
            (module) => module.type === block?.type
          )

          if (!blockModule) return undefined

          return {
            id: error,
            label: blockModule?.label,
          }
        })
        .filter(isDefined)
    },
    flatBlockModules() {
      return this.blockModules.flatMap((module) => {
        return module.meta.map((moduleMeta) => ({
          ...moduleMeta,
          type: moduleMeta.key,
        }))
      })
    },
  },
}
</script>
