import { useBreakpoints as vueUseBreakpoints } from '@vueuse/core'
import { computed, type ComputedRef, type Ref } from 'vue'

export type BreakpointNames = 'xs' | 'sm' | 'md' | 'lg'

const BREAKPOINT_MAP: Record<BreakpointNames, number> = {
  /* eslint-disable sort-keys-fix/sort-keys-fix,vue/sort-keys -- allow for logical ordering */
  xs: 320,
  sm: 640,
  md: 1024,
  lg: 1920,
  /* eslint-enable sort-keys-fix/sort-keys-fix,vue/sort-keys */
}

export interface UseBreakpoints {
  breakpoints: Record<BreakpointNames, Ref<boolean>>
  isDesktop: ComputedRef<boolean>
}

export const useBreakpoints = (): UseBreakpoints => {
  const breakpoints = vueUseBreakpoints<BreakpointNames>(BREAKPOINT_MAP)

  const isDesktop = computed(() => breakpoints.md.value)

  return {
    breakpoints,
    isDesktop,
  }
}
