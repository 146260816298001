const updateMutationFactory =
  (scope) =>
  (key, children) =>
  (state, { id, update }) => {
    const scopedOptions = state[scope]

    const targetIndex = scopedOptions.findIndex((org) => org.id === id)

    if (targetIndex === -1) {
      throw new Error(`${scope} with id ${id} not found on store`)
    } else {
      let setterObject = state[scope][targetIndex]

      if (children) {
        for (const child of children) {
          setterObject = setterObject[child]
        }
      }

      setterObject[key] = update
    }
  }

export { updateMutationFactory }
