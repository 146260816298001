import analyticsLogger from './analyticsLogger'
import authGuards from './authGuards'

const guards = [...authGuards, ...analyticsLogger]

export default (to, from, next) => {
  for (const guard of guards) {
    const nextParams = guard(to, from)
    if (nextParams) return next(nextParams)
  }
  return next()
}
