import get from 'lodash/get'

const getActiveKey = (scope) =>
  scope === 'location' ? 'activeLocation' : 'activeBrand'

const getHandler = ({ active, scope }, curr) => {
  return active
    ? (state, getters) => {
        let targetLocation
        if (scope === 'location') {
          targetLocation = getters['location/active/*']
        } else {
          targetLocation = getters[`brand/byId`](state[getActiveKey(scope)])
        }

        const sliceKey = curr.endsWith('/*') ? curr.slice(0, -2) : curr

        return targetLocation
          ? get(targetLocation, sliceKey.replace('/', '.'))
          : undefined
      }
    : (_state, getters) => (resourceId) => {
        const targetLocation = getters[`${scope}/byId`](resourceId)

        const sliceKey = curr.endsWith('/*') ? curr.slice(0, -2) : curr

        return targetLocation
          ? get(targetLocation, sliceKey.replace('/', '.'))
          : undefined
      }
}

/**
 * Builds getters based on path and options
 * @param {Array} getterList List of getters to build
 * @param {object} options Options to build
 * @param {boolean} options.active Is building for active location or brand
 * @param {string} options.scope `location` or `brand`
 * @returns {object} getters
 */
const buildBaseGetters = (getterList, options) => {
  return getterList.reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: getHandler(options, curr),
    }),
    {}
  )
}

export { buildBaseGetters }
