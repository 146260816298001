import { type CreateBlockData } from '@/lib/builder'

import { type TextSection, type TextSectionCreate } from '../../../types'

export type CreateTextData = CreateBlockData<TextSection> & {
  data: TextSection['data']
}

export const createTextSection = (
  blockData: CreateTextData
): TextSectionCreate => {
  return {
    data: blockData.data ?? null,
    type: blockData.type,
  }
}
