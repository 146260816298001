import { reactive, type Reactive } from 'vue'

import { type PaginatedRequestData, type PaginatedRequestParams } from '@/core'

export type UsePaginationDataArgs = Partial<PaginatedRequestParams>

export interface UsePaginationData {
  requestParams: Reactive<PaginatedRequestParams>
  requestData: Reactive<PaginatedRequestData>
}

export const usePaginationData = (
  params: UsePaginationDataArgs = {}
): UsePaginationData => {
  const requestParams = reactive<PaginatedRequestParams>({
    limit: params.limit ?? 10,
    page: params.page ?? 1,
    query: params.query ?? '',
  })

  const requestData = reactive<PaginatedRequestData>({
    pageCount: undefined,
    totalItems: undefined,
  })

  return { requestData, requestParams }
}
