import { type Action, type AllowedFlow } from '../types'

export interface SetupTranslations {
  errors: Record<string, string>
  flowDefinitions: Record<
    AllowedFlow,
    {
      description: string
      title: string
      'locations-left'?: string
    }
  >
  labels: Record<string, string | Record<string, string>>
  steps: Record<AllowedFlow, Record<string, string>>
  tasks: Record<AllowedFlow, string>
  flowName: Record<Action['name'], string>
}

export const setupEn: SetupTranslations = {
  errors: {
    'dismiss-failed': 'Failed to dismiss this task. Please try again.',
    'mark-complete-failed':
      'Failed to mark this task complete. You may need to dismiss it manually on the Home page.',
  },
  flowDefinitions: {
    domain: {
      description: 'Set up your domain.',
      title: 'Domain',
    },
    'guest-import': {
      description: 'Import existing guest list.',
      'locations-left': '{left}/{total} locations left to import',
      title: 'Import Guests',
    },
    listings: {
      description: 'Improve SEO performance across the internet.',
      'locations-left': '{left}/{total} locations left to set up',
      title: 'Listings',
    },
    menu: {
      description: 'Import your menu.',
      'locations-left': '{left}/{total} locations left to import',
      title: 'Menu',
    },
    'new-location': {
      description: 'Add a new location.',
      title: 'New Location',
    },
    payments: {
      description: 'Add a bank account to receive payments.',
      'locations-left': '{left}/{total} locations left to set up',
      title: 'Payments',
    },
    'pickup-delivery': {
      description: 'Set your delivery and pickup settings',
      'locations-left': '{left}/{total} locations left to set up',
      title: 'Set Up Delivery & Pickup',
    },
    'popular-items': {
      description: 'Indicate which items are popular.',
      'locations-left': '{left}/{total} locations left to set up',
      title: 'Popular Items',
    },
    pos: {
      description: 'Sync your Owner menu with your POS.',
      'locations-left': '{left}/{total} locations left to set up',
      title: 'POS',
    },
    'sms-marketing': {
      description: 'Set up SMS marketing.',
      title: 'SMS Marketing',
    },
  },
  flowName: {
    'onboard-domain': 'Domain',
    'onboard-guest-import': 'Guest Import',
    'onboard-listings': 'Listings',
    'onboard-menu': 'Menu',
    'onboard-payouts': 'Payouts',
    'onboard-pickup-delivery': 'Delivery & Pickup',
    'onboard-popular-items': 'Popular Items',
    'onboard-pos': 'POS',
    'onboard-sms-marketing': 'SMS Marketing',
  },
  labels: {
    'guest-import': {
      column: 'Column {index}',
    },
    remaining: 'Complete | {n} minute remaining | {n} minutes remaining',
  },
  steps: {
    domain: {
      connect: 'Connect',
      introduction: 'Introduction',
    },
    'guest-import': {
      introduction: 'Introduction',
      'match-csv': 'Match Guest Data',
      'upload-csv': 'Upload CSV',
      'verify-csv': 'Verify CSV',
    },
    listings: {
      address: 'Address',
      confirm: 'Confirmation',
      introduction: 'Introduction',
      name: 'Name',
      phone: 'Phone',
      scan: 'Current Listings',
    },
    menu: {
      import: 'Import',
      introduction: 'Introduction',
    },
    'new-location': {
      'add-locations': 'Add Locations',
      subscribe: 'Subscribe',
    },
    payments: {
      connect: 'Connect',
      introduction: 'Introduction',
    },
    'pickup-delivery': {
      'delivery-settings': 'Delivery',
      introduction: 'Introduction',
      'time-management-settings': 'Time Management',
    },
    'popular-items': {
      introduction: 'Introduction',
      selection: 'Select Items',
    },
    pos: {
      introduction: 'Introduction',
      provider: 'Provider',
    },
    'sms-marketing': {
      'business-information': 'Business Information',
      introduction: 'Introduction',
    },
  },
  tasks: {
    domain: 'Connect Domain',
    'guest-import': 'Import Guests',
    listings: 'Setup Listings',
    menu: 'Set Up Menu',
    'new-location': 'Setup Locations',
    payments: 'Payments',
    'pickup-delivery': 'Set Up Delivery & Pickup',
    'popular-items': 'Popular Items',
    pos: 'POS',
    'sms-marketing': 'SMS Marketing',
  },
}
