<template>
  <OwnSegmentedControl
    v-if="layoutWidth > transitionBreakpoint"
    :options="navOptions"
    el="router-link"
    variant="navigation"
  />

  <OwnDropdown v-else v-model="dropdownModel" :options="dropdownOptions" />
</template>

<script lang="ts" setup>
import { computed, inject } from 'vue'
import { type RouteLocationNamedRaw } from 'vue-router'
import { useRoute, useRouter } from 'vue-router'

import { OwnDropdown, OwnSegmentedControl } from '@/ui'

const route = useRoute()
const router = useRouter()

const layoutWidth = inject<number>('page-layout__width') ?? 0

export interface DropdownNavItem {
  label: string
  to: RouteLocationNamedRaw
}

const props = withDefaults(
  defineProps<{
    navItems: DropdownNavItem[]
    transitionBreakpoint?: number
  }>(),
  {
    transitionBreakpoint: 600,
  }
)

const navOptions = computed(() => {
  return props.navItems.map(({ to, label }) => ({
    label,
    value: to,
  }))
})

const dropdownOptions = computed(() => {
  return props.navItems.map(({ label, to }) => ({
    label,
    value: routeLocationAsValue(to),
  }))
})

const dropdownModel = computed({
  get() {
    // @ts-expect-error Vue router upgrade incompat
    return routeLocationAsValue(route)
  },
  set(newValue) {
    const item = props.navItems.find(
      (item) => routeLocationAsValue(item.to) === newValue
    )
    if (item) {
      router.push(item.to)
    }
  },
})

const routeLocationAsValue = (to: RouteLocationNamedRaw): string => {
  if (typeof to === 'string') {
    return to
  } else if (to.name && typeof to.name === 'string') {
    return to.name
  }

  throw new Error('Invalid raw location')
}
</script>
