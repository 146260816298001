import { chunk } from './helpers'

/**
 * Converts a hex string to an rgba color
 * @param {string} hexColor a hex color
 * @param {number} [alpha] an optional alpha value
 * @returns {string} an rgba string
 */
export const hexToRgba = (hexColor, alpha) => {
  const [r, g, b, a] = chunk(hexColor.slice(1), 2).map((c) => parseInt(c, 16))
  return `rgba(${r},${g},${b},${alpha || Math.round((a / 255) * 100) / 100})`
}
