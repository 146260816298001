<template>
  <div class="dialog-heading flex-row gap-6 align-center justify-between">
    <div class="flex-row gap-3 align-center">
      <slot name="icon-left" />
      <OwnType v-if="title" :text="title" variant="title" />
    </div>
    <OwnButton @click="emit('cancel')">
      <template #icon-left>
        <PhX size="24" />
      </template>
    </OwnButton>
  </div>
</template>

<script lang="ts" setup>
import { PhX } from '@phosphor-icons/vue'

import { OwnButton } from '../OwnButton'
import { OwnType } from '../OwnType'

withDefaults(defineProps<{ title?: string }>(), {
  title: undefined,
})

const emit = defineEmits<(event: 'cancel') => void>()
</script>

<style lang="scss" scoped>
.dialog-heading {
  border-bottom: 1px dashed $background-divider;
  padding: 24px;
}
</style>
