import bus from '@/mixins/bus'

const builderConsumer = (messageKey, handler) => {
  return {
    mixins: [bus],
    mounted() {
      this.$bus.on(`builder-${messageKey}`, this[handler])
    },
    name: 'BuilderConsumer',
  }
}

export default builderConsumer
