/**
 * Removes the Vue observers from any objects/arrays
 * @param {*} any A dirty variable
 * @returns {*} A cleaned variable
 */
const removeObserver = <T>(any: T): T => {
  if (any === undefined || any === null) {
    return any
  }

  return JSON.parse(JSON.stringify(any))
}

export default removeObserver
