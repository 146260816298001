import {
  type BlockCore,
  type BuilderState,
  type Command,
  type ResourceCore,
} from '@/lib/builder'

export const pushCommandMutation = <
  TResource extends ResourceCore,
  TBlock extends BlockCore,
>(
  state: BuilderState<TResource, TBlock>,
  payload: Command<TBlock>
): void => {
  const updatedQueue = [...state._builder_commandQueue, payload]

  state._builder_commandQueue = updatedQueue as Array<
    Command<TResource | TBlock>
  >
}
