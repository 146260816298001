import bus from '@/mixins/bus'

export default {
  name: 'BuilderProducer',
  mixins: [bus],
  methods: {
    sendBuilderEvent(messageKey, message) {
      this.$bus.emit(`builder-${messageKey}`, message)
    },
  },
}
