const formatPhoneNumber = (newValue) => {
  const chars = newValue.split('')

  const formatted = new Array(10).fill(' ')

  for (let idx = 0; idx < chars.length; idx++) {
    formatted[idx] = chars[idx]
  }

  formatted.splice(0, 0, '(')
  formatted.splice(4, 0, ')')
  formatted.splice(5, 0, ' ')
  formatted.splice(9, 0, '-')

  return formatted.join('')
}

const PHONE_REGEX = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im

const E164_REGEX = /^\+[1-9]\d{1,14}$/

/**
 * Format a phone number for E164 style
 * @param {string} rawPhoneNumber An unformatted phone number input
 * @returns {string | null} An E164 formatted phone number
 */
const formatPhoneE164 = (rawPhoneNumber) => {
  if (E164_REGEX.test(rawPhoneNumber)) return rawPhoneNumber
  if (!PHONE_REGEX.test(rawPhoneNumber)) return null
  const cleaned = ('' + rawPhoneNumber).replace(/\D/g, '')
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)

  if (!match || !match.length) return null

  const intlCode = match[1] ? `+${match[1]}` : '+1'

  return [intlCode, match[2], match[3], match[4]].join('')
}

export { formatPhoneNumber, formatPhoneE164 }
