<template>
  <span class="w-full flex-col">
    <OwnType
      class="text--truncate"
      el="span"
      :text="cellText"
      :align="props.column.align"
      :color="props.column.body?.color"
      :variant="props.column.body?.font"
    />
  </span>
</template>

<script lang="ts" setup generic="T extends Record<string, unknown>">
import { computed } from 'vue'

import { OwnType } from '../../OwnType'
import { OwnTableColumnConfig } from '../types'

const props = defineProps<{
  column: OwnTableColumnConfig<T>
  item: T
}>()

const cellText = computed<string | number>(() => {
  const itemKey = props.column.body?.key ?? props.column.key
  const itemValue = props.item[itemKey]

  if (props.column.body?.format) {
    return props.column.body.format(itemValue)
  }

  if (typeof itemValue === 'string' || typeof itemValue === 'number') {
    return itemValue
  }

  return '—'
})
</script>
