import merge from 'lodash/merge'

import { ConfiguredClient } from '@/api'
import { type TemplateData } from '@/lib/builder'

import { ContentBlockModule } from '../blocks/content'
import { GalleryBlockModule } from '../blocks/gallery'
import {
  type WebsiteResourceCreate,
  type WebsiteSectionCreate,
} from '../WebsiteBuilder.types'

export const showcaseTemplate = async (
  brandId: string
): Promise<TemplateData<WebsiteResourceCreate, WebsiteSectionCreate>> => {
  const funnel = await ConfiguredClient.funnel.v1.brands.brand.getFunnel({
    params: { brandId },
  })

  return {
    blocks: [
      {
        ...merge(ContentBlockModule.createData({ brandId, type: 'content' }), {
          params: {
            action: null,
            media: {
              params: {
                image:
                  'https://static-content.owner.com/0bf40554-b4d5-40fc-9780-82f89e28cd20',
              },
            },
          },
        }),
      },
      {
        ...GalleryBlockModule.createData({ brandId, type: 'gallery' }),
      },
      {
        ...merge(ContentBlockModule.createData({ brandId, type: 'content' }), {
          params: {
            action: null,
            alignment: 'right',
            media: {
              params: {
                image:
                  'https://static-content.owner.com/f9782ea8-79f0-467f-8a96-deef5f1bac92',
              },
            },
          },
        }),
      },
    ],
    resource: {
      brandId,
      funnel,
      path: { params: { locationId: null, slug: 'custom' }, type: 'custom' },
      sections: [],
      title: 'showcase',
    },
  }
}
