import { type RouteLocationNormalized, type RouteRecordRaw } from 'vue-router'

import {
  type BlockCore,
  type BuilderModuleConfig,
  type ResourceCore,
} from '@/lib/builder'

export const BuilderRouterFactory = <
  TResource extends ResourceCore,
  TResourceCreate,
  TResourceUpdate,
  TBlock extends BlockCore,
  TBlockCreate,
  TBlockUpdate,
>(
  moduleConfig: BuilderModuleConfig<
    TResource,
    TResourceCreate,
    TResourceUpdate,
    TBlock,
    TBlockCreate,
    TBlockUpdate
  >
): RouteRecordRaw[] => {
  const {
    resourceName,
    resourceTitle,
    root,
    storeModule,
    meta,
    scaling,
    templates,
  } = moduleConfig.options

  const { blocks, methods } = moduleConfig

  const {
    analyticsEvents,
    createAnalyticsEvents,
    brandThemePath,
    home,
    previewBackgroundColor,
  } = moduleConfig.options

  const { preview, publishDialog, rightActions } = moduleConfig.components

  const { customPages } = moduleConfig

  const injectionProps = {
    brandThemePath,
    previewBackgroundColor,
    resourceTitle,
    scaling,
    storeModule,
  }

  return [
    ...customPages
      .filter((page) => page.asRoot)
      .map((page) => ({
        component: async () => import('../views/BuilderCustomView.vue'),
        meta,
        name: page.perResource
          ? `${root}.${page.slug}.detail`
          : `${root}.${page.slug}`,
        path: page.perResource ? `${page.slug}/:slug` : page.slug,
        props: (route: RouteLocationNormalized) => ({
          ...injectionProps,
          analyticsEvents: page.analyticsEvents,
          blocks,
          brandThemePath: page.brandThemePath,
          components: page.components,
          home,
          methods: {
            ...methods,
            ...page.methods,
          },
          pageTitle: page.pageTitle,
          preview: {
            ...preview,
            ...page.preview,
          },
          readonly: true,
          slug: page.perResource ? route.params.slug : undefined,
        }),
      })),
    {
      children: [
        {
          component: async () => import('../views/BuilderNewTemplateView.vue'),
          meta,
          name: `${root}.${resourceName}.new`,
          path: 'new',
          props: () => ({
            ...injectionProps,
            analyticsEvents: createAnalyticsEvents,
            blocks,
            home,
            methods,
            preview: {
              ...preview,
              emptyPreview: templates.components?.emptyPreview,
              rightActions: templates.components?.rightActions,
            },
            readonly: true,
            resourceName,
            root,
            slug: 'new',
            templates,
          }),
        },
        ...customPages
          .filter((page) => !page.asRoot)
          .map((page) => ({
            component: async () => import('../views/BuilderCustomView.vue'),
            meta,
            name: `${root}.${resourceName}.${page.slug}`,
            path: page.perResource ? `:slug/${page.slug}` : page.slug,
            props: (route: RouteLocationNormalized) => ({
              ...injectionProps,
              analyticsEvents: page.analyticsEvents,
              blocks,
              brandThemePath: page.brandThemePath,
              components: page.components,
              home,
              methods: {
                ...methods,
                ...page.methods,
              },
              pageTitle: page.pageTitle,
              preview: {
                ...preview,
                ...page.preview,
              },
              readonly: true,
              slug: page.perResource ? route.params.slug : undefined,
            }),
          })),
        {
          children: [
            {
              component: async () => import('../views/BuilderEditView.vue'),
              meta,
              name: `${root}.${resourceName}.edit.block`,
              path: 'blocks/:blockId',
              props: (route) => ({
                blockId: route.params.blockId ?? undefined,
                blocks,
                preview,
                resourceName,
                root,
                slug: route.params.slug ?? undefined,
              }),
            },
          ],
          component: async () => import('../views/BuilderRoot.vue'),
          meta,
          name: `${root}.${resourceName}.edit`,
          path: ':slug',
          props: (route) => ({
            ...injectionProps,
            analyticsEvents,
            blocks,
            home,
            methods,
            preview,
            publishDialog,
            readonly: false,
            resourceName,
            rightActions,
            root,
            slug: route.params.slug ?? undefined,
          }),
        },
      ],
      component: {
        name: 'BuilderRouterView',
        // eslint-disable-next-line xss/no-mixed-html
        template: '<router-view></router-view>',
      },
      meta,
      name: `${root}.${resourceName}`,
      path: `${resourceName}`,
      redirect: { name: `${root}.${resourceName}.home` },
    },
  ]
}
