export const authEn = {
  features: {
    one: {
      description: 'Design and launch your own website.',
      title: 'Website',
    },
    three: {
      description: 'Let your customers order your food online.',
      title: 'Online Ordering',
    },
    two: {
      description: 'Easily build and send emails and text messages.',
      title: 'Marketing',
    },
  },
}
