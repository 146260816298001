import { format, isToday, isTomorrow, isYesterday, parseISO } from 'date-fns'

import i18nPlugin from '@/plugins/i18n'

import { type FormatFn } from './Format.types'
const i18n = i18nPlugin.global

export const DateTimeFormatter: FormatFn<string> = (value) => {
  const parsedDate = parseISO(value)
  return format(parsedDate, 'MMM dd, yyyy, h:mm aa')
}

export const DateTimeFormatterWithDailyContext: FormatFn<string> = (value) => {
  const parsedDate = parseISO(value)

  if (isToday(parsedDate)) {
    return i18n.t('formatters.date-time.today', {
      time: format(parsedDate, 'h:mm aa'),
    })
  }

  if (isTomorrow(parsedDate)) {
    return i18n.t('formatters.date-time.tomorrow', {
      time: format(parsedDate, 'h:mm aa'),
    })
  }

  if (isYesterday(parsedDate)) {
    return i18n.t('formatters.date-time.yesterday', {
      time: format(parsedDate, 'h:mm aa'),
    })
  }

  return format(parsedDate, 'MMM dd, yyyy, h:mm aa')
}
