import { type ActionContext } from 'vuex'

import {
  type BlockCore,
  type BuilderState,
  type ResourceCore,
  type BuilderModuleConfig,
  flattenBlockModules,
} from '@/lib/builder'
import { type CoreModule } from '@/lib/store'
import { logError } from '@/logger'
import { removeObserver } from '@/utils/helpers'

export const publishChanges = async <
  TResource extends ResourceCore,
  TBlock extends BlockCore,
>(
  {
    getters,
    dispatch,
  }: ActionContext<BuilderState<TResource, TBlock>, CoreModule>,
  {
    blocks,
    methods,
  }: {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    blocks: BuilderModuleConfig<TResource, any, any, TBlock, any, any>['blocks']
    methods: BuilderModuleConfig<
      TResource,
      any,
      any,
      TBlock,
      any,
      any
    >['methods']
    /* eslint-enable @typescript-eslint/no-explicit-any */
  }
): Promise<{ state: 'error' | 'success'; errors: string[] }> => {
  const { blockChanges, currentBlocks, resourceChanges } = getters

  const flatBlockModules = flattenBlockModules(blocks)

  const errors: string[] = []

  if (blockChanges.hasChange) {
    const results = await Promise.allSettled(
      blockChanges.changedBlocks.map(async ({ id, ...update }: TBlock) => {
        const blockModule = flatBlockModules.find(
          (module) => module.key === update.type
        )

        return await blockModule?.methods.update(id, update)
      })
    )

    const successfulUpdates = []

    // @ts-expect-error Allow iteration here
    for (const [idx, result] of results.entries()) {
      if (result.status === 'fulfilled') {
        successfulUpdates.push(result.value)
      } else {
        errors.push(blockChanges.changedBlocks[idx].id)
      }
    }

    for (const update of successfulUpdates) {
      await Promise.all([
        dispatch('_builder_dequeueCommands', {
          id: update.id,
          scope: 'block',
        }),
        dispatch('_builder_resetBlock', update),
      ])
    }
  }

  if (resourceChanges.hasChange) {
    try {
      const removedBlocks = removeObserver(resourceChanges)
        .removedBlocks as string[]

      const { id, ...body } = resourceChanges.changedResource

      const result = await methods.updateResource(id, body)
      await Promise.all([
        dispatch(`_builder_dequeueCommands`, {
          scope: 'resource',
        }),
        dispatch(`_builder_resetResource`, result),
      ])

      await Promise.all(
        removedBlocks.map(async (blockId) => {
          const storeBlock = currentBlocks.find(
            (block: TBlock) => block.id === blockId
          )

          const blockModule = flatBlockModules.find(
            (module) => module.key === storeBlock.type
          )

          return await blockModule?.methods.delete(blockId)
        })
      )
    } catch (error) {
      logError(error)
      errors.push('resource')
    }
  }

  return {
    errors,
    state: errors.length ? 'error' : 'success',
  }
}
