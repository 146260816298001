<i18n locale="en">
{
  "title": "Add Locations",
  "actions": {
    "add": "Add Location | Add Location | Add Locations"
  },
  "labels": {
    "empty": "No Locations Found"
  }
}
</i18n>

<template>
  <OwnDialog :model-value="true" @cancel="emit('close')">
    <template #heading>
      <OwnDialogHeading :title="t('title')" @cancel="emit('close')" />
    </template>

    <div class="location-selector-dialog__filter-bar">
      <SearchInput
        ref="searchInput"
        v-model="searchQuery"
        class="w-full"
        borderless
      />
    </div>

    <OwnDialogContent scroll>
      <!-- @vue-expect-error Checkbox Generic Needs work -->
      <OwnCheckboxGroup
        v-if="displayedOptions.length > 0"
        :model-value="selectedLocationIds"
        :items="displayedOptions"
        :disabled-items="alreadySelected"
        @select="onLocationSelect"
        @deselect="onLocationDeselect"
      />

      <DefaultEmptyState v-else :message="t('labels.empty')">
        <template #icon>
          <PhBookBookmark size="24" />
        </template>
      </DefaultEmptyState>
    </OwnDialogContent>

    <OwnDialogFooter>
      <OwnButton
        :text="t('actions.add', selectedLocations.length)"
        primary
        @click="onConfirmSelection"
      >
        <template #icon-right>
          <PhCheckCircle size="24" />
        </template>
      </OwnButton>
    </OwnDialogFooter>
  </OwnDialog>
</template>

<script lang="ts" setup>
import { PhBookBookmark, PhCheckCircle } from '@phosphor-icons/vue'
import { ComponentPublicInstance, computed, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { ConfiguredClient } from '@/api'
import { DefaultEmptyState } from '@/components/empty'
import { SearchInput } from '@/components/inputs'
import { logError } from '@/logger'
import {
  OwnButton,
  OwnCheckboxGroup,
  OwnDialog,
  OwnDialogHeading,
  OwnDialogContent,
  OwnDialogFooter,
} from '@/ui'

import { type Location } from '../../types'

const props = defineProps<{
  alreadySelected: string[]
  brandIds: string[]
}>()

const emit = defineEmits<{
  (event: 'close'): void
  (event: 'select', value: Location[]): void
}>()

const { t } = useI18n()

const searchQuery = ref<string | undefined>(undefined)

const isLoading = ref(true)
const displayedOptions = ref<Location[]>([])

const selectedLocations = ref<Location[]>([])

const searchInput = ref<ComponentPublicInstance | null>(null)

const selectedLocationIds = computed(() =>
  selectedLocations.value.map((location) => location.id)
)

const onLocationSelect = (locationId: string) => {
  const selectedLocation = displayedOptions.value.find(
    (location) => location.id === locationId
  )

  if (selectedLocation) {
    selectedLocations.value.push(selectedLocation)
  }
}

const onLocationDeselect = (locationId: string) => {
  const targetIdx = selectedLocations.value.findIndex(
    (location) => location.id === locationId
  )

  if (targetIdx !== -1) {
    selectedLocations.value.splice(targetIdx, 1)
  }
}

const onConfirmSelection = () => {
  // User hasn't selected anything, just dismiss the dialog
  if (selectedLocations.value.length === 0) return emit('close')

  emit('select', selectedLocations.value)
}

const fetchLocations = async () => {
  try {
    const { results } = await ConfiguredClient.locations.getLocations({
      query: {
        brandId: props.brandIds,
        query: searchQuery.value,
      },
    })

    displayedOptions.value = results.map((location) => ({
      ...location,
      label: location.name,
    }))
  } catch (err) {
    logError(err)
  } finally {
    isLoading.value = false
  }
}

watch(searchQuery, async () => {
  await fetchLocations()
})

onMounted(async () => {
  isLoading.value = true
  await fetchLocations()
})
</script>

<style lang="scss" scoped>
.location-selector-dialog {
  &__filter-bar {
    padding: 16px 24px;
    border-bottom: 1px dashed $background-divider;
  }
}
</style>
