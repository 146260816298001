import { type Olympus } from '@owner/olympus-client'

export const navItemKey = (item: Olympus.Funnel.RenderedNavPath): string => {
  if (item.type === 'page') {
    return `${item.type}-${item.params.label}`
  } else if (item.type === 'action') {
    return `${item.type}-${item.params.action}`
  } else if (item.type === 'partner') {
    return `${item.type}-${item.params.link}`
  } else if (item.type === 'external') {
    return `${item.type}-${item.params.label}`
  }

  return ''
}
