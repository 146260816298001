import en from './en.js'

export default [
  {
    flag: 'https://cdn.vuetifyjs.com/images/flags/us.png',
    label: 'English',
    messages: en,
    name: 'en',
  },
]
