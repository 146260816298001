const emptyToNull = (dataToConvert) => {
  if (dataToConvert === null || dataToConvert === undefined) return null

  if (typeof dataToConvert === 'string') {
    if (dataToConvert === '') return null
  }

  if (typeof dataToConvert === 'object') {
    const convertedData = {}

    for (const key in dataToConvert) {
      const value = dataToConvert[key]
      convertedData[key] = value === '' ? null : value
    }

    return convertedData
  }

  return dataToConvert
}

export { emptyToNull }
