import { PhFileText } from '@phosphor-icons/vue'

import { type BuilderBlockModule } from '@/lib/builder'
import i18nPlugin from '@/plugins/i18n'

import {
  type WebsiteResource,
  type PdfSectionCreate,
  type PdfSection,
  type PdfSectionUpdate,
} from '../../WebsiteBuilder.types'
import { websiteBlockMethods } from '../common/methods'

import PdfEditor from './editor/PdfEditor.vue'
import { createPdfData } from './methods/createPdfData'
import PdfPreview from './preview/PdfPreview.vue'

const i18n = i18nPlugin.global

export const PdfBlockModule: BuilderBlockModule<
  PdfSection,
  PdfSectionCreate,
  PdfSectionUpdate,
  WebsiteResource
> = {
  createData: createPdfData,
  editor: PdfEditor,
  meta: [
    {
      icon: PhFileText,
      key: 'pdf',
      label: i18n.t('website-builder.blocks.pdf'),
      sort: 6,
    },
  ],
  methods: websiteBlockMethods,
  preview: PdfPreview,
}
