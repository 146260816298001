import { applyMetaToChildren } from '@/utils/router'

export const authRouter = applyMetaToChildren(
  [
    {
      path: '',
      redirect: 'auth.login',
    },
    {
      component: async () => import('../views/LoginView.vue'),
      meta: {
        title: 'auth.login',
      },
      name: 'auth.login',
      path: 'login',
    },
    {
      component: async () => import('../views/MultiFactorAuthSentView.vue'),
      meta: {
        title: 'auth.multi-factor-auth',
      },
      name: 'auth.multiFactorAuth',
      path: 'multi-factor-auth',
      props: true,
    },
    {
      component: async () => import('../views/MultiFactorAuthVerifyView.vue'),
      meta: {
        title: 'auth.multi-factor-auth-verify',
      },
      name: 'auth.multiFactorAuthVerify',
      path: 'multi-factor-auth/verify',
    },
    {
      component: async () => import('../views/SubscribeView.vue'),
      meta: {
        title: 'auth.subscribe',
      },
      name: 'auth.subscribe',
      path: 'subscribe',
    },
    {
      component: async () => import('../views/RegisterView.vue'),
      meta: {
        title: 'auth.register',
      },
      name: 'auth.register',
      path: 'register',
    },
    {
      component: async () => import('../views/DirectRegisterView.vue'),
      meta: {
        title: 'auth.direct-register',
      },
      name: 'auth.direct-register',
      path: 'direct-register',
    },
    {
      component: async () => import('../views/ForgotPasswordView.vue'),
      meta: {
        title: 'auth.forgot-password',
      },
      name: 'auth.forgot-password',
      path: 'forgot-password',
    },
    {
      component: async () => import('../views/ResetPasswordView.vue'),
      meta: {
        title: 'auth.reset-password',
      },
      name: 'auth.reset-password',
      path: 'reset-password',
    },
  ],
  {
    authenticated: false,
  }
)
