import { isDefined } from '@vueuse/core'
import { type RouteLocationRaw } from 'vue-router'

import {
  type BlockCore,
  type BuilderModuleConfig,
  type ResourceCore,
} from '@/lib/builder'
import { logError } from '@/logger'
import globalStore from '@/store'

export interface RouteManager {
  customPage: (
    page: string,
    slug?: string,
    returnLocation?: RouteLocationRaw
  ) => Promise<RouteLocationRaw>
  editBlock: (slug: string, blockId: string) => Promise<RouteLocationRaw>
  editResourcePage: (
    slug: string,
    returnLocation?: RouteLocationRaw
  ) => Promise<RouteLocationRaw>
  newPage: (returnLocation?: RouteLocationRaw) => Promise<RouteLocationRaw>
}

export const useBuilderRouter = <
  TResource extends ResourceCore,
  TResourceCreate,
  TResourceUpdate,
  TBlock extends BlockCore,
  TBlockCreate,
  TBlockUpdate,
>(
  config: BuilderModuleConfig<
    TResource,
    TResourceCreate,
    TResourceUpdate,
    TBlock,
    TBlockCreate,
    TBlockUpdate
  >
): RouteManager => {
  const { resourceName, root } = config.options

  const updateReturnLocation = async (
    returnLocation?: RouteLocationRaw
  ): Promise<void> => {
    await globalStore.dispatch(
      `${config.options.storeModule}/_builder_setReturnLocation`,
      returnLocation
    )
  }

  return {
    async customPage(
      page: string,
      slug?: string,
      returnLocation?: RouteLocationRaw
    ): Promise<RouteLocationRaw> {
      const foundPage = config.customPages.find(
        (customPage) => customPage.slug === page
      )

      if (!isDefined(foundPage)) {
        logError(new Error('Unable to find custom page'))
      }

      let name

      if (foundPage?.asRoot && foundPage?.perResource) {
        name = `${root}.${foundPage.slug}.detail`
      } else if (foundPage?.asRoot) {
        name = `${root}.${foundPage.slug}`
      } else {
        name = `${root}.${resourceName}.${page}`
      }

      await updateReturnLocation(returnLocation)

      return {
        name,
        params: slug
          ? {
              slug,
            }
          : undefined,
      }
    },
    async editBlock(slug: string, blockId: string): Promise<RouteLocationRaw> {
      return {
        name: `${root}.${resourceName}.edit.block`,
        params: {
          blockId,
          slug,
        },
      }
    },
    async editResourcePage(
      slug: string,
      returnLocation?: RouteLocationRaw
    ): Promise<RouteLocationRaw> {
      await updateReturnLocation(returnLocation)

      return {
        name: `${root}.${resourceName}.edit`,
        params: {
          slug,
        },
      }
    },
    async newPage(
      returnLocation?: RouteLocationRaw
    ): Promise<RouteLocationRaw> {
      await updateReturnLocation(returnLocation)

      return {
        name: `${root}.${resourceName}.new`,
      }
    },
  }
}
