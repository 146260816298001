import type { CoreLocation, CoreState } from '../../types'

export const getActiveLocations = (
  state: CoreState
): CoreLocation[] | undefined => {
  if (!state.activeBrand) {
    return undefined
  }

  return state.locations.filter(
    (location) => location.brandId === state.activeBrand
  )
}
