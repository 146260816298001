import merge from 'lodash/merge'

import { useBuilder } from '@/lib/builder'

import { type VideoSection } from '../../../WebsiteBuilder.types'

const websiteBuilderQueue = useBuilder('websiteBuilder')

const VideoCommands = {
  updateBlock: websiteBuilderQueue.createBlockCommand<VideoSection>({
    commit: async (currentData, ctx) => {
      return merge(currentData, ctx.update)
    },
  }),
}

export { VideoCommands }
