/**
 * Linearly interpolate between two points
 * @param {Array<number>} pointOne First Point [x1, y1]
 * @param {Array<number>} pointTwo Second Point [x2, y2]
 * @param {number} targetValue Value to get value from
 */
const linterp = (pointOne, pointTwo, targetValue) => {
  const [x1, y1] = pointOne
  const [x2, y2] = pointTwo

  return y1 + (targetValue - x1) * ((y2 - y1) / (x2 - x1))
}

export { linterp }
