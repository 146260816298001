<template>
  <div class="text-color-secondary flex-row gap-3">
    <template v-for="action of props.actions" :key="action.value">
      <OwnPopover
        v-if="action.info"
        class="own-action-list__info"
        align-y="bottom"
        align-x="left"
        trigger-type="hover"
        content-offset="0"
        content-width="100"
        :slide="['x']"
      >
        <template #trigger="{ on }">
          <button
            class="own-action-list__icon-button"
            v-on="on"
            @click.stop="$emit('action', action.value as T)"
          >
            <component :is="action.icon" size="24" />
          </button>
        </template>
        <template #content>
          <OwnCard class="own-action-list__content" control>
            <OwnType :text="action.info" variant="subtitle" />
          </OwnCard>
        </template>
      </OwnPopover>
      <button v-else @click.stop="$emit('action', action.value as T)">
        <component :is="action.icon" size="24" />
      </button>
    </template>
  </div>
</template>

<script lang="ts" setup generic="T extends string">
import { OwnCard } from '../../OwnCard'
import { OwnPopover } from '../../OwnPopover'
import { OwnType } from '../../OwnType'
import { ActionSet } from '../types'

const props = withDefaults(
  defineProps<{
    actions?: ActionSet<T>
  }>(),
  {
    actions: () => [],
  }
)

defineEmits<{ action: [action: T] }>()
</script>

<style lang="scss">
.own-action-list {
  &__info {
    height: 24px;
  }

  &__icon-button {
    height: 24px;
  }

  &__content {
    padding: 8px 12px;
  }
}
</style>
