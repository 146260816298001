/**
 * Tests if url is part of a list of safe urls
 * @param {object} providedURL Url to test(Browser url api)
 * @param {object|Array<object>} safeOptions Safe urls (Browser url api)
 * @returns {boolean} Is safe
 */
export const isSafeOrigin = (providedURL, safeOptions) => {
  let normalizedSafeOptions

  if (Array.isArray(safeOptions)) {
    normalizedSafeOptions = safeOptions
  } else {
    normalizedSafeOptions = [safeOptions]
  }

  return normalizedSafeOptions
    .map((option) => option.host)
    .includes(providedURL.host)
}
