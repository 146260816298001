<template>
  <div class="own-scroll-container">
    <div class="own-scroll-container__scroll-container">
      <slot />
    </div>
  </div>
</template>
<style lang="scss">
.own-scroll-container {
  height: 100%;
  overflow: hidden;

  &__scroll-container {
    height: 100%;
    overflow-y: auto;
  }
}
</style>
