import defaultBrandState from './submodules/brand/state'
import defaultLocationState from './submodules/location/state'

export default {
  RESET_STATE(state) {
    state.activeBrand = defaultBrandState.activeBrand
    state.activeLocation = defaultLocationState.activeLocation
    state.brands = defaultBrandState.brands
    state.locations = defaultLocationState.locations
  },
}
