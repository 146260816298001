import { type ActionContext } from 'vuex'

import {
  type BlockCore,
  type BuilderState,
  type ResourceCore,
} from '@/lib/builder'
import { type CoreModule } from '@/lib/store'

export const forceDiscardAllCommands = async <
  TResource extends ResourceCore,
  TBlock extends BlockCore,
>({
  commit,
}: ActionContext<
  BuilderState<TResource, TBlock>,
  CoreModule
>): Promise<void> => {
  // TODO: before-builder-deploy - This should actually call revert() on all commands
  //  to revert up created or deleted resources

  commit('_builder_FORCE_DISCARD_ALL_COMMANDS')
}
