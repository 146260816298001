import { isDefined } from '@vueuse/core'
import {
  type RouteLocationNamedRaw,
  type RouteLocationNormalized,
} from 'vue-router'

export const toRouteLocation = (
  route: RouteLocationNormalized
): RouteLocationNamedRaw => {
  const { name, params, query } = route

  if (!isDefined(name))
    throw new Error('unable to transform into unknown location')

  return {
    name,
    params,
    query,
  }
}
