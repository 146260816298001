import createMutationsSharer from 'vuex-shared-mutations'

/**
 * Shares mutations between browsers windows and tabs to avoid race conditions/state conflicts
 */
export default createMutationsSharer({
  predicate: (mutation) => {
    const { type } = mutation
    const prefix = type.split('/')[0]
    return ['app', 'session'].includes(prefix)
  },
})
