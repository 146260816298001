<i18n locale="en">
{
  "input-labels": {
    "title": "Title",
    "description": "Description",
    "images": "Gallery Images",
    "image-cropper": "Gallery Image"
  }
}
</i18n>

<template>
  <div class="flex-col gap-8">
    <OwnInputContainer
      :label="t('input-labels.title')"
      :errors="mapErrorMessage(v$.title.$errors)"
    >
      <OwnInput v-model="title" :placeholder="t('input-labels.title')" />
    </OwnInputContainer>
    <OwnInputContainer
      :label="t('input-labels.description')"
      :errors="mapErrorMessage(v$.description.$errors)"
    >
      <OwnInput
        v-model="description"
        :placeholder="t('input-labels.description')"
      />
    </OwnInputContainer>
    <OwnInputContainer :label="t('input-labels.images')">
      <MultiImageInput
        :aspect-ratio="1"
        :model-value="currentBlockParams?.images ?? []"
        :label="t('input-labels.image-cropper')"
        @change="onChange"
      />
    </OwnInputContainer>
  </div>
</template>

<script lang="ts" setup>
import { useVuelidate, ValidationArgs } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { refDebounced } from '@vueuse/core'
import { computed, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { MultiImageInput } from '@/components/inputs'
import { logWarn } from '@/logger'
import { useMappedGetter } from '@/store'
import { OwnInputContainer, OwnInput } from '@/ui'
import { useAnalytics } from '@/utils'
import { mapErrorMessage } from '@/utils/vuelidate'

import { GallerySection } from '../../../WebsiteBuilder.types'
import { GalleryCommands } from '../commands/GalleryCommands'

const props = defineProps<{
  /**
   * ID getting passed to the editor component
   */
  id: string
}>()

const { t } = useI18n()
const analytics = useAnalytics()

const blockById = useMappedGetter<(id: String) => GallerySection | undefined>(
  'websiteBuilder/blockById'
)
const currentBlockParams = computed(() => blockById.value(props.id)?.params)

const rules: ValidationArgs<{ description: string; title: string }> = {
  description: {},
  title: {
    required,
  },
}
const description = ref<string>()
const descriptionDebounced = refDebounced(description, 250)
const title = ref<string>()
const titleDebounced = refDebounced(title, 250)
const v$ = useVuelidate(
  rules,
  { description: descriptionDebounced, title: titleDebounced },
  { $autoDirty: true }
)
onMounted(() => {
  description.value = currentBlockParams.value?.description ?? undefined
  title.value = currentBlockParams.value?.title
})

const fireAnalyticsEvent = (type: 'upload' | 'remove' | 'reorder') => {
  switch (type) {
    case 'upload':
      analytics.track('Gallery Image Added')
      break
    case 'remove':
      analytics.track('Gallery Image Removed')
      break
    case 'reorder':
      analytics.track('Gallery Images Reordered')
      break
    default:
      logWarn(`Gallery Editor - Unsupported analytics event: ${type}`)
  }
}
const onChange = async ({
  data,
  type,
}: {
  data: string[]
  type: 'upload' | 'remove' | 'reorder'
}) => {
  await GalleryCommands.updateBlock({
    targetId: props.id,
    update: {
      params: {
        images: data,
      },
    },
  })

  fireAnalyticsEvent(type)
}

watch(descriptionDebounced, async (value) => {
  if (!v$.value.$validate()) {
    return
  }

  await GalleryCommands.updateBlock({
    targetId: props.id,
    update: {
      params: {
        description: value ?? null,
      },
    },
  })
})
watch(titleDebounced, async (value) => {
  if (!v$.value.$validate()) {
    return
  }

  await GalleryCommands.updateBlock({
    targetId: props.id,
    update: {
      params: {
        title: value,
      },
    },
  })
})
</script>
