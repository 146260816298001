<i18n locale="en">
{
  "title": "Create Page",
  "actions": {
    "create": "Create"
  }
}
</i18n>

<template>
  <OwnDialog :model-value="show" @cancel="$emit('cancel')">
    <template #heading>
      <OwnDialogHeading :title="t('title')" @cancel="$emit('cancel')" />
    </template>

    <OwnDialogContent scroll>
      <CreateTemplateForm
        v-model="formValues"
        v-model:valid="isFormValid"
        @slug-exists="handleSlugExists"
      />
    </OwnDialogContent>

    <template #footer>
      <OwnDialogFooter>
        <OwnButton
          :text="t('actions.create')"
          primary
          :disabled="!isFormValid || slugExists"
          :processing="isCreating"
          @click="onCreate"
        >
          <template #icon-right>
            <PhArrowCircleRight size="24" />
          </template>
        </OwnButton>
      </OwnDialogFooter>
    </template>
  </OwnDialog>
</template>

<script>
import { PhArrowCircleRight } from '@phosphor-icons/vue'
import { useI18n } from 'vue-i18n'

import {
  OwnButton,
  OwnDialog,
  OwnDialogContent,
  OwnDialogFooter,
  OwnDialogHeading,
} from '@/ui'

import CreateTemplateForm from './CreateTemplateForm.vue'

export default {
  name: 'CreateTemplateDialog',
  components: {
    CreateTemplateForm,
    OwnButton,
    OwnDialog,
    OwnDialogContent,
    OwnDialogFooter,
    OwnDialogHeading,
    PhArrowCircleRight,
  },
  props: {
    /** True when builder-core is instantiating the page template */
    isCreating: { type: Boolean, default: false },
    /** Show Modal */
    show: { type: Boolean, default: false },
  },
  emits: ['cancel', 'create'],
  setup() {
    const { t } = useI18n()

    return { t }
  },
  data() {
    return {
      formValues: {
        slug: null,
        title: null,
      },
      isFormValid: false,
      slugExists: false,
    }
  },
  methods: {
    handleSlugExists(exists) {
      this.slugExists = exists
    },
    onCreate() {
      const { formValues, isFormValid } = this

      if (isFormValid) {
        this.$emit('create', {
          path: {
            params: {
              slug: formValues.slug,
            },
          },
          title: formValues.title,
        })
      }
    },
  },
}
</script>
