export default {
  /**
   * Returns the ACL object for the current user
   * @param {object} state Vuex state
   */
  acl: (state) => {
    const { brands, locations } = state.user
    return { brands, locations }
  },

  /**
   * Returns the CSRF Token for the current session
   * @param {object} state Vuex state
   * @returns {string} a CSRF token
   */
  csrfToken: (state) => state.csrfToken,

  /**
   * Returns the datetime at which the current session will expire
   * @param {object} state Vuex state
   * @returns {Date} expiration date
   */
  expiresAt: (state) => state.expiresAt,

  /**
   * Returns the default home route for the current user
   * @param {object} state Vuex state
   * @returns {object|string} A Vue-router route
   */
  home: (state) => {
    const {
      user: { locations, brands },
    } = state

    if (locations && locations.length === 1) {
      return {
        name: 'brand.home',
        params: {
          brandId: brands[0],
        },
      }
    }

    return state.home
  },

  /**
   * Returns whether the app is authenticated or not
   * @param {object} state Vuex state
   * @returns {boolean} true if the user is authenticated
   */
  isAuthenticated: (state) => !!Object.keys(state.user).length,

  /**
   * Returns if the current user is "super admin"
   * @param {object} state Vuex state
   * @returns {boolean} The current user's role
   */
  isSuperAdmin: (state) => state.user.role === 'pb:super',

  /**
   * Returns the current user's location notifications
   * @param {object} state Vuex state
   * @returns {object} a Profitboss user.notifications object
   */
  notifications: (state) => (state.user ? state.user.notifications : {}),

  /**
   * Returns the current user's role
   * @param {object} state Vuex state
   * @returns {string} The current user's role
   */
  role: (state) => state.user.role,

  /**
   * Returns a list of scopes for the current user
   * @param {object} state Vuex state
   * @returns {string[]} an array of scopes
   */
  scopes: (state) => state.scopes,

  /**
   * Returns the current authenticated user
   * @param {object} state Vuex state
   * @returns {object} a Profitboss user object
   */
  user: (state) => state.user,
}
