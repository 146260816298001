import { ConfiguredClient } from '@/api'

import { type Form, type FormUpdate } from '../../types/FormBuilder.types'

export const updateResourceHandler = async (
  formId: string,
  { sections, title, description, submit }: FormUpdate
): Promise<Form> => {
  return await ConfiguredClient.forms.v1.forms.form.updateForm({
    body: { description, sections, submit, title },
    params: { formId },
  })
}
