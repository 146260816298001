import { useEmitter } from './useEmitter'

export const useNotify =
  () =>
  (
    text: string,
    mode: 'error' | 'success' | 'info' | 'warning',
    options: {
      onClose?: () => void
    } = {}
  ): void => {
    useEmitter.emit('root-notification', {
      mode,
      text,
      ...options,
    })
  }
