<template>
  <div class="own-touch-radio-group" :style="style">
    <OwnOption
      v-for="{ icon, label, value: optionValue } of options"
      :key="optionValue"
      :active="optionValue === selected"
      @click="selected = optionValue"
    >
      <div class="flex-row gap-4 align-center justify-between w-full">
        <div class="flex-row gap-4 align-center justify-start">
          <component :is="icon" size="24" />

          <OwnType :text="label" variant="subtitle" color="primary" />
        </div>

        <PhCheckCircle
          class="own-touch-radio-group__icon"
          :class="[
            optionValue === selected && 'own-touch-radio-group__icon--active',
          ]"
          size="24"
        />
      </div>
    </OwnOption>
  </div>
</template>

<script>
import { PhCheckCircle } from '@phosphor-icons/vue'

import { OwnOption } from '../OwnOption'
import { OwnType } from '../OwnType'

export default {
  name: 'OwnTouchRadioGroup',
  components: {
    OwnOption,
    OwnType,
    PhCheckCircle,
  },
  props: {
    /**
     * Number of columns to split the list of options into on larger screens (a single column will always be used on
     * smaller screens).
     */
    columns: { type: Number, default: 1 },

    /**
     * @model
     */
    modelValue: { type: String, default: undefined },

    /**
     * Select Options
     */
    options: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['update:modelValue'],
  computed: {
    selected: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      },
    },
    style() {
      const { columns } = this

      return {
        '--own-touch-radio-group-columns': columns,
      }
    },
  },
}
</script>

<style lang="scss">
.own-touch-radio-group {
  display: grid;
  gap: 12px;

  @include respond-to('md-and-up') {
    grid-template-columns: repeat(
      var(--own-touch-radio-group-columns, 1),
      auto
    );
  }

  &__icon {
    flex-shrink: 0;
    color: transparent;

    &--active {
      color: $bluetiful-500;
    }
  }
}
</style>
