<i18n locale="en">
{
  "actions": {
    "save": "Save"
  }
}
</i18n>

<template>
  <OwnButton :text="t('actions.save')" primary @click="$emit('publish')">
    <template #icon-right>
      <PhCheckCircle size="24" />
    </template>
  </OwnButton>
</template>

<script lang="ts" setup>
import { PhCheckCircle } from '@phosphor-icons/vue'
import { useI18n } from 'vue-i18n'

import { OwnButton } from '@/ui'

defineEmits<{ publish: [] }>()

const { t } = useI18n()
</script>
