<template>
  <div
    class="preview-nav-link"
    :style="active && { borderColor: brandActiveColor }"
  >
    <PreviewTypographyLegacy :text="label" variant="buttonSmall" />
  </div>
</template>

<script>
import PreviewTypographyLegacy from './legacy/PreviewTypographyLegacy'
import { useMergedTheme } from './mixins/useMergedTheme'

export default {
  name: 'PreviewNavLink',
  components: {
    PreviewTypographyLegacy,
  },
  mixins: [useMergedTheme],
  props: {
    /**
     * Show active version
     */
    active: { type: Boolean, default: false },

    /**
     * label to render
     */
    label: { type: String, required: true },
  },
  computed: {
    brandActiveColor() {
      const { mergedTheme } = this

      return mergedTheme.colors.brand.primary
    },
  },
}
</script>

<style lang="scss" scoped>
.preview-nav-link {
  padding: 8px 0;
  border-bottom: 1px solid transparent;
}
</style>
