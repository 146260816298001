export default {
  activeLocationSpecialHours: (state, _, __, rootGetters) => {
    const locationId = rootGetters['core/location/active/id']

    return state.specialHours[locationId] ?? []
  },
  locationSpecialHours: (state) => (locationId) => {
    return state.specialHours[locationId] ?? []
  },
}
