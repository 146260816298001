import { parseToMinorUnit, toMajorUnit } from '@owner/currency'
import { type Olympus } from '@owner/olympus-client'

interface EditableSalary {
  max: string | null
  min: string | null
}

/**
 * Coerce salary to a) the correct unit and b) strings so it can be used in input fields.
 * @param salary Salary
 */
export function prepareSalaryForEditing(
  salary: Olympus.Careers.Job['salary']
): EditableSalary {
  return {
    max: salary.max ? toMajorUnit(salary.max).toString() : null,
    min: salary.min ? toMajorUnit(salary.min).toString() : null,
  }
}

/**
 *
 * @param salary Edited salary
 */
export function prepareSalaryForSubmission(
  salary: EditableSalary
): Olympus.Careers.Job['salary'] {
  return {
    max: salary.max ? parseToMinorUnit(salary.max) : null,
    min: salary.min ? parseToMinorUnit(salary.min) : null,
  }
}
