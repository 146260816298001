import { type CreateBlockData } from '@/lib/builder'

import {
  type FormSectionCreate,
  type FormSection,
} from '../FormBlockModule.types'

export const createFormData = ({
  brandId,
}: CreateBlockData<FormSection>): FormSectionCreate => {
  return {
    brandId,
    params: {
      formId: '',
    },
    type: 'form',
  }
}
