<template>
  <div class="icon-picker">
    <button
      v-for="(icon, key) in icons"
      :key="key"
      :class="[
        'icon-picker__option',
        selected === key && 'icon-picker__option--active',
        'flex-col align-center justify-center',
      ]"
      :aria-label="key"
      :aria-pressed="selected === key"
      @click="handleSelect(key)"
    >
      <component :is="icon" size="24" />
    </button>
  </div>
</template>
<script>
import { mapModel } from '@/utils/computed'

export default {
  name: 'IconPicker',
  props: {
    /**
     * Map of icon names/keys to icon components
     */
    icons: { type: Object, required: true },

    /**
     * Selected icon
     * @model
     */
    modelValue: { type: String, default: null },
  },
  emits: ['update:modelValue'],
  computed: {
    ...mapModel('selected'),
  },
  methods: {
    handleSelect(icon) {
      const { selected } = this

      this.selected = icon === selected ? null : icon
    },
  },
}
</script>
<style lang="scss" scoped>
.icon-picker {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 8px;

  &__option {
    width: 45px;
    height: 45px;
    border-radius: 8px;
    border: 1px solid $background-divider;
    color: $text-color-secondary;

    &--active {
      color: $text-color-primary;
      background-color: $background-selected;
    }
  }
}
</style>
