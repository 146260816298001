import { type CreateBlockData } from '@/lib/builder'

import {
  type VideoSectionCreate,
  type VideoSection,
} from '../../../WebsiteBuilder.types'

export const createVideoData = ({
  brandId,
}: CreateBlockData<VideoSection>): VideoSectionCreate => ({
  brandId,
  params: {
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    header: 'Video Section',
    video: {
      params: {
        id: 'dxbz2kUa58g',
      },
      type: 'youtube',
    },
  },
  type: 'video',
})
