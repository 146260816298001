<template>
  <OwnPopover
    :align-x="alignX"
    :align-y="alignY"
    :slide="slide"
    :content-width="contentWidth"
    :content-height="contentHeight"
    :use-show="useShow"
    trigger-type="click"
  >
    <template #trigger="{ on }">
      <slot name="trigger" :on="on" />
    </template>
    <template #content="{ close }">
      <OwnCard :border-color="contentBorderColor" shadow>
        <slot name="content" :close="close" />
      </OwnCard>
    </template>
  </OwnPopover>
</template>
<script>
import { OwnCard } from '../OwnCard'
import { OwnPopover } from '../OwnPopover'

export default {
  name: 'OwnMenu',
  components: {
    OwnCard,
    OwnPopover,
  },
  props: {
    /** Alignment along x axis */
    alignX: {
      type: String,
      default: 'center',
      validator: (v) => ['left', 'right', 'center'].includes(v),
    },

    /** Alignment along y axis */
    alignY: {
      type: String,
      default: 'bottom',
      validator: (v) => ['top', 'bottom', 'center'].includes(v),
    },

    /** Color of the content border */
    contentBorderColor: {
      type: String,
      default: undefined,
    },

    /** Width of the content block */
    contentHeight: {
      type: [Number, String],
      default: 150,
    },

    /** Width of the content block */
    contentWidth: {
      type: [Number, String],
      default: 150,
    },

    /** Prioritize Axis */
    slide: {
      type: Array,
      default: undefined,
      validator: (v) => {
        const valid = ['x', 'y']
        return v.every((current) => valid.includes(current))
      },
    },

    /** Use Show */
    useShow: { type: Boolean, default: false },
  },
}
</script>
