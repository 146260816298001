<i18n locale="en">
{
  "action": "Go back and update"
}
</i18n>

<template>
  <div class="flex-row align-center justify-end">
    <OwnButton :text="t(`action`)" primary @click="goToBuilder" />
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'

import { OwnButton } from '@/ui'

import { emailBuilderRouteManager } from '../../../builders/email'
import { textBuilderRouteManager } from '../../../builders/text'

export default {
  name: 'InvalidConfigurationFooter',
  components: { OwnButton },
  props: {
    campaign: { type: Object, default: null },
  },
  emits: ['close'],
  setup() {
    const { t } = useI18n()

    return { t }
  },
  methods: {
    async goToBuilder() {
      const routeManager =
        this.campaign.transport === 'sms'
          ? textBuilderRouteManager
          : emailBuilderRouteManager

      const resourcePageRoute = await routeManager.editResourcePage(
        this.campaign.id
      )

      await this.$router.push(resourcePageRoute)

      this.$emit('close') // Close the dialog if already on the builder page
    },
  },
}
</script>
