import { PhArticleMedium, PhFile, PhImageSquare } from '@phosphor-icons/vue'

import { type TemplateOption } from '@/lib/builder'
import i18nPlugin from '@/plugins/i18n'

import {
  type WebsiteResourceCreate,
  type WebsiteSectionCreate,
} from '../WebsiteBuilder.types'

import { articleTemplate } from './articleTemplate'
import { blankTemplate } from './blankTemplate'
import { showcaseTemplate } from './showcaseTemplate'
const i18n = i18nPlugin.global

const templateOptions: Array<
  TemplateOption<WebsiteResourceCreate, WebsiteSectionCreate>
> = [
  {
    fetchData: articleTemplate,
    icon: PhArticleMedium,
    value: 'article',
  },
  {
    fetchData: showcaseTemplate,
    icon: PhImageSquare,
    value: 'showcase',
  },
  {
    fetchData: blankTemplate,
    icon: PhFile,
    value: 'blank',
  },
].map((option) => ({
  ...option,
  label: i18n.t(`website-builder.options.${option.value}`),
}))

export default {
  description: i18n.t('website-builder.description'),
  heading: i18n.t('website-builder.heading'),
  options: templateOptions,
  title: i18n.t('website-builder.title'),
}
