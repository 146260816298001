<i18n locale="en">
{
  "custom": "Custom",
  "pay": "Pay",
  "processing-fee": "Processing Fee",
  "subtotal": "Subtotal",
  "total": "Total",
  "sections": {
    "amount": "Gift Card Amount",
    "from": "From",
    "payment-method": "Payment Method",
    "to": "To",
    "when": "When Should We Send It?"
  },
  "fields": {
    "card-number": "Card Number",
    "delivery-time": "Delivery Time",
    "deliver-now": "Deliver Now",
    "deliver-later": "Deliver Later",
    "email": "Email",
    "expiry-date": "Expiry Date",
    "name": "Name",
    "note": "Note",
    "security-code": "Security Code",
    "send-to-myself": "Send to myself"
  },
  "placeholders": {
    "card-number": "1234 1234 1234 1234",
    "expiry-date": "MM / YY",
    "security-code": "CVC",
    "your-email": "Your Email",
    "your-name": "Your Name"
  }
}
</i18n>

<template>
  <div class="gift-card-form flex-col gap-10">
    <div class="flex-col gap-3">
      <PreviewTypographyLegacy
        variant="titleLarge"
        :text="t('sections.amount')"
      />
      <div class="flex-row gap-2">
        <PreviewButtonLegacy
          v-for="(amount, index) in giftCardAmounts"
          :key="amount"
          class="gift-card-form__amount-button"
          size="zero"
          :variant="index === 2 ? 'contained' : 'text'"
        >
          <PreviewTypographyLegacy
            variant="titleSmall"
            :color="index === 2 ? 'white' : 'secondary'"
            :text="amount"
          />
        </PreviewButtonLegacy>
      </div>
    </div>
    <div class="flex-col gap-3">
      <PreviewTypographyLegacy
        class="gift-card-form__section-heading"
        variant="titleLarge"
        :text="t('sections.from')"
      />
      <div class="flex-row gap-6 justify-evenly">
        <div class="w-full flex-col gap-2">
          <PreviewTypographyLegacy
            variant="titleLarge"
            :text="t('fields.name')"
          />
          <PreviewTextField :placeholder="t('placeholders.your-name')" />
        </div>
        <div class="w-full flex-col gap-2">
          <PreviewTypographyLegacy
            variant="titleLarge"
            :text="t('fields.email')"
          />
          <PreviewTextField :placeholder="t('placeholders.your-email')" />
        </div>
      </div>
      <div class="flex-col gap-2">
        <PreviewTypographyLegacy
          class="gift-card-form__section-heading"
          variant="titleLarge"
          :text="t('fields.note')"
        />
        <PreviewTextField :placeholder="t('fields.note')" />
      </div>
      <div class="gift-card-form__send-to-myself flex-row gap-2">
        <PreviewCheckbox />
        <PreviewTypographyLegacy
          variant="paragraphRegular"
          color="primary"
          :text="t('fields.send-to-myself')"
        />
      </div>
    </div>
    <div class="flex-col gap-3">
      <PreviewTypographyLegacy
        class="gift-card-form__section-heading"
        variant="titleLarge"
        :text="t('sections.to')"
      />
      <div class="flex-row gap-6 justify-evenly">
        <div class="w-full flex-col gap-2">
          <PreviewTypographyLegacy
            variant="titleLarge"
            :text="t('fields.name')"
          />
          <PreviewTextField :placeholder="t('fields.name')" />
        </div>
        <div class="w-full flex-col gap-2">
          <PreviewTypographyLegacy
            variant="titleLarge"
            :text="t('fields.email')"
          />
          <PreviewTextField :placeholder="t('fields.email')" />
        </div>
      </div>
    </div>
    <div class="flex-col gap-3">
      <PreviewTypographyLegacy
        class="gift-card-form__section-heading"
        variant="titleLarge"
        :text="t('sections.when')"
      />
      <div class="flex-row justify-between">
        <PreviewTypographyLegacy
          variant="paragraphRegular"
          color="primary"
          :text="t('fields.deliver-now')"
        />
        <PreviewRadio checked />
      </div>
      <PreviewHr />
      <div class="flex-row justify-between">
        <PreviewTypographyLegacy
          variant="paragraphRegular"
          color="placeholder"
          :text="t('fields.deliver-later')"
        />
        <PreviewRadio />
      </div>
    </div>
    <div class="flex-col gap-3">
      <PreviewTypographyLegacy
        class="gift-card-form__section-heading"
        variant="titleLarge"
        :text="t('sections.payment-method')"
      />
      <div class="w-full flex-col gap-2">
        <PreviewTypographyLegacy
          variant="titleLarge"
          :text="t('fields.card-number')"
        />
        <PreviewTextField :placeholder="t('placeholders.card-number')" />
      </div>
      <div class="flex-row gap-6 justify-evenly">
        <div class="w-full flex-col gap-2">
          <PreviewTypographyLegacy
            variant="titleLarge"
            :text="t('fields.expiry-date')"
          />
          <PreviewTextField :placeholder="t('placeholders.expiry-date')" />
        </div>
        <div class="w-full flex-col gap-2">
          <PreviewTypographyLegacy
            variant="titleLarge"
            :text="t('fields.security-code')"
          />
          <PreviewTextField :placeholder="t('placeholders.security-code')" />
        </div>
      </div>
    </div>
    <PreviewHr />
    <div class="flex-col gap-6">
      <div class="flex-col gap-4">
        <div class="flex-row justify-between">
          <PreviewTypographyLegacy
            variant="titleRegular"
            color="secondary"
            :text="t('subtotal')"
          />
          <PreviewTypographyLegacy
            variant="titleRegular"
            color="secondary"
            :text="subtotal"
          />
        </div>
        <div class="flex-row justify-between">
          <PreviewTypographyLegacy
            variant="titleRegular"
            color="secondary"
            :text="t('processing-fee')"
          />
          <PreviewTypographyLegacy
            variant="titleRegular"
            color="secondary"
            :text="processingFee"
          />
        </div>
      </div>
      <div class="flex-row justify-between">
        <PreviewTypographyLegacy variant="titleLarge" :text="t('total')" />
        <PreviewTypographyLegacy variant="titleRegular" :text="total" />
      </div>
    </div>
    <PreviewButtonLegacy class="gift-card-form__pay-button">
      <PreviewTypographyLegacy
        variant="titleLarge"
        :text="t('pay')"
        color="white"
      />
    </PreviewButtonLegacy>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'

import {
  PreviewButtonLegacy,
  PreviewCheckbox,
  PreviewHr,
  PreviewRadio,
  PreviewTextField,
  PreviewTypographyLegacy,
} from '@/lib/builder'

export default {
  name: 'GiftCardForm',
  components: {
    PreviewButtonLegacy,
    PreviewCheckbox,
    PreviewHr,
    PreviewRadio,
    PreviewTextField,
    PreviewTypographyLegacy,
  },
  setup() {
    const { t } = useI18n()

    return { t }
  },
  data() {
    return {
      giftCardAmounts: ['$10', '$25', '$50', '$75', '$100', this.t('custom')],
      processingFee: '$2.50',
      subtotal: '$50.00',
      total: '$52.50',
    }
  },
}
</script>

<style lang="scss" scoped>
.gift-card-form {
  &__amount-button {
    padding: 12px 8px;
    border-width: 1px;
    border-color: transparent;
    width: 52px;
  }
  &__section-heading {
    padding-bottom: 16px;
  }
  &__send-to-myself {
    padding-top: 12px;
  }
  &__pay-button {
    width: 100%;
  }
}
</style>
