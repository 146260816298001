<template>
  <!-- eslint-disable vue/no-v-html -- linkify escapes HTML, so there is no risk of XSS from this content -->
  <span
    v-if="hotlinkUrls"
    :class="[
      'preview-typography',
      preserveWhitespace && 'preview-typography--preserve-whitespace',
    ]"
    :style="style"
    v-html="linkifiedText"
  />
  <!-- eslint-enable -->
  <span
    v-else
    :class="[
      'preview-typography',
      preserveWhitespace && 'preview-typography--preserve-whitespace',
    ]"
    :style="style"
    v-text="text"
  ></span>
</template>

<script lang="ts" setup>
import { getTypographyStyles, TypographyType } from '@owner/theme'
import get from 'lodash/get'
import { computed } from 'vue'

import { linkify } from '@/utils'

import { useMergedTheme } from './mixins/useMergedThemeComp'

const theme = useMergedTheme()

type Variant =
  | 'heading1'
  | 'heading2'
  | 'heading3'
  | 'heading4'
  | 'titleLarge'
  | 'titleRegular'
  | 'titleSmall'
  | 'paragraphLarge'
  | 'paragraphRegular'
  | 'paragraphSmall'
  | 'buttonLarge'
  | 'buttonRegular'
  | 'buttonSmall'

const props = withDefaults(
  defineProps<{
    align?: 'left' | 'center' | 'right'
    color?:
      | 'primary'
      | 'secondary'
      | 'placeholder'
      | 'brand'
      | 'success'
      | 'info'
      | 'warning'
      | 'danger'
      | 'white'
      | 'black'
      | 'inherit'
    text: string
    variant: Variant
    hotlinkUrls?: boolean
    preserveWhitespace?: boolean
  }>(),
  {
    align: 'left',
    color: undefined,
    hotlinkUrls: false,
    preserveWhitespace: false,
  }
)

const variantMapping: {
  [v in Variant]: TypographyType
} = {
  buttonLarge: 'buttonLg',
  buttonRegular: 'buttonMd',
  buttonSmall: 'buttonSm',
  heading1: 'title5xl',
  heading2: 'title4xl',
  heading3: 'title3xl',
  heading4: 'title2xl',
  paragraphLarge: 'bodyLg',
  paragraphRegular: 'bodyMd',
  paragraphSmall: 'bodySm',
  titleLarge: 'titleXl',
  titleRegular: 'titleLg',
  titleSmall: 'titleMd',
} as const

const pairing = computed(() => theme.value?.font)

const selectedPairing = computed(() => {
  return props.variant.startsWith('paragraph') ? 'secondary' : 'primary'
})

const fontFamily = computed(() => {
  return pairing.value?.[selectedPairing.value] ?? 'Inter'
})

const { fontSize, lineHeight, letterSpacing, weight } = getTypographyStyles(
  'opal',
  fontFamily.value
)[variantMapping[props.variant]]

const typographyColors = {
  brand: 'brand.primary',
  danger: 'danger.400',
  info: 'info.300',
  placeholder: 'text.placeholder',
  primary: 'text.primary',
  secondary: 'text.secondary',
  success: 'success.300',
  warning: 'warning.300',
} as const

const cssColor = computed(() => {
  if (props.color === 'white') {
    return '#ffffff'
  }

  if (props.color === 'black') {
    return '#0D0D0D'
  }

  if (props.color === 'inherit') {
    return undefined
  }

  if (props.color && theme.value)
    return get(theme.value.colors, typographyColors[props.color])

  if (theme.value) {
    return get(theme.value.colors, typographyColors[selectedPairing.value])
  }

  return undefined
})

const style = computed(() => {
  return {
    color: cssColor.value,
    fontFamily: fontFamily.value,
    fontSize: `${fontSize}rem`,
    fontWeight: weight,
    letterSpacing: `${letterSpacing}rem`,
    lineHeight: `${lineHeight}rem`,
  } as const
})

const linkifiedText = computed(() => {
  if (props.hotlinkUrls) {
    return linkify(props.text, {
      className: 'preview-typography__link',
    })
  }

  return props.text
})
</script>

<style lang="scss">
/* Unscoped because the <a> tags generated by linkify don't have the scoped styles attribute */
.preview-typography .preview-typography__link {
  color: $misc-brand;
}
</style>
<style lang="scss" scoped>
.preview-typography {
  cursor: default;

  &--preserve-whitespace {
    white-space: pre-line;
  }
}
</style>
