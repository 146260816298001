<script lang="ts" setup>
import { onBeforeMount } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { formBuilderRouteManager } from '@/features/form-builder/builder/router/formBuilderModuleRouter'

const route = useRoute()
const router = useRouter()

onBeforeMount(async () => {
  const formId = route.params.slug as string

  const editFormRoute = await formBuilderRouteManager.customPage(
    'submissions',
    formId
  )

  await router.replace(editFormRoute)
})
</script>
