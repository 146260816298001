import { type Olympus } from '@owner/olympus-client'

export const defaultAnnouncement = (
  brandId: string
): Olympus.Funnel.BannerParams => ({
  brandId,
  enabled: true,
  icon: undefined,
  link: undefined,
  title: '',
})
