import client from '../client'

/**
 * Gets a paginated list of orders for a location
 * @param {object} query A paginated query object
 * @param {string} [query.locationId] Optional Profitboss Location ID
 * @returns {Promise} resolves a list of order objects for a location
 */
export const getOrders = async (query) =>
  client.get('/orders', {
    ...query,
    includeStoreOrders: true,
  })
