<i18n locale="en">
{
  "no-data": "Design your own text!\n\nhttps://owner.com"
}
</i18n>

<template>
  <div class="text-preview flex-col justify-center">
    <div class="text-preview__bubble flex-col gap-3">
      <OwnType
        color="primary"
        variant="paragraph"
        :text="content.data?.text || t('no-data')"
        hotlink-urls
        preserve-whitespace
      />
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'

import { OwnType } from '@/ui'

export default {
  name: 'TextPreview',
  components: { OwnType },
  props: {
    content: { type: Object, required: true },
  },
  setup() {
    const { t } = useI18n()

    return { t }
  },
}
</script>

<style lang="scss" scoped>
.text-preview {
  margin-left: 50px; /* Push the edit button off to the left */

  &__bubble {
    background-color: $background-secondary;
    padding: 16px;
    border-radius: 18px;
    width: 220px;
  }
}
</style>
