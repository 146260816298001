<i18n locale="en">
{
  "actions": {
    "publish": "Publish"
  }
}
</i18n>

<template>
  <OwnButton :text="t('actions.publish')" primary @click="onClick">
    <template #icon-right>
      <PhArrowCircleUp size="24" />
    </template>
  </OwnButton>
</template>

<script>
import { PhArrowCircleUp } from '@phosphor-icons/vue'
import { useI18n } from 'vue-i18n'

import { builderProducer } from '@/lib/builder'
import { OwnButton } from '@/ui'

export default {
  name: 'AnnouncementRightActions',
  components: {
    OwnButton,
    PhArrowCircleUp,
  },
  mixins: [builderProducer],
  setup() {
    const { t } = useI18n()

    return { t }
  },
  methods: {
    onClick() {
      this.sendBuilderEvent('announcement', { topic: 'publish' })
    },
  },
}
</script>
