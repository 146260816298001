<template>
  <div class="preview-radio" :style="style">
    <div class="preview-radio__background" :style="backgroundStyle"></div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { useMergedTheme } from './mixins/useMergedThemeComp'

const props = withDefaults(
  defineProps<{
    checked?: boolean
  }>(),
  { checked: false }
)

const theme = useMergedTheme()

const backgroundStyle = computed(() => {
  return {
    backgroundColor: props.checked ? 'white' : 'transparent',
  }
})

const style = computed(() => {
  return {
    borderColor: props.checked
      ? theme.value?.colors.brand.primary
      : theme.value?.colors.background.divider,
    borderWidth: props.checked ? '8.75px' : '1px',
  }
})
</script>

<style lang="scss" scoped>
.preview-radio {
  width: 24px;
  height: 24px;
  border-style: solid;
  border-radius: 999px;

  &__background {
    height: 100%;
    border-radius: 999px;
  }
}
</style>
