import { type CreateBlockData } from '@/lib/builder'

import {
  type PdfSectionCreate,
  type PdfSection,
} from '../../../WebsiteBuilder.types'

export const createPdfData = ({
  brandId,
}: CreateBlockData<PdfSection>): PdfSectionCreate => ({
  brandId,
  params: {
    heading: 'My document',
    pdf: '',
    subheading: null,
  },
  type: 'pdf',
})
