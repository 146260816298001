import { type Olympus } from '@owner/olympus-client'
import merge from 'lodash/merge'

import i18nPlugin from '@/plugins/i18n'
const i18n = i18nPlugin.global

export const labelNavItems = (
  navItems: Olympus.Funnel.RenderedNavPath[]
): Olympus.Funnel.RenderedNavPath[] => {
  return navItems.map((item) => {
    switch (item.type) {
      case 'partner':
        return merge(item, {
          params: { title: i18n.t(`nav.partner.${item.params.link}`) },
        })
      case 'action':
        return merge(item, {
          params: { title: i18n.t(`nav.action.${item.params.action}`) },
        })
      default:
        return item
    }
  })
}

export const transformToOlympusNavItems = (
  navItems: Olympus.Funnel.RenderedNavPath[]
): Olympus.Funnel.NavigationItem[] => {
  return navItems.map((item) => {
    switch (item.type) {
      case 'partner':
        return {
          enabled: true,
          path: {
            params: {
              label: item.params.label,
              link: item.params.link,
            },
            type: 'partner',
          },
        }
      case 'action':
        return {
          enabled: true,
          path: {
            params: {
              action: item.params.action,
              label: item.params.label,
            },
            type: 'action',
          },
        }
      case 'external':
        return {
          enabled: true,
          path: {
            params: item.params,
            type: 'external',
          },
        }
      case 'page':
        return {
          enabled: true,
          path: {
            params: {
              label: item.params.label,
              pageId: item.params.pageId,
            },
            type: 'page',
          },
        }
      default:
        throw new Error('Unknown nav type')
    }
  })
}
