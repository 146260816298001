import client from '../client'

/**
 * Gets an array of unratified agreements
 * @param {string} subjectId Profitboss query
 * @returns {Promise} resolves an array of agreements
 */
export const getUnratifiedAgreements = (subjectId) =>
  client.get('/legal/v1/agreements', { ratified: false, subjectId })

/**
 * Create acceptance
 * @param {object} body Acceptance body
 * @param {string} body.agreementId Id of agreement
 * @param {string} body.subjectId Brand Id (this is named subject ID but its really the brand ID)
 * @param {string} body.type Type of agreement
 * @returns {Promise} resolves an array of agreements
 */
export const createAcceptance = (body) =>
  client.post('/legal/v1/acceptances', body)
