const createMessage = (message, vm) => {
  return `[Owner] ${message}` + (vm ? generateComponentTrace(vm) : '')
}

const classifyRE = /(?:^|[-_])(\w)/g
const classify = (str) =>
  str.replace(classifyRE, (c) => c.toUpperCase()).replace(/[-_]/g, '')

const formatComponentName = (vm, includeFile) => {
  if (vm.$root === vm) {
    return '<Root>'
  }
  const options =
    typeof vm === 'function' && vm.cid != null
      ? vm.options
      : vm._isVue
        ? vm.$options || vm.constructor.options
        : vm || {}
  let name = options.name || options._componentTag
  const file = options.__file
  if (!name && file) {
    const match = file.match(/([^/\\]+)\.vue$/)
    name = match && match[1]
  }

  return (
    (name ? `<${classify(name)}>` : `<Anonymous>`) +
    (file && includeFile !== false ? ` at ${file}` : '')
  )
}

const formatStackTrace = (vm, tree) =>
  '\n\n' +
  tree
    .map(
      (vm, i) =>
        `${i === 0 ? '---> ' : ' '.repeat(5 + i * 2)}${
          Array.isArray(vm)
            ? `${formatComponentName(vm[0])}... (${vm[1]} recursive calls)`
            : formatComponentName(vm)
        }`
    )
    .join('\n')

const generateComponentTrace = (vm) => {
  if (vm._isVue && vm.$parent) {
    const tree = []
    let currentRecursiveSequence = 0
    while (vm) {
      if (tree.length > 0) {
        const last = tree[tree.length - 1]
        if (last.constructor === vm.constructor) {
          currentRecursiveSequence++
          vm = vm.$parent
          continue
        } else if (currentRecursiveSequence > 0) {
          tree[tree.length - 1] = [last, currentRecursiveSequence]
          currentRecursiveSequence = 0
        }
      }
      tree.push(vm)
      vm = vm.$parent
    }
    return formatStackTrace(vm, tree)
  } else {
    return `\n\n(found in ${formatComponentName(vm)})`
  }
}

/**
 * Logs an info message
 * @param {string} message A message to log
 */
export function logInfo(message) {
  if (import.meta.env.MODE === 'production') return
  const newMessage = createMessage(message, this)
  // eslint-disable-next-line no-console -- allowed in the logger
  newMessage != null && console.info(newMessage)
}

/**
 * Logs a warning message
 * @param {string} message A message to log
 */
export function logWarning(message) {
  if (import.meta.env.MODE === 'production') return
  const newMessage = createMessage(message, this)
  // eslint-disable-next-line no-console -- allowed in the logger
  newMessage != null && console.warn(newMessage)
}

/**
 * Logs an error message
 * @param {string} message A message to log
 */
export function logError(message) {
  const newMessage = createMessage(message, this)
  // eslint-disable-next-line no-console -- allowed in the logger
  newMessage != null && console.error(newMessage)
}
