<i18n locale="en">
{
  "title": "You have unsaved changes",
  "message": "If you leave this page you will lose your unsaved changes, are you sure you want to discard changes?",
  "actions": {
    "continue-editing": "Continue Editing",
    "discard-changes": "Discard unsaved changes"
  }
}
</i18n>

<template>
  <OwnDialog v-model="isVisible" @cancel="onCancel">
    <template #heading="{ close }">
      <OwnDialogHeading :title="t('title')" @cancel="onCancel(close)" />
    </template>
    <template #default>
      <OwnDialogContent scroll>
        <p
          class="text-paragraph text-color-secondary"
          v-text="t('message')"
        ></p>
      </OwnDialogContent>
    </template>
    <template #footer="{ close }">
      <OwnDialogFooter>
        <OwnButton
          v-if="isDesktop"
          :text="t('actions.continue-editing')"
          @click="onCancel(close)"
        />
        <OwnButton
          :text="t('actions.discard-changes')"
          danger
          @click="onConfirmDiscard(close)"
        />
      </OwnDialogFooter>
    </template>
  </OwnDialog>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'

import {
  OwnButton,
  OwnDialog,
  OwnDialogContent,
  OwnDialogFooter,
  OwnDialogHeading,
} from '@/ui'
import { useBreakpoints } from '@/utils'

const emit = defineEmits<{
  (event: 'cancel'): void
  (event: 'discard'): void
}>()

const { isDesktop } = useBreakpoints()
const { t } = useI18n()

const isVisible = defineModel<boolean>({ default: false })

const onCancel = (close?: () => void | undefined): void => {
  emit('cancel')
  if (close) {
    close()
  }
}

const onConfirmDiscard = (close: () => void | undefined) => {
  emit('discard')
  if (close) {
    close()
  }
}
</script>
