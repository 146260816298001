import mergeWith from 'lodash/mergeWith'

import { useBuilder } from '@/lib/builder'
import { reorder } from '@/utils/helpers'

import { type CareersSection } from '../../../WebsiteBuilder.types'

const websiteBuilderQueue = useBuilder('websiteBuilder')

const updateBlock = websiteBuilderQueue.createBlockCommand<CareersSection>({
  commit: async (currentData, ctx) => {
    return mergeWith(currentData, ctx.update, (_objValue, srcValue, key) => {
      // Do not merge `jobs` because it is an array
      if (key === 'jobs') {
        return srcValue
      }
    })
  },
})

const CareersCommands = {
  /**
   * Add a job to a careers section.
   * @param current Current block value
   * @param jobId Job ID to add
   */
  async addJob(current: CareersSection, jobId: string): Promise<void> {
    const jobs = current.params.jobs.concat([jobId])

    await updateBlock({
      targetId: current.id,
      update: { params: { ...current.params, jobs } },
    })
  },

  /**
   * Remove a job from a careers section.
   * @param current Current block value
   * @param jobIndex Index of job to update
   */
  async deleteJob(current: CareersSection, jobIndex: number): Promise<void> {
    const jobs = current.params.jobs
    jobs.splice(jobIndex, 1)

    await updateBlock({
      targetId: current.id,
      update: { params: { ...current.params, jobs } },
    })
  },

  /**
   * Change the order of a job in a careers section.
   * @param current Current block value
   * @param oldIndex Current index of job
   * @param newIndex New index of job
   */
  async reorderJob(
    current: CareersSection,
    oldIndex: number,
    newIndex: number
  ): Promise<void> {
    const jobs = reorder(current.params.jobs, oldIndex, newIndex)

    await updateBlock({
      targetId: current.id,
      update: { params: { ...current.params, jobs } },
    })
  },

  updateBlock,
}

export { CareersCommands }
