<template>
  <div>
    <slot :trigger-support="triggerSupport" />
  </div>
</template>

<script>
import bus from '@/mixins/bus'

export default {
  name: 'TriggerSupport',
  mixins: [bus],
  methods: {
    triggerSupport() {
      this.$_b_emit('show-help')
    },
  },
}
</script>
