export interface ReorderEvent {
  oldIndex: number
  newIndex: number
}

/**
 * Reorders a list based on an old index and its target index
 * @param {*[]} items an arbitrary array
 * @param {number} oldIndex initial index
 * @param {number} newIndex new index
 * @returns {*[]} a reordered array
 */
export const reorder = <T>(
  items: T[],
  oldIndex: number,
  newIndex: number
): T[] => {
  const newItems = Array.from(items)
  // TODO: Tucker - is this assertion actually valid? Does this introduce an index out of range error?
  const element = newItems[oldIndex] as T
  newItems.splice(oldIndex, 1)
  newItems.splice(newIndex, 0, element)
  return newItems
}

export default reorder
