<i18n locale="en">
{
  "delete-confirm": {
    "delete": "Delete Application",
    "go-back": "Go Back",
    "message": "Are you sure you want to delete this application?"
  },
  "errors": {
    "load": "Unable to load applications"
  },
  "headers" : {
    "createdAt": "Received",
    "delete": "Delete",
    "email": "Email",
    "jobs": "Roles",
    "name": "Name",
    "phone": "Phone Number",
    "resume": "Resume"
  },
  "loading": "Getting applications...",
  "no-data": "No applications",
  "no-query-results": "No applications match that search",
  "roles":  "All | {count} role | {count} roles",
  "view-resume": "View"
}
</i18n>

<template>
  <div>
    <div class="flex-col gap-4">
      <OwnTable
        :columns="columns"
        :items="items"
        :query="requestParams.query"
        item-key="id"
        @click="viewApplicationDetails"
      >
        <template v-if="isLoading" #loading>
          <DefaultComponentLoader :message="t('loading')" />
        </template>
        <template #card="{ item }">
          <div class="w-full flex-row align-center justify-between gap-6">
            <div class="flex-col gap-2">
              <OwnType :text="item.name" color="primary" variant="subtitle" />
              <OwnType
                :text="
                  columns
                    .find((column) => column.key === 'createdAt')
                    .body.format(item.createdAt)
                "
                variant="paragraph-small"
              />
            </div>
            <button class="status-danger" @click.stop="onDelete(item.id)">
              <PhTrash size="24" />
            </button>
          </div>
        </template>
        <template #no-data>
          <DefaultEmptyState :message="t('no-data')">
            <template #icon>
              <PhUsersFour size="24" />
            </template>
          </DefaultEmptyState>
        </template>
        <template #no-query-results>
          <DefaultEmptyState :message="t('no-query-results')">
            <template #icon>
              <PhUsersFour size="24" />
            </template>
          </DefaultEmptyState>
        </template>

        <template #heading.name>
          <SearchInput v-model="requestParams.query" borderless />
        </template>

        <template #item.resume="{ item }">
          <button
            v-if="item.attachment"
            class="text-button-large bluetiful-500"
            @click.stop="openResume(item)"
            v-text="t('view-resume')"
          ></button>
        </template>

        <template #item.delete="{ item }">
          <div class="h-full flex-row align-center justify-end" justify="end">
            <button class="status-danger" @click.stop="onDelete(item.id)">
              <PhTrash size="24" />
            </button>
          </div>
        </template>
      </OwnTable>

      <PaginationControls
        v-model:request-params="requestParams"
        v-model:request-data="requestData"
        :loading="isLoading"
      />
    </div>

    <DeleteConfirmationDialog
      v-model="showDeleteConfirmation"
      :delete-action-label="t('delete-confirm.delete')"
      :message="t('delete-confirm.message')"
      :title="t('delete-confirm.delete')"
      @cancel="idToDelete = null"
      @delete="onDeleteConfirm"
    />

    <router-view />
  </div>
</template>

<script>
import { PhUsersFour, PhTrash } from '@phosphor-icons/vue'
import { useI18n } from 'vue-i18n'
import { mapGetters } from 'vuex'

import { ConfiguredClient } from '@/api'
import { DeleteConfirmationDialog } from '@/components/dialogs'
import { DefaultEmptyState } from '@/components/empty'
import { SearchInput } from '@/components/inputs'
import { DefaultComponentLoader } from '@/components/loaders'
import { PaginationControls, usePaginationData } from '@/components/table'
import { DateFormatter, PhoneFormatter } from '@/format'
import { logError } from '@/logger'
import notify from '@/mixins/notify'
import { OwnTable, OwnType } from '@/ui'
import { download, openInNewTab } from '@/utils'

export default {
  name: 'ApplicationsTable',
  components: {
    DefaultComponentLoader,
    DefaultEmptyState,
    DeleteConfirmationDialog,
    OwnTable,
    OwnType,
    PaginationControls,
    PhTrash,
    PhUsersFour,
    SearchInput,
  },
  mixins: [notify],
  setup() {
    const { t } = useI18n()
    const { requestParams, requestData } = usePaginationData()

    return {
      requestData,
      requestParams,
      t,
    }
  },
  data() {
    return {
      idToDelete: null,
      isLoading: false,
      items: [],
      showDeleteConfirmation: false,
    }
  },
  computed: {
    ...mapGetters({
      activeBrandId: 'core/brand/active/id',
      activeLocationId: 'core/location/active/id',
    }),
    columns() {
      return [
        {
          body: {
            color: 'primary',
            font: 'subtitle',
          },
          key: 'name',
          minWidth: 150,
        },
        {
          body: {
            format: (value) => this.t('roles', value?.length ?? 0),
          },
          key: 'jobs',
          width: 120,
        },
        {
          key: 'email',
          width: 150,
        },
        {
          body: {
            format: PhoneFormatter,
          },
          key: 'phone',
          width: 140,
        },
        {
          body: {
            format: DateFormatter,
          },
          key: 'createdAt',
          width: 105,
        },
        {
          key: 'resume',
          width: 80,
        },
        {
          key: 'delete',
          width: 60,
        },
      ].map((header) => ({
        ...header,
        label: this.t(`headers.${header.key}`),
      }))
    },
  },
  watch: {
    async activeLocationId() {
      await this.fetchApplications()
    },
    requestParams: {
      deep: true,
      async handler() {
        await this.fetchApplications()
      },
    },
  },
  async created() {
    this.isLoading = true
    await this.fetchApplications()
    this.isLoading = false
  },
  methods: {
    async fetchApplications() {
      const { activeBrandId, activeLocationId, requestParams } = this

      try {
        const response =
          await ConfiguredClient.dashboard.v1.brands.brand.careers.getApplications(
            {
              params: { brandId: activeBrandId },
              query: {
                limit: requestParams.limit,
                locationId: activeLocationId,
                page: requestParams.page,
                query:
                  requestParams.query !== '' ? requestParams.query : undefined,
              },
            }
          )

        this.items = response.results
        this.requestData.pageCount = response.pageCount
        this.requestData.totalItems = response.total
      } catch (err) {
        logError(err)
        return this.$notify(this.t('errors.load'), 'error')
      }
    },
    onDelete(id) {
      this.idToDelete = id
      this.showDeleteConfirmation = true
    },
    async onDeleteConfirm() {
      const { idToDelete } = this

      await ConfiguredClient.careers.v1.applications.application.deleteApplication(
        { params: { applicationId: idToDelete } }
      )
      await this.fetchApplications()
    },
    openResume({ attachment, name }) {
      if (attachment.startsWith('data')) {
        download(attachment, name)
      } else {
        openInNewTab(attachment)
      }
    },
    async viewApplicationDetails(application) {
      await this.$router.push({
        name: 'location.careers.applications.detail',
        params: { applicationId: application.id },
      })
    },
  },
}
</script>
