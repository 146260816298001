<i18n locale="en">
{
  "status": {
    "sent": "Sent"
  }
}
</i18n>

<template>
  <CampaignStatusBadge
    class="campaign-stats-right-action"
    :campaign="resource"
  />
</template>

<script>
import CampaignStatusBadge from '../common/CampaignStatusBadge.vue'

export default {
  name: 'CampaignStatsRightAction',
  components: {
    CampaignStatusBadge,
  },
  props: {
    resource: { type: Object, required: true },
  },
}
</script>

<style lang="scss" scoped>
.campaign-stats-right-action {
  @include respond-to('md-and-up') {
    max-width: 150px;
  }
}
</style>
