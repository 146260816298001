import { type CreateBlockData } from '@/lib/builder'

import {
  type HeroSectionCreate,
  type HeroSection,
} from '../../../WebsiteBuilder.types'

export const createHeroData = ({
  brandId,
}: CreateBlockData<HeroSection>): HeroSectionCreate => ({
  brandId,
  params: {
    heading: 'My page',
    subheading: null,
  },
  type: 'hero',
})
