import get from 'lodash/get'
import { mapGetters } from 'vuex'

import globalStore from '@/store'

export const useCurrentTheme = {
  computed: {
    ...mapGetters({
      coreCurrentTheme: 'core/brand/active/theme',
    }),
    currentTheme() {
      const { brandThemePath, coreCurrentTheme, storeModule } = this

      if (brandThemePath) {
        return get(
          globalStore.getters[`${storeModule}/currentResource`],
          brandThemePath
        )
      } else {
        return coreCurrentTheme
      }
    },
  },
  inject: ['brandThemePath', 'storeModule'],
}
