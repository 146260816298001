const VITE_DEBUG_ANALYTICS = import.meta.env.VITE_DEBUG_ANALYTICS
const VITE_MIXPANEL_PROJECT = import.meta.env.VITE_MIXPANEL_PROJECT

const isMixpanelEnabled =
  (VITE_DEBUG_ANALYTICS === 'true' && !!VITE_MIXPANEL_PROJECT) ||
  (import.meta.env.MODE === 'production' && VITE_MIXPANEL_PROJECT)

const mixpanelProjectId = VITE_MIXPANEL_PROJECT

export { isMixpanelEnabled, mixpanelProjectId }
