import type { RouteRecordRaw } from 'vue-router'

export const adminRouter: RouteRecordRaw[] = [
  {
    children: [
      {
        path: '',
        redirect: 'overview',
      },
      {
        component: async () => import('../views/BrandListView.vue'),
        meta: {
          roles: ['pb:*'],
          title: 'admin.brands',
        },
        name: 'brands',
        path: 'overview',
      },
      {
        component: async () => import('../views/LocationListView.vue'),
        meta: {
          roles: ['pb:*'],
          title: 'admin.locations',
        },
        name: 'locations',
        path: 'locations',
      },
      {
        component: async () => import('../views/UserView.vue'),
        meta: {
          roles: ['pb:*'],
          title: 'admin.users',
        },
        name: 'users',
        path: 'users',
      },
    ],
    component: async () => import('../views/AdminView.vue'),
    meta: {
      roles: ['pb:*'],
    },
    path: '',
  },
]
