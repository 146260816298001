<i18n locale="en">
{
  "labels": {
    "enable": "This will make this page available for customers to visit on your website.",
    "update": "This will publish updates made to this page."
  }
}
</i18n>

<template>
  <OwnType :text="t(`labels.${publishType}`)" />
</template>

<script>
import { useI18n } from 'vue-i18n'

import { OwnType } from '@/ui'

export default {
  name: 'WebsitePublishConfirmContent',
  components: {
    OwnType,
  },
  props: {
    /**
     * Is the resource already live?
     */
    isLive: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { t } = useI18n()

    return { t }
  },
  computed: {
    publishType() {
      const { isLive } = this

      return isLive ? 'update' : 'enable'
    },
  },
}
</script>
