import { type Olympus } from '@owner/olympus-client'
import { type ActionContext } from 'vuex'

import {
  type BlockCore,
  type BuilderState,
  type ResourceCore,
} from '@/lib/builder'
import { type CoreModule } from '@/lib/store'

export const blockAdd = async <
  TResource extends ResourceCore,
  TBlock extends BlockCore,
>(
  {
    state,
    commit,
    dispatch,
  }: ActionContext<BuilderState<TResource, TBlock>, CoreModule>,
  payload: Olympus.Funnel.Section
): Promise<void> => {
  commit('_builder_BLOCK_ADD', payload)
}
