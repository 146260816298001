export const metricsEn = {
  // Description of the previous period (values, e.g. "yesterday") based on the current period type (keys, e.g. "today")
  previousPeriodDescription: {
    '28d': 'last 28 days',
    '7d': 'last 7 days',
    fallback: 'from previous period',
    'last-month': 'the month before',
    today: 'yesterday',
    yesterday: 'the day before',
  },
  total: '{amount} total',
  vsPreviousPeriod: 'vs. {amount} {previousPeriodDescription}',
} as const
