import type { CoreLocation, CoreState } from '../../types'

import { getActiveLocations } from './getActiveLocations'

export const getLocationsWithoutStripeConnected = (
  state: CoreState
): CoreLocation[] | undefined => {
  const activeBrandLocations = getActiveLocations(state)

  if (!activeBrandLocations) {
    return
  }

  return activeBrandLocations.filter(
    (location) => !location.processing.stripe?.id
  )
}
