import { computed, type ComputedRef } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

export const usePageTitle = (): ComputedRef<string | undefined> => {
  const route = useRoute()
  const { t } = useI18n()

  return computed(() => {
    for (let i = route.matched.length - 1; i > 0; i--) {
      const match = route.matched[i]
      if (match?.meta?.title) return t(`core.pages.${match.meta.title}.title`)
    }

    return undefined
  })
}
