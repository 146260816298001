import { specialHoursApi } from '@/api'

export default {
  async createSpecialHours({ commit }, specialHours) {
    const newSpecialHour =
      await specialHoursApi.createSpecialHours(specialHours)

    commit('ADD_SPECIAL_HOUR_ENTRY', newSpecialHour)
  },
  async deleteSpecialHours({ commit }, { locationId, specialHoursId }) {
    await specialHoursApi.deleteSpecialHours(locationId, specialHoursId)

    commit('DELETE_SPECIAL_HOURS', { locationId, specialHoursId })
  },
  async loadSpecialHours({ commit, dispatch, rootGetters }) {
    const locationIds = rootGetters['core/locations/ids']

    await dispatch('sanitizeSpecialHours')

    const locationSpecialHours = await Promise.all(
      locationIds.map((locationId) =>
        specialHoursApi
          .getSpecialHours(locationId)
          .then((response) => [locationId, response.results])
      )
    )

    commit('ADD_SPECIAL_HOURS', Object.fromEntries(locationSpecialHours))
  },
  sanitizeSpecialHours({ commit }) {
    commit('SANITIZE_SPECIAL_HOURS')
  },
  async updateSpecialHours({ commit }, { specialHoursEntry, locationId }) {
    await specialHoursApi.updateSpecialHours(
      specialHoursEntry.id,
      specialHoursEntry
    )

    commit('UPDATE_SPECIAL_HOURS', { locationId, specialHoursEntry })
  },
}
