<script>
import { isDefined } from '@vueuse/core'
import { h } from 'vue'

import { OwnType } from '../OwnType'

import OwnInputHelperRow from './components/OwnInputHelperRow'
import OwnInputLabel from './components/OwnInputLabel'

export default {
  name: 'OwnInputContainer',
  components: {
    OwnInputHelperRow,
    OwnInputLabel,
    OwnType,
  },
  props: {
    /**
     * Description of input field
     */
    description: { type: String, default: undefined },

    /**
     * List of errors
     */
    errors: { type: Array, default: () => [] },

    /**
     * Link to define even more context
     */
    helperLink: { type: [String, Object], default: undefined },

    /**
     * Label to show for helper link
     */
    helperLinkText: { type: String, default: undefined },

    /**
     * Helper text to show
     */
    helperText: { type: String, default: undefined },

    /**
     * Label
     */
    label: { type: String, default: undefined },

    /**
     * Max chars allowed
     */
    maxChars: { type: Number, default: undefined },

    /**
     * Shows optional tag
     */
    optional: { type: Boolean, default: false },

    /**
     * Only show for screen readers
     */
    srOnly: { type: Boolean, default: false },

    /**
     * Current value of the input
     */
    value: { type: String, default: undefined },
  },
  computed: {
    accessibilityId() {
      const { label } = this

      if (!label) {
        return undefined
      }

      const splitLabel = label.split(' ')

      return splitLabel
        .map((labelPart) => labelPart.toLowerCase())
        .filter((labelPart) => /^[a-z0-9_-]*$/.test(labelPart))
        .join('-')
    },
    hasError() {
      const { errors } = this
      return errors.length > 0
    },
  },
  methods: {
    buildColumnLayout() {
      const { srOnly } = this

      return h(
        'div',
        {
          class: ['flex-col own-input-container', !srOnly && 'gap-2'],
        },
        {
          default: () => [
            this.buildLabelElement(),
            this.extendInputWithErrorProp(),
            this.buildHelperRow(),
          ],
        }
      )
    },
    buildHelperRow() {
      const {
        accessibilityId,
        helperLink,
        helperLinkText,
        helperText,
        hasError,
        errors,
      } = this

      return h(OwnInputHelperRow, {
        accessibilityId,
        error: hasError,
        helperLink,
        helperLinkText,
        helperText: hasError ? errors[0] : helperText,
      })
    },
    buildLabelElement() {
      const {
        accessibilityId,
        label,
        description,
        maxChars,
        srOnly,
        optional,
        value,
      } = this

      if (label || description) {
        const children = []

        const inputLabelElement = h(OwnInputLabel, {
          accessibilityId,
          class: [
            'own-input-container__label',
            srOnly && 'own-input-container__sr-only',
          ],
          description,
          label,
          optional,
        })

        if (this.$slots.prepend) {
          children.push(
            h(
              'div',
              {
                class: 'flex-row justify-start align-start gap-2',
              },
              {
                default: () => [this.$slots.prepend()[0], inputLabelElement],
              }
            )
          )
        } else {
          children.push(inputLabelElement)
        }

        const charCountEl =
          isDefined(maxChars) && isDefined(value)
            ? h(OwnType, {
                text: `${value?.length ?? 0}/${maxChars}`,
                variant: 'paragraph-small',
              })
            : undefined

        if (charCountEl) children.push(charCountEl)

        return h(
          'div',
          {
            class: 'flex-row align-center justify-between gap-2',
          },
          {
            default: () => children,
          }
        )
      }

      return undefined
    },
    extendInputWithErrorProp() {
      const { accessibilityId, hasError } = this

      const defaultSlot = this.$slots.default()

      const renderedInputElement = defaultSlot[0]

      if (renderedInputElement.props) {
        renderedInputElement.props = {
          ...renderedInputElement.props,
          accessibilityId,
          error: hasError,
        }
      }

      return renderedInputElement
    },
  },
  render() {
    if (this.$slots.default?.()?.length !== 1) {
      return h('div')
    }

    return this.buildColumnLayout()
  },
}
</script>
<style lang="scss">
.own-input-container {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 20px;
  min-width: 20px;

  &__input-column {
    width: 50%;
  }

  &__sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
}
</style>
